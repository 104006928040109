import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  IconButton,
  Button,
  Dialog,
  Box,
  DialogActions,
  DialogContent,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  GroupSettingsModel,
  Inject,
  Page,
  TextWrapSettingsModel,
  SelectionSettingsModel,
  ContextMenu,
  Toolbar,
  Resize,
  DetailRow,
  Group,
  Sort,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { AppDispatch, useAppSelector } from "../../redux/store";
import { ReactComponent as Download } from "../../assets/images/images-svg/Download.svg";
import { IFormRequestDetail } from "../../types/forms";
import {
  CleanUpFormRequests,
  GetFormRequestById,
  GetFormRequests,
  ReprocessFormRequest,
} from "../../redux/features/forms/form-slice";
import Reset from "../../assets/images/images-png/reset2.png";
import Preview2 from "../../assets/images/images-png/preview2.png";
import Preview from "../../assets/images/images-png/preview.png";
import { dateToFromNowDaily } from "../../helpers/reverse-list/reverse-list";
import CloseIcon from "@mui/icons-material/Close";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Loader from "src/layouts/loader/Loader";

function FormRequests(this: any) {
  //dispatch
  const dispatch = useDispatch<AppDispatch>();

  //groupings for grid
  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };

  //handles preview
  const [isPreview, setIsPreview] = useState<boolean>(false);

  //handles confirmation pop Up
  const [confirmationPopUp, setConfirmationPopUp] = useState<boolean>(false);

  //stores value for valid status for cleanUp
  const [isStatusSent, setIsStatusSent] = useState<boolean>(false);

  //stores selected formId for clean Up
  const [selectedFormRequestIds, setSelectedFormRequestIds] = useState<
    string[]
  >([]);

  //contains survey data
  const [survey, setSurvey] = useState<any>(null);

  //select format for grid
  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };

  let gridInstance = useRef<GridComponent | null>(null);

  //text-wrap for grid
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };

  // const contextMenuItems: ContextMenuItemModel[] = [
  //   { text: "Add Into Calendar", target: ".e-content", id: "addintocalendar" },
  // ];

  const [url, setUrl] = useState<string | null>(null);

  const onCheckboxChange = () => {
    if (gridInstance.current) {
      let uniqueIds: string[] = [];
      const selectedrecords: any[] = gridInstance.current.getSelectedRecords();
      selectedrecords.forEach((item) => {
        uniqueIds.push(item.id);
      });
      setSelectedFormRequestIds(uniqueIds);
    }
  };
  //read all formrequests
  const allFormRequests = useAppSelector<IFormRequestDetail[]>(
    (state) => state.formSlice.AllFormRequests
  );

  const isLoading = useAppSelector<boolean>(
    (state) => state.formSlice.isLoading
  );
  //handle SortData massage Settings

  const handlePreview = async (id: string) => {
    const data = await dispatch(GetFormRequestById({ assessmentId: id }));
    let tempSurvey = new Model(data.payload?.form.formSchema);
    tempSurvey.data = data?.payload?.formData;
    tempSurvey.mode = "display";
    if (tempSurvey) {
      setSurvey(tempSurvey);
      setIsPreview(true);
    }
  };
  //#region useEffect region

  // used to fetch intake client form data
  useEffect(() => {
    if (!allFormRequests.length) {
      dispatch(GetFormRequests({ formType: "All Forms, No Assessments" }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFormRequests.length]);

  //#endregion

  let refresh: boolean;
  function load(this: any) {
    refresh = this?.refreshing;
  }

  function dataBound() {
    if (refresh && gridInstance.current) {
      gridInstance.current.groupColumn("name");
      refresh = false;
    }
  }

  const entityTypeTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.entityType === "Client"
            ? "Client"
            : props.entityType === "Provider"
            ? "Employee"
            : props.entityType}
        </span>
      </div>
    );
  };

  //field: entityName
  const entityNameTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.entityName}</span>
      </div>
    );
  };

  const errorMsgTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.errorMsg}</span>
      </div>
    );
  };

  //field: sentDtm
  const sentDtmTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.sentDtm
            ? new Date(`${props.sentDtm}`).toLocaleDateString("en-US")
            : "--------"}
        </span>
      </div>
    );
  };

  const submitDtmTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.submitDtm
            ? new Date(`${props.submitDtm}`).toLocaleDateString("en-US")
            : "--------"}
        </span>
      </div>
    );
  };

  const updateDtmTemplate = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.updatedAt
            ? dateToFromNowDaily(new Date(`${props.updatedAt}`))
            : "--------"}
        </span>
      </div>
    );
  };

  //template for icons in data grid
  function materialTemplate(props: any) {
    return (
      <div>
        {props.status === "Error" || props.status === "Mapped" ? (
          <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "20px",
            }}
          >
            {props.status === "Mapped" ? (
              <>
              <a href={props.pdfUrl}>
                <IconButton style={{ background: "transparent", padding: "5px 0px" }}>
                  <Download />
                </IconButton>
              </a>
              <div
                aria-label="reprocess"
                style={{ padding: "5px 0px", cursor: "pointer" }}
                onClick={() => {
                  handlePreview(props?.id);
                }}
              >
                <img src={Preview2} height={30} className="resetImage" />
              </div>
              </>
            ) : (
              //do not show anything
              <span></span>
            )}
          <div
            aria-label="reprocess"
            style={{ padding: "5px 0px", cursor: "pointer" }}
            onClick={() => {
              dispatch(
                ReprocessFormRequest({
                  id: props.id,
                  formType: props.formType,
                })
              );
            }}
          >
            <img src={Reset} height={20} className="resetImage" />
          </div>
        </div>
        </>
        ) : (
          //do not show anything
          <span></span>
        )}
      </div>
    );
  }

  //#endregion

  //#region Memoised Componant region
  //data grid table
  const dataGridTable = React.useMemo(
    () =>
       (
        <GridComponent
          id="grid" //must have!!
          className="gridTable"
          dataSource={allFormRequests}
          // rowTemplate={template.bind(this)}
          dataBound={dataBound.bind(this)}
          allowPaging={true}
          ref={gridInstance}
          load={load}
          width="auto"
          height="650"
          allowGrouping={true}
          groupSettings={groupOptions}
          allowFiltering={true}
          allowSorting={true}
          allowResizing={true}
          allowTextWrap={true}
          filterSettings={{ type: "Menu" }}
          textWrapSettings={textWrapSettings}
          selectionSettings={selectionSettings}
          checkBoxChange={onCheckboxChange}
          // contextMenuItems={contextMenuItems}
        >
          <ColumnsDirective>
            <ColumnDirective type="checkbox" width="50" textAlign="Left" />
            <ColumnDirective
              field="id"
              visible={false}
              headerText="ID"
              isPrimaryKey={true}
              width="130"
            />
            <ColumnDirective
              headerText="Type"
              width="80"
              textAlign="Left"
              field="entityType"
              template={entityTypeTemplate}
            />
            <ColumnDirective
              headerText="Person"
              width="150"
              textAlign="Left"
              field="entityName"
              template={entityNameTemplate}
            />
            <ColumnDirective
              headerText="Form"
              width="180"
              textAlign="Left"
              field="formName"
            />
            <ColumnDirective
              headerText="Send Date"
              width="100"
              textAlign="Left"
              field="sentDtm"
              template={sentDtmTemplate}
            />
            <ColumnDirective
              headerText="Submit Date"
              width="100"
              textAlign="Left"
              field="submitDtm"
              template={submitDtmTemplate}
            />
            <ColumnDirective
              headerText="Status"
              width="70"
              textAlign="Left"
              field="status"
            />
            <ColumnDirective
              headerText="Error"
              width="150"
              textAlign="Left"
              field="errorMsg"
              template={errorMsgTemplate}
            />
            <ColumnDirective
              headerText="Updated On"
              width="100"
              textAlign="Left"
              field="updatedAt"
              template={updateDtmTemplate}
            />
            <ColumnDirective
              headerText="PDF"
              width="100"
              textAlign="Left"
              field="formPDF"
              disableHtmlEncode={false}
              template={materialTemplate}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Group,
              ContextMenu,
              Toolbar,
              Resize,
              DetailRow,
              Sort,
              Filter,
            ]}
          />
        </GridComponent>
      ),
    [allFormRequests]
  );
  //#endregion

  //#region useEffect region

  return (
    <>
      <div className="formRequets">
        <Grid sx={{ mt: "30px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              fontWeight="bold"
              className="listingTable__title"
              style={{ marginBottom: "10px" }}
            >
              Requests & Submissions
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                if (!selectedFormRequestIds.length) {
                  return;
                }
                const isStatusUnValid = allFormRequests.some(
                  (item) =>
                    selectedFormRequestIds.includes(item.id) &&
                    item.status !== "Sent"
                );
                if (isStatusUnValid) {
                  setIsStatusSent(false);
                } else {
                  setIsStatusSent(true);
                }
                setConfirmationPopUp(true);
              }}
            >
              Clean Up
            </Button>
          </div>

          <Loader isShow={isLoading} />
          {dataGridTable}
        </Grid>
        {isPreview && (
          <Dialog open={isPreview} className="smallModel--assessment">
            <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
              <Typography variant="h3" className="smallModel__title">
                preview
              </Typography>

              <Button
                className="smallModel__closeBtn"
                onClick={() => {
                  setIsPreview(false);
                }}
              >
                <CloseIcon style={{ color: "white" }} />
              </Button>
            </Box>
            {url === null ? (
              <div>
                <Survey id="id" model={survey} />
              </div>
            ) : (
              <iframe
                src={url}
                height="3000"
                width="700"
                title="frame"
              ></iframe>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "12px",
              }}
            >
              <Button
                color="primary"
                onClick={() => {
                  setIsPreview(false);
                }}
              >
                Close
              </Button>
            </div>
          </Dialog>
        )}
      </div>
      <Dialog
        open={confirmationPopUp}
        className="smallModel confirmExport cleanup-model"
      >
        <div>
          <Box className="smallModel__head" style={{ padding: "8px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              Clean Up Form Requests
            </Typography>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setConfirmationPopUp(false);
              }}
            >
              <CloseIcon />
            </Button>
          </Box>
          <DialogContent className="cleanup-content">
            {isStatusSent ? (
              <Typography>
                This action will cleanup {selectedFormRequestIds.length} form
                requests with Status - <b>"Sent".</b>
              </Typography>
            ) : (
              <Typography>
                This action cannot be applied to
                {"  "}
                <b>"Non-Sent"</b> {"  "} form requests.
              </Typography>
            )}
          </DialogContent>

          {isStatusSent ? (
            <DialogActions>
              <Button
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  setConfirmationPopUp(false);
                  dispatch(
                    CleanUpFormRequests({ formIds: selectedFormRequestIds })
                  );
                  gridInstance.current?.clearSelection();
                  setSelectedFormRequestIds([]);
                }}
              >
                Yes, Please
              </Button>
            </DialogActions>
          ) : (
            <DialogActions>
              <Button
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  setConfirmationPopUp(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          )}
        </div>
      </Dialog>
    </>
  );
}
export default FormRequests;
