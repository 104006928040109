import axios from "../axios/axios";

export const getTranslation = async (
  sourceType: string,
  id: string,
  targetLanguage: string,
  texts?: string
) => {
  try {
    const { data } = await axios.post(`/translate/${id}`, {
      type:sourceType,
      target:targetLanguage,
      formData: texts,
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const TranslationService = {
  getTranslation,
};

export default TranslationService;
