import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid2";
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Slide,
  Slider,
} from "pure-react-carousel";
import { ReactComponent as LeftArrow } from "../../../assets/images/images-svg/leftArrow.svg";
import { ReactComponent as RightArrow } from "../../../assets/images/images-svg/rightArrow.svg";
import { ProviderForms } from "../../../types/provider";
import { ClientForms } from "../../../types/client";
import { BaseClient, MatchedProvider } from "../../../types/schedule";
import { AvailabilityDetails } from "../../../types/availability";
import EntityAvailabilityHeatMap from "./EntityAvailabilityHeatMap";

const AvailabilityHeatMap: React.FC<{
  selectedClients: ClientForms[] | BaseClient[];
  selectedProvider: ProviderForms[] | MatchedProvider[];
  isValidAvailability: (availability: AvailabilityDetails) => boolean;
}> = ({ selectedClients, selectedProvider, isValidAvailability }) => {
  //used to display number cards based on screen size
  const visibleSlide: number = 2;
  //set active Index for show and hide scroll buttons
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  //Left scroll button popup handle
  const funcLeft = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < visibleSlide) {
      return true;
    } else {
      return true;
    }
  };

  // Right scroll button popup handle
  const funcRight = () => {
    if (activeIndex === null) {
      return false;
    }
    if (activeIndex < selectedClients?.length + 1) {
      return true;
    }
    return true;
  };

  //Right scroll button Click handle
  const handleRight = () => {
    if (activeIndex) {
      if (activeIndex + visibleSlide < selectedClients?.length + 1) {
        setActiveIndex(activeIndex + visibleSlide);
      } else {
        setActiveIndex(selectedClients?.length + 1 - 1);
      }
    }
  };

  //Left scroll button Click handle
  const handleLeft = () => {
    if (activeIndex) {
      if (activeIndex - visibleSlide + 1 >= visibleSlide) {
        setActiveIndex(activeIndex - visibleSlide);
      } else {
        setActiveIndex(visibleSlide - 1);
      }
    }
  };
  useEffect(() => {
    if (selectedClients.length + 1 > visibleSlide) {
      setActiveIndex(visibleSlide - 1);
    }
    // eslint-disable-next-line
  }, [selectedClients.length, visibleSlide]);

  return (
    <Grid container spacing={2} className="booking-heatmap" width={"100%"}>
      <CarouselProvider
        naturalSlideWidth={80}
        naturalSlideHeight={130}
        totalSlides={selectedClients.length + selectedProvider.length} // Add 1 for the provider heatmap
        visibleSlides={visibleSlide}
        step={visibleSlide}
        orientation="horizontal"
        className="formCardSlider"
      >
        <Slider>
          {/* Map through selectedClients and render each as a slide */}
          {selectedClients.map((item, index) => (
            <Slide index={index} key={`client-slide-${index}`}>
              <Grid size={{ xs: 12 }} key={item.clientBasicDetails.id}>
                <EntityAvailabilityHeatMap
                  availabilityDetails={item.availabilityDetails}
                  isValidAvailability={isValidAvailability}
                  clientBasicDetails={item.clientBasicDetails}
                  employeBasicDetails={null}
                />
              </Grid>
            </Slide>
          ))}

          {/* Render the provider heatmap as a separate slide */}
          {selectedProvider.map((item, index) => (
            <Slide index={index} key={`provider-slide-${index}`}>
              <Grid size={{ xs: 12 }} key={item.employmentDetails.id}>
                <EntityAvailabilityHeatMap
                  availabilityDetails={item.availabilityDetails}
                  isValidAvailability={isValidAvailability}
                  clientBasicDetails={null}
                  employeBasicDetails={item.employmentDetails}
                />
              </Grid>
            </Slide>
          ))}
        </Slider>

        {/* Navigation Buttons */}
        {funcLeft() && (
          <ButtonBack className="sliderNavBtn" onClick={handleLeft}>
            <LeftArrow />
          </ButtonBack>
        )}
        {funcRight() && (
          <ButtonNext className="sliderNavBtn" onClick={handleRight}>
            <RightArrow />
          </ButtonNext>
        )}
      </CarouselProvider>
      <div style={{ width: "100%", display: "flex" }}>
        <div className="availabilityStatus">
          <span className="unAvailableBox">Unavailable</span>
          <span className="availableBox">Available</span>
          <span className="partiallyBookBox">Partially Booked</span>
          <span className="fullyBookBox">Fully Booked</span>
        </div>
      </div>
    </Grid>
  );
};

export default AvailabilityHeatMap;
