import axios from "../axios/axios";
import { AllUserMessageI, createMessage } from "../types/message";
import { SingleResponse } from "../types/response";

//get all message by id
export const getMessageByEntityId = async (entityId: string) => {
  try {
    const { data } = await axios.get<SingleResponse<AllUserMessageI>>(
      `/message/${entityId}`
    );

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post create message
export async function postCreateMessage(
  payloadData: createMessage,
  entityId: string,
  files?: any[],
  libraryFiles?: any[]
) {
  const formData = new FormData();
  formData.append("subject", payloadData.subject);
  formData.append("message", payloadData.message);
  formData.append("whoCanView", JSON.stringify(payloadData.whoCanView));
  formData.append("type", payloadData.type);
  formData.append("from", payloadData.from);
  formData.append("entityType", payloadData.entityType);

  formData.append("libraryFiles", JSON.stringify(libraryFiles));

  files?.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const { data } = await axios.post(`/message/${entityId}`, formData);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

//post create message
export async function updateMessage(id: string, message: any) {
  try {
    const { data } = await axios.put(`/message/${id}`, { message });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export const getUnreadUsers = async () => {
  try {
    const { data } = await axios.get(`/unreadMessageUser`);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

//post create message
export async function deleteMessage(id: string) {
  try {
    const { data } = await axios.delete(`/message/${id}`);

    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

//update read-unread data
export async function updateStatus(id: string[]) {
  try {
    const data = await axios.patch(`/message`, { id });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}
//patch message
export const patchComments = async (
  messageId: string,
  entityId: string,
  comment: string,
  entityType: string,
  files?: any[]
) => {
  const formData = new FormData();
  formData.append("entityId", entityId);
  formData.append("comment", comment);
  formData.append("entityType", entityType);
  files?.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const data = await axios.post(`/comment/${messageId}`, formData);
    return data.data;
  } catch (e: any) {
    throw e.response.data;
  }
};

export async function postComposeMessage(
  payloadData: any,
  files?: any[],
  libraryFiles?: any[]
) {
  const formData = new FormData();
  formData.append("subject", payloadData.subject);
  formData.append("message", payloadData.message);
  formData.append("whoCanView", JSON.stringify(payloadData.whoCanView));
  formData.append("from", payloadData.from);
  if (libraryFiles) {
    formData.append("libraryFiles", JSON.stringify(libraryFiles));
  }
  formData.append("users", JSON.stringify(payloadData.users));

  files?.forEach((file) => {
    if (!file.fileKey) {
      formData.append("file", file);
    }
  });
  try {
    const { data } = await axios.post("/composeMessage", formData);
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
}

export const updateActiveMessage = async (
  messageId: string[],
  activeStatus: boolean
) => {
  try {
    const { data } = await axios.patch(`/updateMessageActive`, {
      messageId,
      activeStatus,
    });
    return data;
  } catch (e: any) {
    throw e?.response?.data;
  }
};

const MessageService = {
  getMessageByEntityId,
  postCreateMessage,
  updateStatus,
  patchComments,
  postComposeMessage,
  getUnreadUsers,
  updateMessage,
  updateActiveMessage,
};

export default MessageService;
