import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import { Close, Delete, Edit, Settings } from "@mui/icons-material";
// import FileUpload from "react-material-file-upload";
import { MuiFileInput } from "mui-file-input";
import {
  ColumnDirective,
  ColumnsDirective,
  DetailRow,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  SelectionSettingsModel,
  Sort,
  TextWrapSettingsModel,
} from "@syncfusion/ej2-react-grids";

import { RootState } from "../../../../redux/store";
import {
  deleteProviderFileById,
  setProviderFileCategory,
  updateProviderFileNameById,
  UpdateProviderFilePermission,
} from "../../../../redux/features/providers-main/provider-slice";
import { setProviderFilesDataChange } from "../../../../redux/features/providers-main/provider-list-slice";
import { setResponseValue } from "../../../../redux/features/api-response/api-response";

import CommonService, {
  FileCategory,
} from "../../../../service/common.service";

import { FileModelType, FilePermissionSetting } from "../../../../types/files";

import { ReactComponent as Download } from "../../../../assets/images/images-svg/Download.svg";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import { getFileType } from "../../../../utils/FileUtils";
import DeleteConfirmation from "../../../../layouts/delete-confirmation/DeleteConfirmation";
import BackupIcon from "@mui/icons-material/Backup";
import { shortenFileName } from "src/constants/common";
import English from "../../../../assets/images/images-png/united-kingdom.png";
import Spanish from "../../../../assets/images/images-png/spain.png";
import China from "../../../../assets/images/images-png/china.png";
import { getTranslation } from "src/service/translation.service";
import ShareIcon from "@mui/icons-material/Share";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IProviderSubFileList {
  providerId: string;
  providerName: string;
}

const ProviderSubFilesList: React.FC<IProviderSubFileList> = ({
  providerId,
  providerName,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch();

  //files modal controller
  const [filesModal, setFilesModal] = useState<boolean>(false);

  //stores files data
  const [newFiles, setNewFiles] = useState<any[]>([]);

  //file name edit modal
  const [fileNameEdit, setFileNameEdit] = useState<boolean>(false);

  //file name
  const [file, setFile] = useState<{ id: string; fileName: string }>({
    id: "",
    fileName: "",
  });
  //handles preview modal open
  const [open, setOpen] = useState<boolean>(false);

  //stores url
  const [url, setUrl] = useState<string>("");

  const [fileType, setFileType] = useState<string>("");

  //for delete confirmation popup
  const [deleteFile, setDeleteFile] = useState<{
    openPopup: boolean;
    fileId: string;
  }>({
    openPopup: false,
    fileId: "",
  });

  const [errorMessage, setErrorMessage] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [permissionModal, setPermissionModal] = useState<boolean>(false);

  const [permissionSettings, setPermissionSettings] =
    useState<FilePermissionSetting>({
      fileOwner: {
        ownerCanSee: false,
      },
    });
  //sort the files
  const providerFiles = useSelector<RootState, any[]>(
    (state) => state.providerSlice.providerFiles
  );

  const sortedFiles = [...providerFiles]?.sort((a, b) => {
    return (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any);
  });

  //handle error  message
  const [hasError, setHasError] = useState<boolean>(false);

  //#endregion

  //#region methods region

  // file submit handler
  const onSubmit = async () => {
    if (newFiles.length === 0) {
      setHasError(true);
      return;
    }
    setFilesModal(false);
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await CommonService.postFiles(
        FileCategory.Onboarding,
        "Provider",
        FileModelType.Provider,
        providerId,
        newFiles
      );

      if (status) {
        setNewFiles([]);
        dispatch(setProviderFilesDataChange(null));
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };
  const handlePreviewModal = async (url: string) => {
    const fileType = getFileType(url);
    if (fileType) {
      setFileType(fileType);
    }
    setUrl(url);
    setOpen(true);
  };

  // category update handler
  const handleChange = async (e: SelectChangeEvent<string>, file: any) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { status, message } = await CommonService.updateFileCategory(
        file.id,
        file.entityId,
        e.target.value
      );
      if (status) {
        dispatch(
          setProviderFileCategory({ id: file.id, value: e.target.value })
        );
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(setResponseValue({ name: "message", value: message }));
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  };

  const handleTranslation = async (targetLanguage: string) => {
    try {
      //
      setIsLoading(true);
      if (errorMessage) {
        setErrorMessage("");
      }
      // call translation api
      const data = await getTranslation("FormRequest", file.id, targetLanguage);
      if (data.data) {
        // set the url
        if (data.data.fileUrl) {
          setUrl("");
          setTimeout(() => setUrl(data.data.fileUrl), 0);
        }
      } else {
        setUrl("");
        setErrorMessage(data.message);
      }
    } catch (error: any) {
      console.log(error, "error");
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: error?.message }));
    } finally {
      setIsLoading(false); //set Is Loading to false
    }
  };

  const handleUpdatePermission = () => {
    dispatch(
      UpdateProviderFilePermission({
        fileId: file.id,
        permissionSetting: permissionSettings,
      })
    );
    setPermissionModal(false);
    // setPermissionSettings({
    //   fileOwner: {
    //     ownerCanSee: false,
    //   },
    // });
  };
  // #endregion
  let gridInstance: GridComponent;
  // let grid: Grid | null;

  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };

  //text-wrap for grid
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };

  const rowSelected = () => {
    console.log("[rowSelected]");
    if (gridInstance) {
      const selectedrowindex: number[] = gridInstance.getSelectedRowIndexes();
      const selectedrecords: object[] = gridInstance.getSelectedRecords();
    }
  };
  // const pdfExportComplete = (): void => {
  //   if (gridInstance) {
  //     gridInstance.hideSpinner();
  //   }
  // };
  // const excelExportComplete = (): void => {
  //   if (gridInstance) {
  //     gridInstance.hideSpinner();
  //   }
  // };

  //select format for grid
  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };

  let refresh: boolean;
  function load(this: any) {
    console.log("[load]");
    refresh = this?.refreshing;
  }

  //field: UploadedBy
  const uploadedByField = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.uploadedBy}</span>
      </div>
    );
  };

  //field: UploadTime
  const uploadTimeField = (props: any) => {
    return (
      <div>
        <span style={{ display: "flex", justifyContent: "left" }}>
          {new Date(props.uploadedAt).toLocaleString()}
        </span>
      </div>
    );
  };

  //field: category
  const categoryField = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Box className="fromGroup">
          <Select
            className="form-control"
            labelId="demo-simple-select-label"
            value={props.category}
            name="category"
            onChange={(e: SelectChangeEvent<string>) => handleChange(e, props)}
            MenuProps={MenuProps}
            style={{ maxWidth: "200px", width: "200px" }}
          >
            <MenuItem value="Onboarding">OnBoarding</MenuItem>
            <MenuItem value="Credentialing">Panel with</MenuItem>
            <MenuItem value="Certificate">Certificate</MenuItem>
          </Select>
        </Box>
      </div>
    );
  };

  //field: fileName
  const fileNameField = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {props.fileName
            ? shortenFileName(props.fileName, 50)
            : shortenFileName(props.fileName.split("_$SPLIT$_")[1], 50)}
        </span>
      </div>
    );
  };

  //field: action
  const actionField = (props: any) => {
    return (
      props && (
        <div style={{ display: "flex", gap: "15px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a href={props.fileUrl}>
              <IconButton style={{ background: "transparent" }}>
                <Download />
              </IconButton>
            </a>
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setDeleteFile({ openPopup: true, fileId: props.id })}
          >
            <Delete color="primary" />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              setFile({ id: props.id, fileName: props.fileName });
              setFileNameEdit(true);
            }}
          >
            <Edit color="primary" />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => {
              setPermissionModal(true);
              if (props.permissionSetting) {
                setPermissionSettings(props.permissionSetting);
              }
              setFile({ id: props.id, fileName: props.fileName });
            }}
          >
            <ShareIcon color="primary" />
          </div>
          <Button
            onClick={() => {
              setFile({ id: props.id, fileName: props.fileName });
              handlePreviewModal(props.fileUrl);
            }}
          >
            Preview
          </Button>
        </div>
      )
    );
  };

  return (
    <div className="listingTable">
      <div className="listingTable__header d-block">
        <div style={{ position: "relative", textAlign: "right" }}>
          <Button className="addBtn" onClick={() => setFilesModal(true)}>
            + Upload Files
          </Button>
        </div>
      </div>

      <GridComponent
        id="grid" //must have!!
        className="gridTable"
        dataSource={sortedFiles}
        // dataBound={dataBound.bind(this)}
        allowPaging={true}
        ref={(grid) => (gridInstance = grid as GridComponent)}
        statelessTemplates={["directiveTemplates"]}
        load={load}
        width="auto"
        height="650"
        allowGrouping={true}
        groupSettings={groupOptions}
        allowFiltering={true}
        allowSorting={true}
        allowResizing={true}
        allowTextWrap={true}
        filterSettings={{ type: "Menu" }}
        textWrapSettings={textWrapSettings}
        selectionSettings={selectionSettings}
        rowSelected={rowSelected}
        // allowPdfExport={true}
        // allowExcelExport={true}
        // pdfExportComplete={pdfExportComplete}
        // excelExportComplete={excelExportComplete}
      >
        <ColumnsDirective>
          <ColumnDirective
            field="id"
            visible={false}
            headerText="ID"
            isPrimaryKey={true}
            width="150"
          />
          <ColumnDirective
            headerText="File Name"
            width="180"
            textAlign="Left"
            field="file"
            template={fileNameField}
          />
          <ColumnDirective
            headerText="Category"
            width="120"
            textAlign="Left"
            field="category"
            template={categoryField}
          />
          <ColumnDirective
            headerText="Upload Time"
            width="100"
            textAlign="Left"
            field="uploadTime"
            template={uploadTimeField}
          />
          <ColumnDirective
            headerText="Uploaded By"
            width="120"
            textAlign="Left"
            field="uploadedBy"
            template={uploadedByField}
          />
          <ColumnDirective
            headerText="Action"
            width="120"
            textAlign="Left"
            field="action"
            disableHtmlEncode={false}
            template={actionField}
          />
        </ColumnsDirective>
        <Inject services={[Page, Group, DetailRow, Filter, Sort]} />
      </GridComponent>

      <Dialog open={fileNameEdit} className="largeModel">
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            Rename File
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => setFileNameEdit(false)}
          >
            <Close />
          </Button>
        </Box>
        <Box className="stepperForm">
          <ValidatorForm
            onSubmit={() => {
              dispatch(
                updateProviderFileNameById({
                  id: file.id,
                  fileName: file.fileName,
                  providerId: providerId,
                })
              );
              setFileNameEdit(false);
            }}
          >
            <Box className="stepperForm__inner p-30px">
              <InputLabel className="fromLabel">File Name</InputLabel>
              <TextValidator
                id="fileName"
                type="text"
                name="fileName"
                value={file.fileName}
                onChange={(e: any) =>
                  setFile({ ...file, fileName: e.target.value })
                }
                //validators={["required", "matchRegexp:^[a-zA-Z- ]*$"]}
                validators={["required"]}
                errorMessages={["File name is required"]}
              />
            </Box>
            <Box className="stepperForm__footer b-0">
              <Box sx={{ flex: "1 1 auto" }} />
              <Button type="submit" className="button">
                Save Changes
              </Button>
            </Box>
          </ValidatorForm>
        </Box>
      </Dialog>

      {filesModal && (
        <Dialog open={true} className="fileUploadModal smallModel">
          <Box className="smallModel__head">
            <Typography variant="h5" className="smallModel__title">
              Upload Files
            </Typography>
            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setNewFiles([]);
                setHasError(false);
                setFilesModal(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <Box className="p-30px">
            <Box>
              <Box className="fileUploadBlock">
                <Box className="uploadIconBox">
                  <BackupIcon color="primary" className="uploadIcon" />
                  <Typography>
                    Drag 'n' drop files here or click to add files
                  </Typography>
                </Box>

                <MuiFileInput
                  multiple
                  hideSizeText
                  sx={{
                    "& .css-1fel157 > span": { display: "none" },
                    "& .css-flami6": { display: "none" },
                    "& fieldset": { border: "none" },
                  }}
                  value={newFiles}
                  onChange={(e: File[]) => {
                    //Change: regarding duplicates, we should allow to upload
                    //This is the same behavior of Google Cloud File Drive.
                    setNewFiles([...newFiles, ...e]);
                    setHasError(false);
                  }}
                />
              </Box>

              <div className="selectedIn-fileBox">
                {newFiles &&
                  newFiles.map((file, index) => {
                    return (
                      <div className="selectedIn-file">
                        <h1>{file.name}</h1>
                        <span>
                          <Delete
                            onClick={() => {
                              setNewFiles(
                                newFiles.filter(
                                  (_, fileIndex) => fileIndex !== index
                                )
                              );
                            }}
                          />
                        </span>
                      </div>
                    );
                  })}
              </div>
            </Box>
            {hasError && newFiles.length === 0 && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Please select files first
              </FormHelperText>
            )}
          </Box>

          <Box className="stepperForm__footer">
            <Button
              type="submit"
              color="inherit"
              className="border-button"
              onClick={() => {
                setNewFiles([]);
                setHasError(false);
                setFilesModal(false);
              }}
            >
              Cancel
            </Button>
            <div></div>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button className="button" onClick={onSubmit}>
              Save
            </Button>
          </Box>
        </Dialog>
      )}
      {open && (
        <Dialog open={open} className="smallModel--assessment ">
          <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
            <Typography variant="h3" className="smallModel__title">
              Preview
            </Typography>

            <Button
              className="smallModel__closeBtn"
              onClick={() => {
                setOpen(false);
                setErrorMessage("");
              }}
            >
              <Close style={{ color: "white" }} />
            </Button>
          </Box>
          <DialogContent sx={{ padding: "0px" }}>
            <div className="forms-preview">
              {fileType === "pdf" && (
                <div className="language-DrpDwn">
                  <FormControl size="small" sx={{ minWidth: 150 }}>
                    <InputLabel id="demo-select-small-label">
                      Translate
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            width: 150,
                          },
                        },
                      }}
                      label="Translate"
                      onChange={(e: any) => handleTranslation(e.target.value)}
                    >
                      <MenuItem
                        value={"en"}
                        key={"en"}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        English
                        <img
                          src={English}
                          alt=""
                          height={"20px"}
                          width={"20px"}
                        />
                      </MenuItem>
                      <MenuItem
                        value={"es"}
                        key={"es"}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Spanish
                        <img
                          src={Spanish}
                          alt=""
                          height={"20px"}
                          width={"20px"}
                        />
                      </MenuItem>
                      <MenuItem
                        value={"zh-cn"}
                        key={"zh-cn"}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        Chinese
                        <img
                          src={China}
                          alt=""
                          height={"20px"}
                          width={"20px"}
                        />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}

              <div className="error-message">
                {errorMessage && <p>{errorMessage}</p>}
                {isLoading && <p>Loading...</p>}
              </div>

              <DocViewer
                key={url}
                pluginRenderers={DocViewerRenderers}
                documents={[
                  {
                    uri: url,
                    fileType: fileType,
                  },
                ]}
                config={{
                  header: {
                    disableHeader: true,
                    disableFileName: false,
                    // retainURLParams: false,
                  },
                }}
                // style={{ height: "85vh" }}
              />
            </div>
          </DialogContent>
        </Dialog>
      )}
      {deleteFile.openPopup && (
        <DeleteConfirmation
          name={"File"}
          setModal={(value) => {
            if (value) {
              dispatch(deleteProviderFileById({ fileId: deleteFile.fileId }));
            }
            setDeleteFile({
              openPopup: false,
              fileId: "",
            });
          }}
        />
      )}
      <Dialog open={permissionModal} className="checklistModal">
        <Box className="smallModel__head" style={{ padding: "12px 16px" }}>
          <Typography variant="h3" className="smallModel__title">
            Visibility Permission
          </Typography>
          <Button
            className="smallModel__closeBtn"
            onClick={() => {
              setPermissionModal(false);
              // setPermissionSettings({
              //   fileOwner: {
              //     ownerCanSee: false,
              //   },
              // });
            }}
          >
            <Close />
          </Button>
        </Box>
        <DialogContent>
          <Box className="permission-setting">
            <Typography className="permission-setting-label">
              Share this file with {providerName}.
            </Typography>
            <Switch
              checked={permissionSettings?.fileOwner.ownerCanSee || false}
              onChange={() => {
                setPermissionSettings({
                  fileOwner: {
                    ownerCanSee: !permissionSettings.fileOwner.ownerCanSee,
                  },
                });
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions className="checklistModal-footer">
          <Button
            className="button"
            variant="contained"
            onClick={handleUpdatePermission}
          >
            Save Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProviderSubFilesList;
