import React, { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { Box } from "@mui/material";
import {
  EditTemplate,
} from "../../../../types/businessSettings";
import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";
import QuillResizeImage from "quill-resize-image";
import Quill from "quill";
// Register the resize image module
Quill.register("modules/resizeImage", QuillResizeImage);

interface IMessageEditor {
  setTextField: React.Dispatch<React.SetStateAction<string>>;
  messageTemplateData: string;
  isDataChanged: boolean;
}

const MessageEditor: React.FC<IMessageEditor> = ({
  setTextField,
  messageTemplateData,
  isDataChanged,
}) => {
  const modules = {
    resizeImage: {
      displaySize: true, // Show size next to the image
    }
  };
  const { quill, quillRef } = useQuill({modules});
  // used for storing cursor position in editor
  const [cursorPosition, setCursorPosition] = useState<number | undefined>(0);

  //getCurrent template
  const currentTemplate = useSelector<RootState, EditTemplate>(
    (state) => state.dataCatalogSlice.currentMessageTemplate
  );

  useEffect(() => {
    if (quill) {
      //for pasting images
      quill.root.addEventListener('paste', async function(e: ClipboardEvent) {
        const clipboard = e.clipboardData;
        if (!clipboard) return;

        // Handle images in clipboard
        for (let i = 0; i < clipboard.items.length; i++) {
          const item = clipboard.items[i];
          if (item.type.indexOf("image") !== -1) {
            e.preventDefault();
            
            const blob = item.getAsFile();
            if (!blob) continue;

            const reader = new FileReader();
            reader.onload = (e) => {
              const dataUrl = e.target?.result as string;
              if (!dataUrl) return;
              const range = quill.getSelection(true);
              quill.insertEmbed(range.index, "image", dataUrl, "user");
            };
            reader.readAsDataURL(blob);
          }
        }
      });
      //for finding and storing cursor position
      quill.on(
        "editor-change",
        function (eventName: string, range: { index: number; length: number }) {
          if (eventName === "selection-change") {
            if (range) {
              setCursorPosition(range.index);
            }
          }
          if (eventName === "text-change") {
            var cursorPos = quill.getSelection();
            setCursorPosition(cursorPos?.index);
          }
          const editor = quill.root;
          const elements = editor.querySelectorAll("p, h1, h2, h3, h4, h5, h6");
          elements.forEach((el: any) => {
            el.style.margin = "0";
          });
          //sets message contents of editor
          const text = quillRef.current.firstChild.innerHTML.replace(
            /<p><br><\/p>/g,
            "<p>&nbsp;</p>" // Keeps empty paragraphs as non-breaking spaces
          );
          setTextField(text);
        }
      );

      //prints messageTemplate at cursorPosition
      if (cursorPosition || cursorPosition !== undefined) {
        if (messageTemplateData) {
          quill.clipboard.dangerouslyPasteHTML(
            cursorPosition,
            " {{" + messageTemplateData + "}}"
          );
        }
      }

      // Set the cursor focus at the end of the text
      quill.setSelection(quill.getLength(), 0);
    }

    // eslint-disable-next-line
  }, [quill, messageTemplateData, isDataChanged]);

  // used for edit message Editor content
  useEffect(() => {
    if (!quill) return;
    
    if (currentTemplate?.isEdit === true && currentTemplate?.templateData?.message) {
      // Insert template data
      quill.root.innerHTML = currentTemplate.templateData.message;
  
      // Use setTimeout to ensure everything has rendered properly
      setTimeout(() => {
        // ✅ Ensure all images retain their saved width & height
        const images = quill.root.querySelectorAll("img");
        images.forEach((img) => {
          const width = img.getAttribute("width");
          const height = img.getAttribute("height");
  
          if (width && height) {
            img.style.width = `${width}px`;
            img.style.height = `${height}px`;
          }
        });
        
        // Set cursor focus at the end - MOVED INSIDE THE TIMEOUT
        quill.setSelection(quill.getLength(), 0);
        
      }, 30); // Slightly longer timeout to ensure content is fully rendered
    }
  }, [currentTemplate?.isEdit, currentTemplate?.templateData?.message, quill]);

  //#endregion

  return (
    <Box className="massageBox">
      <div ref={quillRef} />
    </Box>
  );
};

export default MessageEditor;