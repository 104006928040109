import { useNavigate, Link } from "react-router-dom";
import { Descope } from "@descope/react-sdk";
import { useSession } from "@descope/react-sdk";
import { Typography, Box } from "@mui/material";
import SidePanel from "../sign-up/SidePanel";
import "./SignIn.scss";
import ABAEngineLogo from "../../../assets/images/logos/abaengine.png";
import { updateLastLogin } from "src/redux/features/user-slice";
import { useAppDispatch } from "src/redux/store";

const SignIn: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useSession();
  const dispatch = useAppDispatch();

  if (isAuthenticated) {
    return null;
  }

  const handleLastLogIn = async (email: any) => {
    await dispatch(updateLastLogin({ email: email }));
  };

  return (
    <Box className="auth-container">
      <SidePanel />
      <Box className="descope-container">
        <Box className="logo" component={"img"} src={ABAEngineLogo}></Box>
        <Descope
          flowId="otp-sign-in"
          theme="light"
          onSuccess={(e) => {
            handleLastLogIn(e.detail.user?.email);
            // navigate("/map");
          }}
          onError={(err) => {
            console.log("Descope Error!", err);
          }}
        />
        <Typography sx={{ textAlign: "center", marginBottom: "15px" }}>
          New to ABA Engine? Sign up{" "}
          <Box component={"span"}>
            <Link
              to="/sign-up"
              style={{ color: "#096DD9", textDecoration: "none" }}
            >
              here
            </Link>
          </Box>
        </Typography>
      </Box>
    </Box>
  );
};

export default SignIn;
