import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Edit, Close } from "@mui/icons-material";
import ReactTooltip from "react-tooltip";
import CalendarHeatmap, {
  ReactCalendarHeatmapValue,
} from "react-calendar-heatmap";
import { ProviderForms } from "../../../../types/provider";
import { AppDispatch, RootState } from "../../../../redux/store";
import {
  setAvailabilityDetails,
  setInitialState,
  updateProviderAvailabilityById,
} from "../../../../redux/features/providers-main/provider-slice";
import moment from "moment";
// import AvailabilityDetailsFormNew from "../../providers-forms/AvailabilityDetailsFormNew";
import { setSelectedAvailabiltyValues } from "../../../../redux/features/Common/availability-slice";
import {
  AvailabilityDetails,
  ISelectedAvailability,
} from "../../../../types/availability";
import { sortDataArray } from "../../../../utils/AvailabilityUtils";
import AvailabilityDetailsNew from "../../providers-forms/AvailabilityDetailsFormNew";

//Note: Follow the ClientSubAvaialbility Code Pattern
interface ProviderSubAvailabilityProps {
  providerData: ProviderForms;
  setIsDataEdited: React.Dispatch<React.SetStateAction<boolean>>;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ProviderSubAvailability: React.FC<ProviderSubAvailabilityProps> = ({
  providerData,
  setIsDataEdited,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //handles the popup for editing availability details
  const [editAvailabilityDetails, setEditAvailabilityDetails] =
    useState<boolean>(false);

  //stores availability values
  const availabiltyValues = useSelector<RootState, ISelectedAvailability>(
    (state) => state.availabilitySlice
  );

  // #endregion

  // #region methods region

  //handles the data setting in redux for client availability details
  const handleEditAvailabilityClick = () => {
    if (providerData.availabilityDetails.length) {
      dispatch(setAvailabilityDetails(providerData?.availabilityDetails));
    } else {
      // dispatch(addNewAvailability());
    }
    setEditAvailabilityDetails(true);
  };

  //availability details update handler
  const onSubmitAvailabilityInfo = () => {
    // dispatch(setBeginDate(new Date().toLocaleString()));  //use today to reset beginDate
    dispatch(updateProviderAvailabilityById());
    setEditAvailabilityDetails(false);
  };

  //used to ser availability
  const handleChangeAvailability = (value: string) => {
    const availability = providerData.availabilityDetails.filter(
      (item) => item.id === value
    );

    dispatch(
      setSelectedAvailabiltyValues({
        name: "availability",
        value: availability[0],
      })
    );
    setIsDataEdited(true);
    return availability[0];
  };

  //handles the logic to show the current date for provider availability details if the beginDate is less than current date
  // const providerAvailabilityDetails = sortDataArray<AvailabilityDetails>(
  //   providerData?.availabilityDetails
  // )?.map((item: AvailabilityDetails) => {
  //   const currentDate = new Date();
  //   const compareDate = moment(item?.beginDate).isBefore(currentDate);
  //   if (compareDate) {
  //     return { ...item, beginDate: currentDate.toLocaleString() };
  //   }
  //   return item;
  // });

  // #endregion

  // #region useEffect region

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  // #endregion

  return (
    <Grid size={{ xs: 12 }}>
      <div className="detailsCard">
        <div className="detailsCard__header">
          <Typography className="detailsCard__title">Availability</Typography>
          <IconButton
            className="detailsCard__headBtn"
            size="small"
            edge="start"
            onClick={() => handleEditAvailabilityClick()}
          >
            <Edit />
          </IconButton>
        </div>
        <div className="detailsCard__body">
          <div>
            <Grid container spacing={3} alignItems="top">
              <Grid size={{ xs: 12, lg: 4, xl: 3 }}>
                <div className="fromGroup matchScheduleBox-time max-w-250px mb-6">
                  <Select
                    className="form-control"
                    value={availabiltyValues.availability?.id || ""}
                    onChange={(e) => {
                      handleChangeAvailability(e.target.value);
                    }}
                    MenuProps={MenuProps}
                  >
                    {sortDataArray<AvailabilityDetails>(
                      providerData?.availabilityDetails
                    )?.map((item: AvailabilityDetails, index: number) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item?.beginDate
                            ? moment(item?.beginDate).format("MM/DD/YYYY")
                            : ""}
                          {" - "}
                          {item?.endDate
                            ? moment(item?.endDate).format("MM/DD/YYYY")
                            : ""}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
                <div className="availabilityStatus">
                  <span className="unAvailableBox">Unavailable</span>
                  <span className="availableBox">Available</span>
                  <span className="partiallyBookBox">Partially Booked</span>
                  <span className="fullyBookBox">Fully Booked</span>
                </div>
              </Grid>
              <Grid size={{ xs: 12, lg: 8, xl: 9 }}>
                <CalendarHeatmap
                  showOutOfRangeDays={true}
                  startDate={moment(
                    availabiltyValues.availability?.beginDate
                  ).format("MM/DD/YYYY")}
                  endDate={moment(availabiltyValues.availability?.beginDate)
                    .add(12, "M")
                    .format("MM/DD/YYYY")}
                  values={
                    providerData?.availabilityDetails.length
                      ? ([
                          ...availabiltyValues.availability?.heatMap,
                        ] as ReactCalendarHeatmapValue<string>[])
                      : []
                  }
                  showWeekdayLabels
                  showMonthLabels
                  weekdayLabels={[
                    "Sun",
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                  ]}
                  gutterSize={4}
                  tooltipDataAttrs={(
                    value?: ReactCalendarHeatmapValue<string>
                  ) => {
                    if (value?.date) {
                      const formattedDate = moment(new Date(value.date)).format(
                        "ddd YYYY-MM-DD"
                      );
                      const tooltipContent = value.availableHours
                        ? `date: ${formattedDate}<br/>` +
                          `available: ${value.availableHours}<br/>` +
                          `booked: ${value.bookedHours}<br/>` +
                          `remaining: ${value.remainingHours}`
                        : `date: ${formattedDate}`;

                      // Always return a valid TooltipDataAttrs object
                      return {
                        "data-tip": tooltipContent,
                        "data-html": true,
                      } as unknown as CalendarHeatmap.TooltipDataAttrs;
                    }

                    // Explicitly returning an empty TooltipDataAttrs object instead of undefined
                    return {
                      "data-tip": "",
                      "data-html": true,
                    } as unknown as CalendarHeatmap.TooltipDataAttrs;
                  }}
                  classForValue={(value) => {
                    if (!value) {
                      return "color-empty";
                    }
                    if (value?.pending) {
                      return `color-pending color-square-${value.count}`;
                    } else {
                      return `color-square-${value.count}`;
                    }
                  }}
                />
                <ReactTooltip />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {editAvailabilityDetails && (
        <Dialog open={editAvailabilityDetails} className="largeModel">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Availability
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                dispatch(setInitialState(null));
                setEditAvailabilityDetails(false);
              }}
            >
              <Close />
            </Button>
          </Box>
          <AvailabilityDetailsNew
            isBoardPage={false}
            isNew={false}
            updateHandler={onSubmitAvailabilityInfo}
          />
        </Dialog>
      )}
    </Grid>
  );
};

export default ProviderSubAvailability;
