import { Box, Typography, Button, Dialog } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./business-settings.scss";
import CloseIcon from "@mui/icons-material/Close";
import {
  NewDataCatalog,
} from "../../../types/businessSettings";
import {
  NewDataCatalogs,
} from "../../../constants/parent-settings";
import CatalogTemplate from "./CatalogTemplate";
import BusinessForm from "./BusinessForm";
import BusinessCatalog from "./BusinessCatalog";
import BusinessScheduling from "./bussiness-setting-component/BusinessScheduling";
import BusinessMessage from "./message-template/BusinessMessage";
import BusinessCommunication from "./bussiness-setting-component/BusinessCommunication";

import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { OrgData } from "../../../types/company";
import { OrgService } from "../../../constants/company-user";
import BusinessEmployeePortal from "./bussiness-setting-component/BusinessEmployeePortal";
import { setCurrentTemplate } from "../../../redux/features/data-catalog/data-catalog-slice";
import AdminScheduleSetting from "./bussiness-setting-component/AdminScheduleSettings";
import resourceCenterLogo from "../../../assets/images/images-svg/resource-center.svg";

const BusinessSettings: React.FC = () => {
  //#region variable region

  //store location
  const { state } = useLocation();

  const dispatch = useDispatch<AppDispatch>();

  const org = useSelector<RootState, OrgData>(
    (state) => state.organizationSlice.orgData
  );

  const navigate = useNavigate();

  //store selected tab
  const [selectedTab, setSelectedTab] = useState<string>(state?.tab || "Forms");

  //handle Open dialog
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // used to handle edit dialog
  const [isEdit, SetIsEdit] = useState<boolean>(false);

  //used to handle selected Data
  const [selectedCatalogData, setSelectedCatalogData] =
    useState<NewDataCatalog>(NewDataCatalogs);

  //store selected filter catalogname values
  const [filtercatalogname, setfiltercatalogname] = useState<string>("");

  //#endregion

  // console.log("selectedTab:", selectedTab)

  return (
    <>
      <div className="pageTemplate settingPage">
        <h3 className="settingPage__title">Business Settings</h3>
        <div className="pageTemplate-top">
          <ul className="innerTabs mb-tab">
            <li className="innerTabs__item form-tabs">
              <button
                onClick={() => setSelectedTab("Forms")}
                className={selectedTab === "Forms" ? "active" : ""}
              >
                Forms
              </button>
            </li>
            {/* {!(
              org?.orgService?.length === 1 &&
              org.orgService.includes(OrgService.MentalHealth)
            ) && (
              <li className="innerTabs__item">
                <button
                  onClick={() => setSelectedTab("Scheduling")}
                  className={selectedTab === "Scheduling" ? "active" : ""}
                >
                  Scheduling
                </button>
              </li>
            )} */}
            <li className="innerTabs__item form-tabs">
              <button
                onClick={() => setSelectedTab("MessageTemplates")}
                className={selectedTab === "MessageTemplates" ? "active" : ""}
              >
                Templates
              </button>
            </li>
            <li className="innerTabs__item form-tabs">
              <button
                onClick={() => setSelectedTab("DataCatalog")}
                className={selectedTab === "DataCatalog" ? "active" : ""}
              >
                Catalog
              </button>
            </li>
            <li className="innerTabs__item form-tabs">
              <button
                onClick={() => setSelectedTab("Communication")}
                className={selectedTab === "Communication" ? "active" : ""}
              >
                Communication
              </button>
            </li>
            <li className="innerTabs__item form-tabs">
              <button
                onClick={() => setSelectedTab("Scheduling")}
                className={selectedTab === "Scheduling" ? "active" : ""}
              >
                Scheduling
              </button>
            </li>
            {!(
              org?.orgService?.length === 1 &&
              org.orgService.includes(OrgService.MentalHealth)
            ) && (
              <li className="innerTabs__item form-tabs">
                <button
                  onClick={() => setSelectedTab("ParentPortal")}
                  className={selectedTab === "ParentPortal" ? "active" : ""}
                >
                  Parent Portal
                </button>
              </li>
            )}
            <li className="innerTabs__item form-tabs">
              <button
                onClick={() => setSelectedTab("EmployeePortal")}
                className={selectedTab === "EmployeePortal" ? "active" : ""}
              >
                Employee Portal
              </button>
            </li>
          </ul>
          {selectedTab === "MessageTemplates" && (
            <span className="add-temp-btn">
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/resourcehub", {
                    state: {
                      tab: "MessageTemplates",
                      group: "Client",
                    },
                  });
                }}
              >
                <img
                  className="survetFormLogo"
                  src={resourceCenterLogo}
                  alt="resourceCenterLogo"
                />
                Resource Center
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  SetIsEdit(false);
                  setOpenDialog(true);
                  dispatch(setCurrentTemplate({ isEdit: false }));
                  navigate("/message-template");
                }}
                // sx={{
                //   marginTop: "6px",
                //   maxHeight: "35px",
                //   width: "150px",
                // }}
              >
                + Create New
              </Button>
              {/* <Button
                variant="contained"
                onClick={() => {
                  navigate("/resourcehub"
                  // {
                  //   state: {
                  //     filterFormGroup,
                  //   },
                  // }
                );
                }}
              >
                <img className="survetFormLogo" src={resourceCenterLogo} alt="resourceCenterLogo" />
                  Resources
              </Button> */}
            </span>
          )}
        </div>

        <Box>
          {selectedTab === "Forms" && <BusinessForm tabState={state?.group} />}
          {selectedTab === "MessageTemplates" && <BusinessMessage />}
        </Box>

        {selectedTab === "DataCatalog" && (
          <BusinessCatalog
            setOpenDialog={setOpenDialog}
            SetIsEdit={SetIsEdit}
            setSelectedCatalogData={setSelectedCatalogData}
            filtercatalogname={filtercatalogname}
            setfiltercatalogname={setfiltercatalogname}
          />
        )}
        {selectedTab === "DataCatalog" && openDialog && (
          <Dialog open={openDialog} className="largeModel largeModel--edit">
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                {isEdit ? "Edit Concept Item" : "Add Concept Item"}
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  setOpenDialog(false);
                  setSelectedCatalogData(NewDataCatalogs);
                  SetIsEdit(false);
                }}
              >
                <CloseIcon />
              </Button>
            </Box>
            <CatalogTemplate
              setOpenDialog={setOpenDialog}
              isEdit={isEdit}
              setSelectedCatalogData={setSelectedCatalogData}
              selectedCatalogData={selectedCatalogData}
              filtercatalogname={filtercatalogname}
            />
          </Dialog>
        )}
        {selectedTab === "Communication" && (
          <BusinessCommunication selectedGroup={state?.type || ""} />
        )}
        {selectedTab === "ParentPortal" && <BusinessScheduling />}
        {selectedTab === "EmployeePortal" && <BusinessEmployeePortal />}
        {selectedTab === "Scheduling" && <AdminScheduleSetting />}
      </div>
    </>
  );
};

export default BusinessSettings;
