import {
  Box,
  Button,
  Card,
  FormGroup,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { AppDispatch, RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import {
  PostCustomForm,
  UpdateExternalForm,
} from "../../../redux/features/intake/intake-form-slice";
import { IAssessmentFormData } from "../../../types/assessment";
import { GetFormById } from "../../../redux/features/forms/form-slice";
import { useNavigate } from "react-router-dom";

interface IparentAppForm {
  editFormDetails?: boolean;
  setAddFormsDetails: React.Dispatch<React.SetStateAction<boolean>>;
  setEditFormDetails: React.Dispatch<React.SetStateAction<boolean>>;
  parentformId: string;
  formType: string;
  setEditDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedFormType: Dispatch<SetStateAction<string>>;
  selectedFormType: string;
}

export enum FormTypes {
  IntakeForm = "Intake Packet Form",
  OnboardingForm = "Employee Packet Form",
}

const ParentAppForm: React.FC<IparentAppForm> = ({
  editFormDetails,
  setAddFormsDetails,
  setEditFormDetails,
  parentformId,
  formType,
  setEditDialog,
  setSelectedFormType,
  selectedFormType,
}) => {
  //#region variable region

  const navigate = useNavigate();

  //Dispatch
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //stores data for editIntakeForm
  const org = useSelector<RootState, IAssessmentFormData>(
    (state) => state.formSlice.AssessmentFormData
  );

  //store formurl data
  const [formurl, setFormurl] = useState<string>("");

  //store formurl data
  const [formname, setFormName] = useState<string>("");

  // store embed code
  const [embedCode, setEmbedCode] = useState<string>("");

  //used to handle validation for url
  const [validateUrl, setValidateUrl] = useState<boolean>(false);

  //used to handle error message
  const [hasError, setHasError] = useState<boolean>(false);
  //#endregion

  // #region methods region

  //Regex used for validation
  const isURL = (str: any) => {
    var pattern = new RegExp(
      "^((ft|htt)ps?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?" + // port
        "(\\/[-a-z\\d%@_.~+&:]*)*" + // path
        "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  };

  //#endregion

  //#region useEffect region

  //gets form data for selectedId
  useEffect(() => {
    if (parentformId && editFormDetails) {
      dispatch(GetFormById({ formId: parentformId }));
    }
  }, [dispatch, editFormDetails, parentformId]);

  //fetch edited data
  useEffect(() => {
    if (editFormDetails && parentformId) {
      setFormName(org.name);
      setFormurl(org.url);
      setEmbedCode(org?.embeddedCode);
    }
  }, [
    dispatch,
    editFormDetails,
    org?.embeddedCode,
    org.name,
    org.url,
    parentformId,
  ]);

  //#endregion

  return (
    <div className="settingBox__part-inner">
      <FormGroup className="settingBox__field formBox">
        <div className="settingBox__formBox">
          <Box className="fromGroup">
            <InputLabel className="fromLabel">Form Name</InputLabel>
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              placeholder="Enter form Name"
              value={formname.trimStart()}
              onChange={(e) => {
                setFormName(e.target.value);
                if (!formname) {
                  setHasError(true);
                }
              }}
            />
            {hasError && !formname.trim() && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Please enter form name
              </FormHelperText>
            )}
          </Box>
          <Box className="fromGroup">
            <InputLabel className="fromLabel">Form URL</InputLabel>
            <TextField
              id="outlined-basic"
              label=""
              variant="outlined"
              placeholder="Enter form Link"
              value={formurl}
              onChange={(e) => {
                if (e.target.value.length === 0 || !isURL(e.target.value)) {
                  setValidateUrl(true);
                } else {
                  setValidateUrl(false);
                }
                setFormurl(e.target.value);
              }}
            />
            {validateUrl && !isURL(formurl) && formurl?.length > 0 && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Please add valid url
              </FormHelperText>
            )}

            {validateUrl && formurl?.length === 0 && (
              <FormHelperText style={{ color: "#d32f2f" }}>
                Please add url
              </FormHelperText>
            )}
          </Box>
          <Box className="fromGroup">
            <InputLabel className="fromLabel">Form Type</InputLabel>
            <div style={{ display: "flex", gap: "20px" }}>
              {Object.values(FormTypes).map((item, index) => (
                <Box className="fromGroup-chips">
                  <Card
                    key={index}
                    className={
                      selectedFormType === item ? "fromGroup-chip-active" : ""
                    }
                    onClick={() => {
                      setSelectedFormType(item);
                    }}
                  >
                    {item}
                  </Card>
                </Box>
              ))}
            </div>
          </Box>
          <Box className="fromGroup">
            <InputLabel className="fromLabel">
              Form Embedded Code (optional)
            </InputLabel>
            <TextField
              multiline
              id="outlined-basic-embed"
              label=""
              variant="outlined"
              rows={3}
              placeholder="Enter embedded code"
              value={embedCode}
              onChange={(e) => {
                setEmbedCode(e.target.value);
              }}
            />
          </Box>
          <div className="largeModel__footer">
            <Button
              className="button"
              type="submit"
              onClick={() => {
                if (
                  formurl?.length === 0 ||
                  !isURL(formurl) ||
                  !formname.trim()
                ) {
                  setValidateUrl(true);
                  setHasError(true);
                  return;
                }
                if (editFormDetails && parentformId) {
                  dispatch(
                    UpdateExternalForm({
                      id: parentformId,
                      name: formname,
                      formURL: formurl,
                      embeddedCode: embedCode,
                      type: "External",
                      formType: formType,
                    })
                  );
                } else {
                  dispatch(
                    PostCustomForm({
                      name: formname,
                      formURL: formurl,
                      formEmbeddedCode: embedCode,
                      type: "External",
                      formType: formType,
                    })
                  );
                }
                navigate("/business-settings", {
                  state: {
                    tab: "Intake",
                  },
                });
                setAddFormsDetails(false);
                setEditFormDetails(false);
                setEditDialog(false);
                setValidateUrl(false);
              }}
            >
              Save Changes
            </Button>
          </div>
        </div>
      </FormGroup>
    </div>
  );
};

export default ParentAppForm;
