import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Board, boardInit } from "../../../types/board";
import { setAPIStatus } from "../active-steps/active-slice";
import { setResponseValue } from "../api-response/api-response";
import BoardService, {
  createNewBoard,
  deleteBoard,
  getAllCustomBoards,
  updateExistingBoard,
  getUserTemplate,
  getBoardCard,
  getAutomation,
  postAutomation,
} from "../../../service/customBoard.service";
import { getAllClientIntakeStage } from "../client-main/client-intake-stage";
import { getAllProviderOnBoardingStages } from "../providers-main/provider-onboardingstage-slice";
import { getProviderBoards } from "../providers-main/provider-board-slice";
import { getClientBoards } from "../client-main/client-board-slice";
import { defaultAutomation } from "../../../constants/automation";

const initialState: boardInit = {
  allBoard: [],
  assessmentBoard: [],
  newBoard: {
    id: "",
    name: "",
    description: "",
    stages: [
      {
        id: "",
        //name: "",
        goal: "1 day",
        client: [],
        provider: [],
        checkList: [],
        index: 0,
        boardColumnNames: [],
        stageName: "Default Stage",
      },
    ],
    automation: [defaultAutomation],
    boardType: "client", //auto-assign default
    boardName: "",
    primitive: false,
    index: 0,
    permission: {
      viewClient: [],
      viewProvider: [],
    },
  },
  templates: [],
  allCards: [],
  selectedCard: [],
  allAutomation: [],
  saveAutomation: false,
  selectedTab: {
    id: "Intake",
    type: "Clients",
  },
  scrollPosition: [],
  selectedBusinessUnits: [],
  automationUpdated: false,
};

const customBoardSlice = createSlice({
  name: "custom-board",
  initialState: initialState,
  reducers: {
    setNewBoard: (state, action) => {
      return {
        ...state,
        newBoard: {
          ...state.newBoard,
          [action.payload.name]: action.payload.value,
        },
      };
    },
    resetNewBoard: (state) => {
      return {
        ...state,
        newBoard: {
          id: "",
          name: "",
          description: "",
          stages: [
            {
              id: "",
              //name: "",
              goal: "1 day",
              client: [],
              provider: [],
              checkList: [],
              index: 0,
              boardColumnNames: [],
              stageName: "Default Stage",
            },
          ],
          automation: [defaultAutomation],
          boardType: "client", //auto-assign default
          boardName: "",
          primitive: false,
          index: 0,
          permission: {
            viewClient: [],
            viewProvider: [],
          },
        },
      };
    },
    setEditBoard: (state, action) => {
      return {
        ...state,
        newBoard: {
          id: action.payload.id,
          name: action.payload.boardName,
          description: action.payload.boardDescription,
          stages: action.payload.stages,
          boardType: action.payload.boardType,
          boardName: action.payload.boardName,
          automation: action.payload.automation,
          primitive: action.payload.primitive ? true : false,
          index: action.payload.index,
          permission: action.payload.permission,
        },
      };
    },
    updateCustomBoardStage: (stage, action) => {
      const passAllboard = stage.allBoard.map((board): Board => {
        if (board.id === action.payload.boardId) {
          return { ...board, stages: action.payload.stages };
        } else {
          return board;
        }
      });
      return { ...stage, allBoard: passAllboard };
    },
    setSelectedCard: (state, action) => {
      return { ...state, selectedCard: action.payload };
    },
    setAllAutomation: (state, action) => {
      return { ...state, allAutomation: action.payload, saveAutomation: true };
    },
    setSaveAutomation: (state, action) => {
      return { ...state, saveAutomation: action.payload };
    },
    setSelectedBoardTab: (state, action) => {
      return {
        ...state,
        selectedTab: { ...state.selectedTab, ...action.payload },
      };
    },
    setAutomationOrder: (state, action) => {
      if (action.payload !== undefined) {
        const data = state.allAutomation.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              automation: action.payload.automation,
            };
          } else {
            return item;
          }
        });
        return {
          ...state,
          allAutomation: data,
          saveAutomation: true,
        };
      }
    },
    setBoardScrollPosition: (state, action) => {
      return {
        ...state,
        scrollPosition: action.payload,
      };
    },
    setBusinessUnitFilters: (state, action) => {
      const { boardId, businessUnits } = action.payload;
    
      // Ensure `selectedBusinessUnits` is an array
      if (!Array.isArray(state.selectedBusinessUnits)) {
        state.selectedBusinessUnits = [];
      }
    
      const existingBoardIndex = state.selectedBusinessUnits.findIndex(
        (item) => item.boardId === boardId
      );
    
      if (existingBoardIndex !== -1) {
        // Update the business units for the existing board
        state.selectedBusinessUnits[existingBoardIndex].businessUnits = businessUnits;
      } else {
        // Add a new entry for the board
        state.selectedBusinessUnits.push({ boardId, businessUnits });
      }
    },
    setAutomationUpdated:(state, action) => {
      return {
        ...state,
        automationUpdated: action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(addNewCustomBoard.rejected, (state, action) => {
      setAPIStatus(false);
    });
    builder.addCase(addNewCustomBoard.fulfilled, (state, action) => {
      setAPIStatus(true);
      if (action.payload) {
        return {
          ...state,
          allBoard: [...state.allBoard, action.payload],
        };
      }
    });
    builder.addCase(getAllCustomBoard.fulfilled, (state, action: any) => {
      if (action.payload) {
        return {
          ...state,
          allBoard: action.payload.allBoard,
        };
      }
    });
    builder.addCase(updateCustomBoard.fulfilled, (state, action) => {
      return { ...state, allBoard: action.payload || state.allBoard };
    });
    builder.addCase(deleteCustomBoard.fulfilled, (state, action) => {
      if (action.payload !== undefined) {
        return {
          ...state,
          allBoard: state.allBoard.filter(
            (item) => item.id !== action.payload
          ),
          allAutomation: state.allAutomation.filter(
            (item) => item.id !== action.payload
          ),
        };
      }
    });
    builder.addCase(getAllUserTemplate.fulfilled, (state, action) => {
      return { ...state, templates: action.payload };
    });
    builder.addCase(getAllAutomation.fulfilled, (state, action) => {
      return { ...state, allAutomation: action.payload };
    });
  },
});

export const addNewCustomBoard = createAsyncThunk(
  "custom-board/addNewCustomBoard",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    const { boardSlice } = getState() as { boardSlice: boardInit };
    const sendData = boardSlice.newBoard;

    try {
      const response = await createNewBoard(sendData);
      if (response.data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: response.message,
          })
        );
        const sliceData: Board = {
          id: response.data.id,
          name: response.data.boardName,
          description: response.data.boardDescription,
          boardType: response.data.boardType,
          boardName: response.data.boardName,
          stages: response.data.stages,
          automation: response.data.automation,
          primitive: response.data.primitive,
          index: response.data.index,
        };
        return sliceData;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getAllCustomBoard = createAsyncThunk(
  "custom-board/getAllCustomBoard",
  async (_, { dispatch }) => {
    try {
      const allBoard: Board[] = await getAllCustomBoards();
      return { allBoard };
    } catch (e) {
      dispatch(setResponseValue({ name: "error", value: true }));

      return [];
    }
  }
);

export const updateCustomBoard = createAsyncThunk(
  "custom-board/updateCustomBoard",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    const { boardSlice } = getState() as { boardSlice: boardInit };
    const sendData = boardSlice.newBoard;

    try {
      const { data, message } = await updateExistingBoard(sendData);
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
      if(boardSlice.automationUpdated){
        dispatch(getAllAutomation());
        dispatch(setAutomationUpdated(false));
      }
      if (data.boardCategory === "custom") {
        return boardSlice.allBoard.map((board) => {
          if (board.id !== sendData.id) {
            return board;
          } else {
            return data;
          }
        });
      } else {
        if (data.boardCategory === "intake") {
          dispatch(setEditBoard(data));
          await dispatch(getAllClientIntakeStage());
        } else {
          await dispatch(getAllProviderOnBoardingStages());
        }
        return boardSlice.allBoard;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const deleteCustomBoard = createAsyncThunk(
  "custom-board/deleteCustomBoard",
  async (_, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    const { boardSlice } = getState() as { boardSlice: boardInit };
    const sendData = boardSlice.newBoard;

    try {
      const response = await deleteBoard(sendData);
      if (response.data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: response.message,
          })
        );
        return sendData.id;
      }
      // return boardSlice.allBoard.filter((board) => board.id !== sendData.id);
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const getAllUserTemplate = createAsyncThunk(
  "custom-board/getAllUserTemplate",
  async (_, { getState, dispatch }) => {
    try {
      const response = await getUserTemplate();
      return response.data;
    } catch (e: any) {
      return [];
    }
  }
);

export const getAllBoardCard = createAsyncThunk(
  "custom-board/getAllBoardCard",
  async () => {
    try {
      const response = await getBoardCard();
      return response.data;
    } catch (e: any) {
      return [];
    }
  }
);

export const getAllAutomation = createAsyncThunk(
  "custom-board/getAllBoardCard",
  async () => {
    try {
      const response = await getAutomation();
      return response.data;
    } catch (e: any) {
      return [];
    }
  }
);

export const saveAutomationChanges = createAsyncThunk(
  "custom-board/saveAutomationChages",
  async (_, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { boardSlice } = getState() as { boardSlice: boardInit };
      const automation = boardSlice.allAutomation;
      const { data, message } = await postAutomation(automation);
      if (data) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: message,
          })
        );
      }
      dispatch(setSaveAutomation(false));
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
      return false;
    }
  }
);

export const DeleteBoardcard = createAsyncThunk(
  "delete/boardCard",
  async ({ id }: { id: string }, { getState, dispatch }) => {
    dispatch(setResponseValue({ name: "pending", value: true }));
    try {
      const response = await BoardService.deleteBoardCard(id);

      if (response.status === 1) {
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({
            name: "message",
            value: response.message,
          })
        );
        dispatch(getClientBoards({ useLoader: false }));
        dispatch(getProviderBoards({ useLoader: false }));
        dispatch(getAllCustomBoard());
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(
        setResponseValue({
          name: "message",
          value: e.message,
        })
      );
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const {
  setNewBoard,
  resetNewBoard,
  setEditBoard,
  updateCustomBoardStage,
  setSelectedCard,
  setAllAutomation,
  setSaveAutomation,
  setSelectedBoardTab,
  setAutomationOrder,
  setBoardScrollPosition,
  setBusinessUnitFilters,
  setAutomationUpdated,
} = customBoardSlice.actions;

export default customBoardSlice;
