import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import ProviderService from "../../../service/provider.service";
import { Board, stages } from "../../../types/board";
import { setResponseValue } from "../api-response/api-response";
import { defaultAutomation } from "../../../constants/automation";

const initialState: Board = {
  id: "",
  name: "",
  description: "",
  stages: [
    {
      id: "",
      //name: "",
      goal: "1 day",
      client: [],
      provider: [],
      checkList: [],
      index: 0,
      boardColumnNames: [],
      stageName: "Default Stage",
    },
  ],
  automation: [defaultAutomation],
  boardType: "provider", //auto-assign default
  boardName: "",
  index: 0,
  permission: {
    viewClient: [],
    viewProvider: [],
  },
};

const providerOnBoardingStage = createSlice({
  name: "providerOnBoardingStage",
  initialState: initialState,
  reducers: {
    updateOnBoardingStage: (state, action) => {
      if (action.payload) {
        if (action.payload.boardId !== state.id) return;
        state.stages = state.stages.map((item) => {
          const updatedStage = action.payload.updatedStages.find(
            (stage: stages) => stage.id === item.id
          );
          return updatedStage ? updatedStage : item;
        });
      }
    },
    setOnboardingStage: (state, action) => {
      if (action.payload) {
        state.stages = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getAllProviderOnBoardingStages.fulfilled,
      (state, action: PayloadAction<any | undefined>) => {
        if (action.payload) {
          return {
            id: action.payload.id,
            name: action.payload.boardName,
            description: action.payload.boardDescription,
            stages: action.payload.stages,
            boardType: action.payload.boardType,
            boardName: action.payload.boardName,
            automation: action.payload.automation,
            index: action.payload.index,
            primitive:true,
            permission:
              action.payload.permission !== null
                ? action.payload.permission
                : { viewProvider: [] },
          };
        }
      }
    );
  },
});

export const getAllProviderOnBoardingStages = createAsyncThunk(
  "providerOnBoardingStage/getAllProviderOnBoardingStages",
  async (_, { getState, dispatch }) => {
    try {
      dispatch(setResponseValue({ name: "pending", value: true }));
      const { data, status } =
        await ProviderService.getProviderOnBoardingStages();
      if (status) {
        return data;
      }
    } catch (e: any) {
      dispatch(setResponseValue({ name: "error", value: true }));
      dispatch(setResponseValue({ name: "message", value: e?.message }));
    } finally {
      dispatch(setResponseValue({ name: "pending", value: false }));
    }
  }
);

export const { updateOnBoardingStage, setOnboardingStage } = providerOnBoardingStage.actions;

export default providerOnBoardingStage;
