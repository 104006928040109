import React, {  useRef, useState, useEffect } from "react";
import "../../providers/provider/Providers.scss";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "@reduxjs/toolkit";
import "./clientSchedules.scss";
import { AppDispatch, RootState } from "../../../redux/store";
import {
  clearSingleData,
  GetClientById,
  setSingleClientData,
} from "../../../redux/features/client-main/client-list-slice";
import {
  clearSingleProvder,
  GetProviderById,
} from "../../../redux/features/providers-main/provider-list-slice";

import { AvailabilityDetails } from "../../../types/availability";
import { ClientForms } from "../../../types/client";
import { MatchedProvider, MatchOption } from "../../../types/schedule";
import PageOne from "../client-pages/ScheduleBasicDetails";
import SingleScheduleList from "../client-pages/SingleScheduleList";
import SchedulesMatch from "../schedule-match/SchedulesMatch";
import { clearSchedulesMatchValues } from "../../../redux/features/schedules-main/match-slice";

import { getAllBookingsData } from "../../../redux/features/schedules-main/schedule-list-slice";
import { setSelectedAvailabiltyValues } from "../../../redux/features/Common/availability-slice";

import { sortDataArray } from "../../../utils/AvailabilityUtils";
import {
  defaultClientAvailabilityDetail,
  defaultProviderAvailabilityDetail,
} from "../../../constants/availability";
import { ISelectedAvailability } from "../../../types/availability";
import BookingSchedulerNew from "../booking-scheduler/BookingScheduleNew";
import { clearSchedulesFilterValues } from "../../../redux/features/schedules-main/schedules-filter-slice";
import { dataUpdated } from "../../../redux/features/providers-main/provider-list-slice";
import { ProviderForms } from "../../../types/provider";

const ClientSchedules: React.FC = () => {
  // #region variables region

  const availabiltyValues = useSelector<RootState, ISelectedAvailability>(
    (state) => state.availabilitySlice
  );

  //routing object
  const navigate = useNavigate();

  //to get data from client schedule
  const { state } = useLocation();

  //array to store the id's of selectedProviders
  const [selectedProviderIds, setSelectedProviderIds] = useState<string[]>([]);

  //selected Days from the match providers section
  const [selectedDays, setSelectedDays] = useState<number[]>([]);

  //selected client for match clients
  const [selectedClients, setSelectedClients] = useState<ClientForms[]>([]);

  //selected providers from matched providers
  const [selectedProviders, setSelectedProviders] = useState<MatchedProvider[]>(
    []
  );

  //working Hours
  const [workingHoursMap, setWorkingHoursMap] = useState<any[][]>([]);

  //id retrieved from the url to fetch a single client
  const { id } = useParams();

  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //binding action creators and dispatch
  const dispatchAction = bindActionCreators(
    {
      GetClientById,
      setSingleClientData,
      getAllBookingsData,
      GetProviderById,
    },
    dispatch
  );

  //same value between re-renders
  const dispatchActionRef = useRef(dispatchAction);

  //Variable to re-fetch the provider if the data is updated
  const [isDataEdited, setIsDataEdited] = useState<boolean>(false);

  //gets the selected single client from redux
  const clientData = useSelector<RootState, ClientForms>(
    (state) => state.clientListSlice.singleClient
  );
  //gets the selected single provider from redux
  const providerData = useSelector<RootState, ProviderForms>(
    (state) => state.providerListSlice.singleProvider
  );

  //counter from location state
  const [counter, setCounter] = useState<number>(state?.counter || 0);

  //entityType from location state
  const entityType: string =
    state?.entityType || localStorage.getItem("entityType") || "Client";

  const dataChange = useSelector<RootState, boolean>(
    (state) => state.clientListSlice.listDataChange
  );

  //match values
  const matchValues = useSelector<RootState, MatchOption>(
    (state) => state.matchSlice
  );

  //store selected availability
  const [selectedAvailability, setSelectedAvailability] =
    useState<AvailabilityDetails>(
      entityType === "Provider"
        ? defaultProviderAvailabilityDetail
        : defaultClientAvailabilityDetail
    );

  //last booking  Date to show booking on calendar
  const [lastBookedDate, setLastBookedDate] = useState<Date>();

  // #endregion

  // #region methods region

  //handles logic for going back
  const goBack = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
    e.preventDefault();
    if (counter === 0) {
      navigate(-1);
    } else if (counter === 1 && entityType === "Provider") {
      navigate(-1);
    } else {
      localStorage.removeItem("setCounter");
      //only from 1->0, trigger reset
      if (counter === 1) {
        setSelectedDays([]);
      }
      setCounter(counter - 1);
    }
    if (counter === 3) {
      const data: any = localStorage.getItem("selectedProviders");
      setSelectedClients([]);
      const parsedproviderDATA = JSON.parse(data);
      if (parsedproviderDATA) {
        setSelectedProviderIds(
          parsedproviderDATA.map((i: any) => i.employmentDetails.id)
        );
      }
    }
  };

  // #endregion

  // #region useEffect region

  //a. counter = 0 --> clear match values in redux
  useEffect(() => {
    //come from counter 0
    if (counter === 0) {
      dispatch(clearSchedulesMatchValues({}));
    }
    if (counter === 2) {
      dispatch(clearSchedulesFilterValues({}));

      dispatch(dataUpdated({ dataUpdate: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, counter]);

  //b. id, isDataEdited, datachange --> getClient data
  useEffect(() => {
    if (id) {
      // console.log("GetClientById:", id);
      if (entityType === "Client") {
        dispatchActionRef.current.GetClientById({ clientId: id });
      } else if (entityType === "Provider") {
        dispatchActionRef.current.GetProviderById({ providerId: id });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, isDataEdited, dataChange]);

  useEffect(() => {
    if (counter === 2 || counter === 3) {
      localStorage.setItem(
        "selectedProviders",
        JSON.stringify(selectedProviders)
      );
      localStorage.removeItem("bookedData");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataEdited]);

  //c. clientData --> initialize availability table based on [selectedAvailability, toay]
  useEffect(() => {
    let availabilityDetails: any[] = [];
    if (
      entityType === "Client" &&
      clientData?.availabilityDetails?.length > 0
    ) {
      availabilityDetails = clientData.availabilityDetails;
    } else if (
      entityType === "Provider" &&
      providerData?.availabilityDetails?.length > 0
    ) {
      availabilityDetails = providerData.availabilityDetails;
    }

    if (availabiltyValues && availabiltyValues?.availability?.entityId === id) {
      setSelectedAvailability(availabiltyValues?.availability);
      return;
    }

    //use today's availability as default
    const defaultDate = new Date();
    const availabilityForToday = availabilityDetails.find(
      (availability) =>
        new Date() >=
          (availability.beginDate
            ? new Date(availability.beginDate)
            : defaultDate) &&
        new Date() <=
          (availability.endDate ? new Date(availability.endDate) : defaultDate)
    );
    if (availabilityForToday) {
      dispatch(
        setSelectedAvailabiltyValues({
          name: "availability",
          value: availabilityForToday,
        })
      );
      setSelectedAvailability(availabilityForToday);
    } else {
      const defaultSelectedAvailablility =
        sortDataArray<AvailabilityDetails>(availabilityDetails)[0];
      if (defaultSelectedAvailablility !== undefined) {
        dispatch(
          setSelectedAvailabiltyValues({
            name: "availability",
            value: defaultSelectedAvailablility,
          })
        );
        setSelectedAvailability(defaultSelectedAvailablility);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clientData,
    providerData,
    providerData?.availabilityDetails?.length,
    clientData?.availabilityDetails?.length,
  ]);

  //fetch and store selectedavailability data
  useEffect(() => {
    let arr = [];
    if (clientData && matchValues) {
      arr.push(matchValues.availability?.availabilitySelectedDays);
    }

    //populate mathced provider All availability data
    if (selectedProviders) {
      for (let provider of selectedProviders) {
        if (provider && provider.availabilityDetails) {
          for (let availability of provider.availabilityDetails) {
            arr.push(availability?.availabilitySelectedDays);
          }
        }
      }
    }
    setWorkingHoursMap([...arr]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientData, selectedProviders]);

  //used for storing data in localStorage
  useEffect(() => {
    window.localStorage.setItem("setCounter", JSON.stringify(counter));

    if (counter === 2) {
      window.localStorage.setItem("ClientData", JSON.stringify(clientData));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [counter, matchValues?.selectedAvailability, clientData]);

  useEffect(() => {
    return () => {
      // console.log("remove local cache");
      dispatch(clearSingleData());
      dispatch(clearSingleProvder());
      localStorage.removeItem("MatchValue");
      localStorage.removeItem("ClientData");
      localStorage.removeItem("selectedProviders");
      localStorage.removeItem("checked");
      localStorage.removeItem("setCounter");
      localStorage.removeItem("entityType");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //#endregion
  // console.log("counter, entityType:", counter, entityType, providerData);

  return (
    <div>
      <div className="pageTemplate">
        <Box>
          <Grid container spacing={0}>
            <Grid size={{ xs: 12 }}>
              <Button
                className="backBtn"
                startIcon={<KeyboardBackspaceIcon />}
                onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
                  goBack(e)
                }
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Box>
        {counter === 1 && (
          <PageOne
            entityType={entityType}
            clientData={clientData}
            providerData={providerData}
            counter={counter}
            setCounter={setCounter}
            selectedDays={selectedDays}
            setSelectedDays={setSelectedDays}
            selectedAvailabilty={selectedAvailability}
            setSelectedAvailability={setSelectedAvailability}
          />
        )}
        {counter === 0 && (
          <SingleScheduleList
            clientData={clientData}
            isDataEdited={isDataEdited}
            setIsDataEdited={setIsDataEdited}
            setCounter={setCounter}
            // setAllMiltiDelete={setAllMiltiDelete}
            // multiDelete={multiDelete}
          />
        )}
        {counter === 2 && (
          <SchedulesMatch
            clientData={clientData}
            clientName={`${
              clientData?.clientBasicDetails?.childFirstName
            }${" "}${clientData?.clientBasicDetails?.childLastName}`}
            setCounter={setCounter}
            selectedProviders={[providerData]}
            setSelectedProviders={setSelectedProviders}
            selectedProviderIds={selectedProviderIds}
            setSelectedProviderIds={setSelectedProviderIds}
            isDataEdited={isDataEdited}
            setLastBookedDate={setLastBookedDate}
            entityType={entityType}
            selectedClients={selectedClients}
          />
        )}
        {counter === 3 && (
          <BookingSchedulerNew
            providersData={selectedProviders}
            setSelectedProviders={setSelectedProviders}
            clientId={id ? id : ""}
            isDataEdited={isDataEdited}
            setIsDataEdited={setIsDataEdited}
            workingHoursMap={workingHoursMap}
            lastBookedDate={lastBookedDate}
            selectedClients={selectedClients}
            entityType={entityType}
          />
        )}
      </div>
    </div>
  );
};

export default ClientSchedules;
