import Search from "@mui/icons-material/Search";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./formTracking.scss";
import { ClientForms } from "../../types/client";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import AddIcon from "@mui/icons-material/Add";
import { GetAllClientData } from "../../redux/features/client-main/client-list-slice";
import DeleteIcon from "@mui/icons-material/Delete";
import { IFormCard } from "../../types/forms";
import { PostFormRequest } from "../../redux/features/forms/form-slice";
import { GetAllProviders } from "../../redux/features/providers-main/provider-list-slice";
import { ProviderForms } from "../../types/provider";
import { BusinessFormType } from "../../constants/forms";

interface IIntakemodel {
  selectedFormId: string;
  setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
  selectedForm: IFormCard;
}

interface Ilist {
  id: string;
  firstName: string;
  lastName: string;
}
const IntakeModel: React.FC<IIntakemodel> = ({
  selectedFormId,
  setOpenPopup,
  selectedForm,
}) => {
  //#region variable region
  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  //fetch client list
  const ClientList = useSelector<RootState, ClientForms[]>(
    (state) => state.clientListSlice.listData
  );

  //fetch provider list
  const ProviderList = useSelector<RootState, ProviderForms[]>(
    (state) => state.providerListSlice.listData
  );

  // selected entity list
  const [selectedEntityData, setSelectedEntityData] = useState<Ilist[]>([]);

  // store selected entityIds
  const [entityIds, setEntityIds] = useState<string[]>([]);

  //store entity data for mapping
  const [dataList, setDataList] = useState<Ilist[]>([]);

  //used to show error
  const [error, setError] = useState<boolean>(false);

  const [searchList, setSearchList] = useState<Ilist[]>(dataList);

  //#endregion

  //#region method region

  //get match value
  const getMatchData = (item: Ilist, searchVal: string): boolean => {
    const fullName = `${item.firstName} ${item.lastName}`;
    if (
      searchVal &&
      !fullName.toLowerCase().includes(searchVal.toLowerCase().trim())
    ) {
      return false;
    }

    if (searchVal === "") {
      return false;
    }
    return true;
  };

  //get client match value
  const handleSearchResult = (searchVal: string) => {
    let result: any[] = [];
    if (searchVal !== undefined) {
      if (searchVal !== "") {
        dataList.map((item) => {
          return getMatchData(item, searchVal) && result.push(item);
        });
      } else {
        result = [...dataList];
      }
    }
    setSearchList(result);
  };

  //handles selected client
  const getSelectedClientList = (ID: string) => {
    let arr: Ilist[] = [];

    let result = dataList.filter((unit) => unit.id === ID)[0];

    let val = selectedEntityData?.find((item) => item.id === ID);
    arr.push(result);

    if (!val) {
      setSelectedEntityData([...arr, ...selectedEntityData]);
      setEntityIds([ID, ...entityIds]);
    }

    return arr;
  };

  const selectAllClientList = () => {
    const idList = dataList.map((item) => item.id);
    setSelectedEntityData([...dataList]);
    setEntityIds([...idList]);
  };

  const deselectAllClientList = () => {
    setSelectedEntityData([]);
    setEntityIds([]);
  };

  // handle deletetion pof selected client
  const handleDeleteSelectedClient = (item: Ilist) => {
    let result = selectedEntityData.filter((unit) => unit.id !== item.id);

    let val = entityIds.filter((id) => id !== item.id);

    setSelectedEntityData(result);
    setEntityIds(val);
  };

  //handle send Form
  const handleSendForm = () => {
    if (entityIds.length === 0) {
      setError(true);
      return;
    }

    let entityType = "";
    switch (selectedForm.formType) {
      case BusinessFormType.IntakePacketForm:
      case BusinessFormType.IntakeClientForm:
        entityType = "Client";
        break;
      case BusinessFormType.EmployeePacketForm:
      case BusinessFormType.NewEmployeeForm:
        entityType = "Provider";
        break;
      default:
        setError(true);
        throw new Error("Invalid form type");
    }

    dispatch(
      PostFormRequest({
        formId: selectedForm?.id,
        entityIds: entityIds,
        entityType: entityType,
        formType: selectedForm.formType,
      })
    );
    setOpenPopup(false);
  };

  //#endregion
  useEffect(() => {
    //fetch client list
    console.log("refetch client list for tenant...");
    if (
      selectedForm.formType === "Intake Packet Form" &&
      ClientList.length === 0
    ) {
      dispatch(GetAllClientData());
    } else if (
      selectedForm.formType === "Employee Packet Form" &&
      ProviderList.length === 0
    ) {
      dispatch(GetAllProviders());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFormId]);

  //#region useEffect region
  // //to filter client list
  useEffect(() => {
    if (selectedForm.formType === "Intake Packet Form" && ClientList.length) {
      const extractedData: Ilist[] = ClientList.map((client) => {
        // Extract relevant properties and create a new Ilist object
        const {
          id,
          childFirstName: firstName,
          childLastName: lastName,
        } = client.clientBasicDetails;
        return { id, firstName, lastName };
      });

      setDataList(extractedData);
    } else if (
      selectedForm.formType === "Employee Packet Form" &&
      ProviderList.length
    ) {
      const extractedProviderData: Ilist[] = ProviderList.map((provider) => {
        // Extract relevant properties and create a new Ilist object
        const {
          id,
          firstName: firstName,
          lastName: lastName,
        } = provider.employmentDetails;
        return { id, firstName, lastName };
      });
      setDataList(extractedProviderData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ClientList, ProviderList]);
  //#endregion

  // console.log("dataList:", dataList?.length);

  return (
    <Box>
      <Box padding="15px" style={{ overflowY: "auto" }}>
        <Box className="fromGroup--Intake">
          {selectedForm.formType === "Intake Packet Form" ? (
            <Typography className="modelBlockTitle-Intake">
              1. Select one or more clients you want to send the form
            </Typography>
          ) : (
            <Typography className="modelBlockTitle-Intake">
              1. Select one or more employees you want to send the form
            </Typography>
          )}
          <Box className="innerModelSearch-Intake">
            <Search
              style={{
                position: "absolute",
                left: "7px",
                top: "7px",
                zIndex: 1,
              }}
            />
            <TextField
              id="input-with-sx"
              label=""
              placeholder="search clients by name here..."
              variant="standard"
              onChange={(e) => {
                handleSearchResult(e.target.value);
              }}
              onFocus={() => {
                setSearchList(dataList);
              }}
            />
            {searchList?.length > 0 && (
              <>
                <Button
                  variant="contained"
                  style={{
                    position: "absolute",
                    right: "2px",
                    transform: "translateY(-50%)",
                    top: "50%",
                  }}
                  onClick={
                    selectedEntityData?.length > 0
                      ? deselectAllClientList
                      : selectAllClientList
                  }
                >
                  {selectedEntityData?.length > 0 ? "remove all" : "add all"}
                </Button>
              </>
            )}
          </Box>

          <Box className="Intake-modelSearch-data" width="100%">
            {searchList?.length > 0 && (
              <>
                {searchList?.map((item) => {
                  return (
                    <Box
                      key={item?.id}
                      display="flex"
                      alignItems={"center"}
                      justifyContent={"space-between"}
                      style={{
                        padding: "10px 15px",
                        background: "#ecf6ff",
                      }}
                      onClick={() => getSelectedClientList(item.id)}
                    >
                      {item.firstName} {item.lastName}
                      {selectedEntityData
                        ?.map((i) => i.id)
                        .includes(item?.id) ? (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Added
                          <AddIcon />
                        </span>
                      ) : (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          Add
                          <AddIcon />
                        </span>
                      )}
                    </Box>
                  );
                })}
              </>
            )}
          </Box>

          <Box m="30px 0 0" width="100%">
            {selectedForm.formType === "Intake Packet Form" ? (
              <InputLabel className="modelBlockTitle" style={{ color: "#000" }}>
                Added Clients
              </InputLabel>
            ) : (
              <InputLabel className="modelBlockTitle" style={{ color: "#000" }}>
                Added Employees
              </InputLabel>
            )}
            <Box maxHeight={"200px"} overflow="auto">
              {selectedEntityData?.length > 0 && (
                <>
                  {selectedEntityData?.map((item) => {
                    return (
                      <Box
                        p={"10px 15px"}
                        mb={"8px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                        bgcolor={"#fff"}
                        boxShadow="0px 2px 8px rgba(0, 0, 0, 0.15)"
                        borderRadius={"4px"}
                      >
                        {item.firstName} {item.lastName}
                        <Box
                          color={"#096dd9"}
                          display={"flex"}
                          alignItems={"center"}
                          onClick={() => {
                            if (entityIds.includes(item?.id)) {
                              handleDeleteSelectedClient(item);
                            }
                          }}
                        >
                          <DeleteIcon />
                        </Box>
                      </Box>
                    );
                  })}
                </>
              )}
            </Box>
            {error && entityIds?.length === 0 && (
              <FormHelperText style={{ color: " #d32f2f", paddingLeft: "5px" }}>
                Please select new client
              </FormHelperText>
            )}
          </Box>
        </Box>
      </Box>
      <Box className="assessmentModel__footer">
        <Button onClick={handleSendForm} className="send-btn">
          Send Form
        </Button>
      </Box>
    </Box>
  );
};

export default IntakeModel;
