import React, { useState } from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { getActiveAvailability } from "../../../constants/schedule";
import { MenuProps } from "../../../constants/providers";
import { EmploymentDetails } from "../../../types/provider";
import { ClientBasicDetails } from "../../../types/client";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import CalendarHeatmap, {
  ReactCalendarHeatmapValue,
} from "react-calendar-heatmap";
import { AvailabilityDetails } from "../../../types/availability";
import ReactTooltip from "react-tooltip";
import { sortDataArray } from "../../../utils/AvailabilityUtils";
import moment from "moment";

const EntityAvailabilityHeatMap: React.FC<{
  availabilityDetails: AvailabilityDetails[];
  isValidAvailability: (availability: AvailabilityDetails) => boolean;
  clientBasicDetails: ClientBasicDetails | null;
  employeBasicDetails: EmploymentDetails | null;
}> = ({
  availabilityDetails,
  isValidAvailability,
  employeBasicDetails,
  clientBasicDetails,
}) => {
  //sets current availability
  const [selectedAvailability, setSelectedAvailability] =
    useState<AvailabilityDetails | null>(
      getActiveAvailability(availabilityDetails)
    );
  //set values for valid availability
  const [availabilityError, setAvailabilityError] = useState<boolean>(false);

  const getOptimalDateRange = (
    beginDate: string | Date | null,
    endDate: string | Date | null
  ) => {
    const now = moment();
    const start = beginDate ? moment(beginDate) : now;
    const end = endDate ? moment(endDate) : now.add(6, "M");

    // If no dates provided, show current date + 4 months
    if (!beginDate || !endDate) {
      return {
        startDate: now.format("MM/DD/YYYY"),
        endDate: now.add(6, "M").format("MM/DD/YYYY"),
      };
    }

    // Calculate midpoint of availability period
    const midpoint = moment(start).add(
      moment(end).diff(start) / 2,
      "milliseconds"
    );

    if (now.isBefore(midpoint)) {
      // If current date is in first half of availability, show from start
      return {
        startDate: start.format("MM/DD/YYYY"),
        endDate: moment(start).add(4, "M").format("MM/DD/YYYY"),
      };
    } else {
      // If current date is in second half, show until end
      return {
        startDate: moment(end).subtract(4, "M").format("MM/DD/YYYY"),
        endDate: end.format("MM/DD/YYYY"),
      };
    }
  };

  return (
    <div>
      <FormControl
        size="small"
        className="planning-filter-select"
        sx={{ marginTop: "5px" }}
      >
        <InputLabel id="demo-select-small-label">Select Date Range</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={selectedAvailability?.id || ""}
          onChange={(e) => {
            const data = availabilityDetails.find(
              (item) => item.id === e.target.value
            );
            if (data) {
              // relax this logic to allow to set availability!!
              // if (!isValidAvailability(data)) {
              //   setAvailabilityError(true);
              // } else {
              //   setSelectedClientAvailability(data);
              // }
              setSelectedAvailability(data);
            }
          }}
          input={<OutlinedInput label="Select Date Range" />}
          MenuProps={MenuProps}
        >
          {sortDataArray<AvailabilityDetails>(availabilityDetails)?.map(
            (item: AvailabilityDetails, index: number) => {
              return (
                <MenuItem key={index} value={item.id}>
                  {item?.beginDate
                    ? moment(item?.beginDate).format("MM/DD/YYYY")
                    : ""}
                  {" - "}
                  {item?.endDate
                    ? moment(item?.endDate).format("MM/DD/YYYY")
                    : ""}
                </MenuItem>
              );
            }
          )}
        </Select>
      </FormControl>
      {(!selectedAvailability || availabilityError) && (
        <FormHelperText style={{ color: "#d32f2f", marginLeft: "15px" }}>
          This provider has no active availability.
        </FormHelperText>
      )}
      {clientBasicDetails && (
        <div className="userList-name m-15">
          <img
            src={clientBasicDetails?.clientProfile?.url || ClientImg}
            className="userSmallCircle"
            alt=""
          />
          <span style={{ marginLeft: 10 }}>
            {clientBasicDetails?.childFirstName +
              " " +
              clientBasicDetails?.childLastName}
          </span>
        </div>
      )}
      {employeBasicDetails && (
        <div className="userList-name m-15">
          <img
            src={employeBasicDetails?.providerProfile?.url || ProviderImg}
            className="userSmallCircle"
            alt=""
          />
          <span style={{ marginLeft: 10 }}>
            {employeBasicDetails?.firstName +
              " " +
              employeBasicDetails?.lastName}
          </span>
        </div>
      )}
      <CalendarHeatmap
        showOutOfRangeDays={true}
        {...getOptimalDateRange(
          selectedAvailability?.beginDate ?? null,
          selectedAvailability?.endDate ?? null
        )}
        values={
          selectedAvailability
            ? ([
                ...selectedAvailability?.heatMap,
              ] as ReactCalendarHeatmapValue<string>[])
            : []
        }
        showWeekdayLabels
        showMonthLabels
        weekdayLabels={["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]}
        gutterSize={5}
        tooltipDataAttrs={(value?: ReactCalendarHeatmapValue<string>) => {
          if (value?.date) {
            const formattedDate = moment(new Date(value.date)).format(
              "(ddd) YYYY-MM-DD"
            );
            const tooltipContent = value.availableHours
              ? `date: ${formattedDate}<br/>` +
                `available: ${value.availableHours}<br/>` +
                `booked: ${value.bookedHours}<br/>` +
                `remaining: ${value.remainingHours}`
              : `date: ${formattedDate}`;

            // Always return a valid TooltipDataAttrs object
            return {
              "data-tip": tooltipContent,
              "data-html": true,
            } as unknown as CalendarHeatmap.TooltipDataAttrs;
          }

          // Explicitly returning an empty TooltipDataAttrs object instead of undefined
          return {
            "data-tip": "",
            "data-html": true,
          } as unknown as CalendarHeatmap.TooltipDataAttrs;
        }}
        classForValue={(value) => {
          if (!value) {
            return "color-empty";
          }
          if (value?.pending) {
            return `color-pending color-square-${value.count}`;
          } else {
            return `color-square-${value.count}`;
          }
        }}
      />
      <ReactTooltip />
    </div>
  );
};

export default EntityAvailabilityHeatMap;
