import { useDescope } from "@descope/react-sdk";
import React, { useCallback } from "react";
import ABAEngineLogo from "../../assets/images/logos/abaengine.png";
import Alert from "../../assets/images/images-svg/alertBlue.svg";
import "./errorPage.scss";

const ErrorPage: React.FC = () => {
  const { logout } = useDescope();

  //handles logout
  const handleUserLogout = useCallback(() => {
    localStorage.removeItem("path");
    logout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logout]);
  return (
    <div className="errorPage">
      <div className="errorHeader">
        <img alt="logo" src={ABAEngineLogo} height={"50px"} />
      </div>

      <div className="errorbody">
        <div className="errorPage__container">
          <img src={Alert} alt="alertIcon" height={"60px"} />
          <p>
            It seems that you don not have permission to access Management Portal.
          </p>
          <p>
            Please log out or try different browser to login again.
          </p>

          <button className="logOut-btn" onClick={handleUserLogout}>
            Log Out
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
