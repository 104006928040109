import {
  IAssessementSendData,
  IAssessmentForm,
  IAssessmentFormClientData,
  IAssessmentFormData,
  IJsonData,
} from "../types/assessment";
import {
  IFormCard,
  ICommunicationSettings,
  IFormRequestDetail,
} from "../types/forms";
import { 
  formCard,
  formRequestDetail,
  updatedCommunicationSettings, 
} from "../constants/forms";

// export interface IAssessementClientData {
//   formId: "";
//   entityIds: [];
//   entityType: "";
// }
/*
export const assessmentsList: IAssessment = {
  id: "",
  status: "",
  formName: "",
  clientName: "",
  clientId: "",
  formId: "",
  sendedDate: "",
  completedDate: null,
  profileUrl: "",
  evaluation: {},
}; */
 
export const assessementClientData: IAssessmentFormClientData = {
  id: "",
  status: "",
  result: null,
  pdf: null,
  form: {
    id: "",
    formCategory: "",
    formSchema: {
      title: "",
      logoPosition: "",
      showProgressBar: "",
      completedHtmlOnCondition: [{}],
      pages: [
        {
          name: "",
          elements: [],
        },
      ],
      triggers: [
        {
          type: "",
          expression: "",
          gotoName: "",
        },
      ],
    },
    authTenantId: "",
    authSystem: "",
    bussinessUnit: "",
    active: true,
    createdAt: "",
    updatedAt: "",
  },
  client: {
    id: "",
    frontegg_tenantId: "",
    descope_tenantId: "",
    parentFirstName: "",
    parentLastName: "",
    childFirstName: "",
    childLastName: "",
    childDateOfBirth: null,
    childGender: "",
    childLanguages: [],
    businessUnit: "",
    clientType: "",
    onTrackStage: "",
    checklistHistory: [],
    intakeStage: "",
    concerns: "",
    hearAboutUs: "",
    files: [],
    clientProfile: {},
    stage: "",
    createdAt: "",
    updatedAt: "",
  },
  completedDate: null,
  createdAt: "",
  updatedAt: "",
};

export const assessmentPreviewForm: IAssessmentFormData = {
  id: "",
  name: "",
  url: "",
  embeddedCode: "",
  isJot: false,
  isComplete: false,
  formCategory: "",
  formSchema: {
    title: "",
    logoPosition: "",
    showProgressBar: "",
    completedHtmlOnCondition: [{}],
    pages: [
      {
        name: "",
        elements: [],
      },
    ],
    triggers: [
      {
        type: "",
        expression: "",
        gotoName: "",
      },
    ],
  },
  authTenantId: "",
  authSystem: "",
  active: true,
  createdAt: "",
  updatedAt: "",
};

export const assessmentClientDetails: IAssessementSendData = {
  formId: "",
  clientId: [],
};

export const assessmentJson: IJsonData = {
  formSchema: {
    title: "",
    logoPosition: "",
    showProgressBar: "",
    completedHtmlOnCondition: [{}],
    pages: [
      {
        name: "",
        elements: [],
      },
    ],
    triggers: [
      {
        type: "",
        expression: "",
        gotoName: "",
      },
    ],
  },
};

export const communicationSettings: ICommunicationSettings[] = [];

export const assessmentData: IAssessmentForm = {
  isLoading:false,
  AssessmentRequests: [],       //for assessment page
  AllFormRequests: [],   //for forms page

  IntakeForms: [formCard],
  ProviderForms: [formCard],
  AssessmentCards: [formCard],
  AllForms: [formCard],

  AssessmentFormData: assessmentPreviewForm,
  CommunicationSettings: communicationSettings,
  UpdatedAssessmentSetting: updatedCommunicationSettings,
  AssessmentjsonData: assessmentJson,
  hasSetting: false,
  AssessementSendData: [assessmentClientDetails],
  AssessmentFormClientData: assessementClientData,
};

export const DefaultDiagnosisTypes = [
  {
    displayText: "Depression",
    description:
      "Depression is characterized by anhedonia (lack of pleasurable pursuit), changes in eating or sleeping, and difficulty concentrating. Suicidal thoughts and behaviors can be related to depression, although not everyone who is depressed experiences these issues.",
  },
  {
    displayText: "Mania",
    description:
      "Mania constitutes a state of heightened energy, emotional lability, and unfocused activity. It may also involve getting very little sleep, uncharacteristically impulsive behaviors, and/or grandiose plans or self-perception while manic.",
  },
  {
    displayText: "Generalized Anxiety",
    description:
      "Generalized anxiety involves diffuse worries (often about inconsequential things) and an intolerance of uncertainty. This tends to have a physical impact, which is seen in the form of restlessness, tension, distraction, and impaired sleep.",
  },
  {
    displayText: "Panic",
    description:
      "Panic attacks often include elevated heart rate, profuse sweating, shaking or trembling, the perception of unreality, and the fear of imminent death (often due to heart attack). This usually results in avoidance of situations that are thought to contribute to these sensations.",
  },
  {
    displayText: "PTSD",
    description:
      "Posttraumatic stress may result when someone has difficulty coping with a traumatic event. This often results in long-term avoidance of reminders of the event, physical reactivity to potential signs of threat, and intrusive memories/nightmares related to the event.",
  },
  {
    displayText: "Phobia",
    description:
      "Phobias entail the experience of intense anxiety in very specific situations (and typically avoidance of those situations). For example, someone may be extremely afraid of small spaces, but does not tend to display similar fear or avoidance in other contexts.",
  },
  {
    displayText: "Substance Use Problems",
    description:
      "This broad term includes the use of alcohol or other drugs to such an extent that this has caused social, financial, legal, vocational, medical, or other problems. This does not presume that any/all use is problematic, but focuses on outcomes related to use.",
  },
  {
    displayText: "ADHD",
    description:
      "Inattention, hyperactivity, and/or impulsivity that make it difficult for a given patient to fulfill important responsibilities. Individual presentations may vary, and patients often display symptoms in only one domain (inattention or hyperactivity).",
  },
  {
    displayText: "OCD",
    description:
      "Unwanted, repeated thoughts (obsessions) become associated with behaviors (compulsions) intended to reduce the frequency of those thoughts. A fear of germs or contamination is extremely common, but symptoms can be extremely variable.",
  },
  {
    displayText: "Suicidality",
    description:
      "This category includes thoughts or behaviors reflective of a desire to kill oneself. Given the severity of these symptoms the threshold for screening positive is lower than other categories (therefore it is important to ask additional follow-up questions).",
  },
  {
    displayText: "Social Anxiety",
    description:
      "This involves anxiety in/avoidance of social situations, often to such a degree that the patient gives up things that would otherwise be enjoyable or important. This may be seen generally in nearly all social situations, or more narrowly (e.g., only when engaged in public speaking).",
  },
  {
    displayText: "Separation Anxiety",
    description:
      "This fear of being away from a loved one (often mom) involves irrational concerns about something bad happening while separated, tantrums/other intense efforts to remain together, and physical symptoms of fear (e.g., nausea).",
  },
  {
    displayText: "Behavior Problem",
    description:
      "This broad category includes oppositional behaviors (e.g., refusal to follow rules; arguing with adults; blaming mistakes on other people) and aggression. In general, follow-up questions with parents will likely be useful to clarify severity.",
  },
  {
    displayText: "Any Alcohol or Substance Use",
    description:
      "A positive screen in this category means that the respondent has engaged in alcohol and/or drug use at least once. This may or may not have resulted in negative consequences, but is highlighted separately due to the patient's age.",
  },
  {
    displayText: "Any Thoughts of Suicide",
    description:
      "The experience of suicidal thoughts is somewhat common among adolescents, and does not necessarily equate to a desire to harm oneself. Follow-up questions are more likely to be answered honestly if the youth's parents cannot overhear.",
  },
  {
    displayText: "Agoraphobia",
    description:
      "Agoraphobia involves deliberate avoidance of situations that could promote strong, negative emotions (frequently panic). Examples include crowds of people, large stores or theaters, or other places in which the patient believes he/she could not escape.",
  },
];
