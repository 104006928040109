import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Edit, Close } from "@mui/icons-material";
import ReactTooltip from "react-tooltip";
import CalendarHeatmap, {
  ReactCalendarHeatmapValue,
} from "react-calendar-heatmap";
import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  removeAvailability,
  setAvailabilityDetails,
  setClientId,
  setEditClient,
  setInitialState,
  updateClientAvailabilityDetailById,
} from "../../../../redux/features/client-main/client-slice";
import { setSelectedAvailabiltyValues } from "../../../../redux/features/Common/availability-slice";

import ClientAvailabilityDetailsNew from "../../client-stepper/ClientAvailabilityDetailsNew";

import {
  AvailabilityDetails,
} from "../../../../types/availability";
import { ClientForms } from "../../../../types/client";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ClientSubAvailabilityProps {
  clientData: ClientForms;
  clientId: string;
  setSelectedAvailability: React.Dispatch<
    React.SetStateAction<AvailabilityDetails>
  >;
  setIsDataEdited: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClientSubAvailability: React.FC<ClientSubAvailabilityProps> = ({
  clientData,
  clientId,
  setSelectedAvailability,
  setIsDataEdited,
}) => {
  // #region variables region

  //dispatch for redux
  const dispatch = useAppDispatch();

  //form handler for edit or new
  const { isEditClient } = useAppSelector((state) => state.client);

  //handles the edit for client availability details
  const [editAvailabilityInfo, setEditAvailabilityInfo] =
    useState<boolean>(false);

  // #endregion

  // #region methods region

  //handles the data setting in redux for client availability details
  const handleEditAvailabilityClick = () => {
    if (clientData.availabilityDetails?.length) {
      dispatch(
        setAvailabilityDetails({
          data: clientData?.availabilityDetails,
        })
      );
    }
    dispatch(setEditClient(true));
    setEditAvailabilityInfo(true);
  };

  //handles the edit form submission for client availability details
  const onSubmitAvailabilityInfo = () => {
    dispatch(setClientId({ id: clientId }));
    // dispatch(setBeginDate(new Date().toLocaleString())); //use today to reset beginDate
    dispatch(updateClientAvailabilityDetailById());
    setEditAvailabilityInfo(true);
    dispatch(setEditClient(false));
  };

  //handles the close logic when save changes or close edit form for client availability details
  const handleCloseForAvailabilityDetails = () => {
    dispatch(setInitialState({}));
    dispatch(setEditClient(false));
    setEditAvailabilityInfo(true);
  };

  //Deprecated By Hui: Incorrect logic!! We should not overwrite.
  //handles the logic to show the current date for client availability details if the beginDate is less than current date
  // const clientAvailabilityDetails = sortDataArray<AvailabilityDetails>(
  //   clientData?.availabilityDetails
  // )?.map((item: AvailabilityDetails) => {
  //   const currentDate = new Date();
  //   const compareDate = moment(item?.beginDate).isBefore(currentDate);
  //   if (compareDate) {
  //     return { ...item, beginDate: currentDate.toLocaleString() };
  //   }
  //   return item;
  // });

  // #endregion

  const availabiltyValues = useAppSelector((state) => state.availabilitySlice);
  const handleChangeAvailability = (value: string) => {
    const availability = clientData.availabilityDetails.filter(
      (item) => item.id === value
    );

    dispatch(
      setSelectedAvailabiltyValues({
        name: "availability",
        value: availability[0],
      })
    );
    setIsDataEdited(true);
    setSelectedAvailability(availability[0]);
    return availability[0];
  };
  // #region useEffect region
  useEffect(() => {
    ReactTooltip.rebuild();
  }, [editAvailabilityInfo, clientData]);

  // #endregion
  return (
    <Grid size={{ xs: 12 }}>
      <div className="detailsCard">
        <div className="detailsCard__header">
          <Typography className="detailsCard__title">Availability</Typography>
          <IconButton
            onClick={() => handleEditAvailabilityClick()}
            className="detailsCard__headBtn"
            size="small"
            edge="start"
          >
            <Edit />
          </IconButton>
        </div>
        <div className="detailsCard__body">
          <div>
            <Grid container spacing={3} alignItems="center">
              <Grid size={{ xs: 12, lg: 4, xl: 3 }}>
                <div className="fromGroup matchScheduleBox-time max-w-250px mb-6">
                  <Select
                    className="form-control"
                    value={availabiltyValues.availability?.id || ""}
                    onChange={(e) => {
                      handleChangeAvailability(e.target.value);
                    }}
                    MenuProps={MenuProps}
                  >
                    {clientData?.availabilityDetails?.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item?.beginDate
                            ? moment(item?.beginDate).format("MM/DD/YYYY")
                            : ""}
                          {" - "}
                          {item?.endDate
                            ? moment(item?.endDate).format("MM/DD/YYYY")
                            : ""}
                        </MenuItem>
                      );
                    })}
                    {/* {sortDataArray<AvailabilityDetails>(
                      clientAvailabilityDetails
                    )?.map((item: AvailabilityDetails, index: number) => {
                      return (
                        <MenuItem key={index} value={item.id}>
                          {item?.beginDate
                            ? moment(item?.beginDate).format("MM/DD/YYYY")
                            : ""}
                          {" - "}
                          {item?.endDate
                            ? moment(item?.endDate).format("MM/DD/YYYY")
                            : ""}
                        </MenuItem>
                      );
                    })} */}
                  </Select>
                </div>

                <div className="availabilityStatus">
                  <span className="unAvailableBox">Unavailable</span>
                  <span className="availableBox">Available</span>
                  <span className="partiallyBookBox">Partially Booked</span>
                  <span className="fullyBookBox">Fully Booked</span>
                </div>
              </Grid>
              <Grid size={{ xs: 12, lg: 8, xl: 9 }}>
                <CalendarHeatmap
                  showOutOfRangeDays={true}
                  startDate={moment(
                    availabiltyValues.availability?.beginDate
                  ).format("MM/DD/YYYY")}
                  endDate={moment(availabiltyValues.availability?.beginDate)
                    .add(6, "M")
                    .format("MM/DD/YYYY")}
                  values={
                    clientData?.availabilityDetails?.length
                      ? ([
                          ...availabiltyValues.availability?.heatMap,
                        ] as ReactCalendarHeatmapValue<string>[])
                      : []
                  }
                  showWeekdayLabels
                  showMonthLabels
                  weekdayLabels={[
                    "Sun",
                    "Mon",
                    "Tue",
                    "Wed",
                    "Thu",
                    "Fri",
                    "Sat",
                  ]}
                  gutterSize={5}
                  tooltipDataAttrs={(
                    value?: ReactCalendarHeatmapValue<string>
                  ) => {
                    if (value?.date) {
                      const formattedDate = moment(new Date(value.date)).format(
                        "ddd YYYY-MM-DD"
                      );
                      const tooltipContent = value.availableHours
                        ? `date: ${formattedDate}<br/>` +
                          `available: ${value.availableHours}<br/>` +
                          `booked: ${value.bookedHours}<br/>` +
                          `remaining: ${value.remainingHours}`
                        : `date: ${formattedDate}`;

                      // Always return a valid TooltipDataAttrs object
                      return {
                        "data-tip": tooltipContent,
                        "data-html": true,
                      } as unknown as CalendarHeatmap.TooltipDataAttrs;
                    }

                    // Explicitly returning an empty TooltipDataAttrs object instead of undefined
                    return {
                      "data-tip": "",
                      "data-html": true,
                    } as unknown as CalendarHeatmap.TooltipDataAttrs;
                  }}
                  classForValue={(value) => {
                    // if (!value) {
                    //   return "color-empty";
                    // }
                    // return `color-square-${value.count}`;
                    if (!value) {
                      return "color-empty";
                    }
                    if (value?.pending) {
                      return `color-pending color-square-${value.count}`;
                    } else {
                      return `color-square-${value.count}`;
                    }
                  }}
                />
                <ReactTooltip />
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {isEditClient && editAvailabilityInfo ? (
        <>
          <Dialog
            open={editAvailabilityInfo}
            className="largeModel largeModel--edit"
          >
            <Box className="largeModel__head">
              <Typography variant="h5" className="largeModel__title">
                Edit Availability
              </Typography>
              <Button
                className="largeModel__closeBtn"
                onClick={() => {
                  handleCloseForAvailabilityDetails();
                  dispatch(removeAvailability({}));
                }}
              >
                <Close />
              </Button>
            </Box>
            {/* <ClientAvailabilityDetails
              isBoardPage={false}
              onSubmit={onSubmitAvailabilityInfo}
            /> */}

            <ClientAvailabilityDetailsNew
              isBoardPage={false}
              onSubmit={onSubmitAvailabilityInfo}
            />
          </Dialog>
        </>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default ClientSubAvailability;
