import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ClientService from "../../../service/client.service";
import { Board, BoardStage } from "../../../types/board";
import { defaultAutomation } from "../../../constants/automation";

const initialState: Board = {
  id: "",
  name: "",
  description: "",
  stages: [
    {
      id: "",
      //name: "",
      goal: "1 day",
      client: [],
      provider: [],
      checkList: [],
      index: 0,
      boardColumnNames: [],
      stageName: "Default Stage",
    },
  ],
  automation: [defaultAutomation],
  boardType: "client", //auto-assign default
  boardName: "",
  index: 0,
  permission: {
    viewClient: [],
    viewProvider: [],
  },
};

const clientIntakeStage = createSlice({
  name: "clientIntakeStage",
  initialState: initialState,
  reducers: {
    updateIntakeStage: (state, action) => {
      if (action.payload) {
        if (action.payload.boardId !== state.id) return;
        state.stages = state.stages.map((item) => {
          const updatedStage = action.payload.updatedStages.find(
            (stage: BoardStage) => stage.id === item.id
          );
          return updatedStage ? updatedStage : item;
        });
      }
    },
    setIntakeStage: (state, action) => {
      if (action.payload) {
        state.stages = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllClientIntakeStage.fulfilled, (state, action) => {
      // console.log("clientIntakeStage:", action.payload);
      if (action.payload) {
        return {
          id: action.payload.id,
          name: action.payload.boardName,
          description: action.payload.boardDescription,
          stages: action.payload.stages,
          boardType: action.payload.boardType,
          boardName: action.payload.boardName,
          automation: action.payload.automation,
          index: action.payload.index,
          primitive: true,
          permission:
            action.payload.permission !== null
              ? action.payload.permission
              : { viewClient: [] },
        };
      } else {
        return { ...initialState };
      }
    });
  },
});

export const getAllClientIntakeStage = createAsyncThunk(
  "clientIntakeStage/getAllClientIntakeStage",
  async () => {
    try {
      const { data, status } = await ClientService.getAllClientIntakeStage();
      if (status) {
        return data;
      }
    } catch (e: any) {}
  }
);

export const { updateIntakeStage, setIntakeStage } = clientIntakeStage.actions;

export default clientIntakeStage;
