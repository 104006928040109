import {
  ICommunicationSettings,
  ICommunicationUpdate,
  IFormCard,
  IJsonData,
  IPreviewForm,
  IUpdatedCommunicationSettings,
  IFormRequestDetail,
} from "../types/forms";

import { IResource } from "../types/resourceHub";

export const formCard: IFormCard = {
  id: "",
  name: "",
  url: "",
  embeddedCode: null,
  isJot: true,
  isComplete: false,
  formType: "",
  formCategory: "",
  formSchema: {
    locale: "",
    title: {
      default: "",
      es: "",
    },
  },
  frontegg_tenantId: "",
  descope_tenantId: "",
  active: false,
  applicableTo: {
    insurances: [],
    authCodes: [],
  },
  checkList: [],
  resource: {} as IResource,
  installedVersion: "",
  createdAt: "",
  updatedAt: "",
  permissionSetting: {
    submission:{
      submitterCanSee: false,
    }
  }
};

export const formRequestDetail: IFormRequestDetail = {
  id: "",
  status: "",
  formName: "",
  formId: "",

  entityName: "",
  entityId: "",
  entityType: "",
  profileUrl: "",

  sentDtm: "",
  saveDtm: null,
  submitDtm: null,
  evaluateDtm: null,
  cancelDtm: null,
  mapDtm: null,
  errorDtm: "",
  errorMsg: "",

  createdBy: "",
  createdAt: "",
  updatedAt: "",

  evaluation: {},
};

export const previewForm: IPreviewForm = {
  id: "",
  name: "",
  url: "",
  embeddedCode: "",
  isJot: false,
  isComplete: false,
  formCategory: "",
  formSchema: {
    title: "",
    logoPosition: "",
    showProgressBar: "",
    completedHtmlOnCondition: [{}],
    pages: [
      {
        name: "",
        elements: [],
      },
    ],
    triggers: [
      {
        type: "",
        expression: "",
        gotoName: "",
      },
    ],
  },
  //   authTenantId: "",
  //   authSystem: "",
  formType: "",
  frontegg_tenantId: "",
  descope_tenantId: "",
  active: true,
  createdAt: "",
  updatedAt: "",
  checkList: [],
};

export const communicationSettings: ICommunicationSettings = {
  id: "",
  authTenantId: "",
  inMailTemplate: "",
  inMailActive: false,
  emailTemplate: "",
  emailActive: false,
  smsTemplate: "",
  smsActive: false,
  status: "",
  type: "",
  createdAt: "",
  updatedAt: "",
  name: "",
  days: 0,
  daysActive: false,
};

export const updatedCommunicationSettings: IUpdatedCommunicationSettings = {
  id: "",
  inMailTemplate: "",
  inMailActive: false,
  emailTemplate: "",
  emailActive: false,
  smsTemplate: "",
  smsActive: false,
  name: "",
  days: 0,
  daysActive: false,
};

export const communicationData: ICommunicationUpdate = {
  inMailTemplate: "",
  inMailActive: false,
  emailTemplate: "",
  emailActive: false,
  smsValue: "",
  smsActive: false,
  smsTemplate: "",
  boardActive: false,
};

export const FormJson: IJsonData = {
  formSchema: {
    title: "",
    logoPosition: "",
    showProgressBar: "",
    completedHtmlOnCondition: [{}],
    pages: [
      {
        name: "",
        elements: [],
      },
    ],
    triggers: [
      {
        type: "",
        expression: "",
        gotoName: "",
      },
    ],
  },
};

export enum BusinessFormGroup {
  Intake = "Intake",
  Onboarding = "Onboarding",
  Assessment = "Assessment",
  Session = "Session",
}

//must be in sync with the formType in the form schema
export enum BusinessFormType {
  AssessmentForm = "Assessment Form",
  IntakePacketForm = "Intake Packet Form",
  IntakeClientForm = "Intake Client Form",
  NewEmployeeForm = "New Employee Form",
  EmployeePacketForm = "Employee Packet Form",
  ShareableAssessmentForm = "Shareable Assessment Form",
  SessionForm = "Session Form",
}

export const DefaultSessionForm = ["Sign In Note", "Sign Out Note"];

export enum ResourceHubMessage {
  Client = "Client",
  Provider = "Provider",
  Admin = "Admin",
  Intake = "Intake",
  Onboarding = "Onboarding",
  Scheduling = "Scheduling",
}

//helper function to sort form requests
export  const SortFormRequestData = (formRequests: IFormRequestDetail[]) => {
  let data: IFormRequestDetail[] = [];
  if (formRequests.length > 0) {
    let result = [...formRequests].sort(
      (a: IFormRequestDetail, b: IFormRequestDetail) => {
        return (
          (new Date(b.updatedAt) as any) - (new Date(a.updatedAt) as any)
        );
      }
    );
    data = [...result];
  }
  return data;
};