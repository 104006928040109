import React, {
  Dispatch,
  useRef,
  SetStateAction,
  useState,
  useEffect,
} from "react";
import { useDispatch, useSelector, batch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { bindActionCreators } from "@reduxjs/toolkit";
import {
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  SelectChangeEvent,
  TableBody,
  TableContainer,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import CloseIcon from "@mui/icons-material/Close";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import GooglePlacesAutocomplete, {
  geocodeByAddress,
} from "react-google-places-autocomplete";
import usePlacesAutocomplete, { getLatLng } from "use-places-autocomplete";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import {
  CancelOptions,
  UpdateOptions,
  WeekDaysFirstletter,
  WeekDaysFullName,
  WhoCancelled,
} from "../../../constants/schedule";
import { MenuProps } from "../../../constants/providers";
import { AvailabilityTimesForSchedulesBars } from "../../../constants/schedules-filters";
import {
  bookService,
  cancelGroupBooking,
  cancelService,
  editService,
  updateGroupBooking,
} from "../../../service/schedules.service";

import {
  addOtherAddresses,
  setClientContactDetailsWithOtherAddresses,
  updateClientContactDetailById,
} from "../../../redux/features/client-main/client-slice";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import {
  GetAllClientData,
  GetClientById,
} from "../../../redux/features/client-main/client-list-slice";
import { getAllBookingsData } from "../../../redux/features/schedules-main/schedule-list-slice";
import { getDataCatalog } from "../../../redux/features/data-catalog/data-catalog-slice";
import { GetProviderById } from "../../../redux/features/providers-main/provider-list-slice";
import { NewDataCatalog } from "../../../types/businessSettings";
import { ProviderForms } from "../../../types/provider";
import { OrgBU } from "../../../types/company";
import {
  ClientAuthPayload,
  ClientForms,
  OtherAddresses,
} from "../../../types/client";
import {
  BaseClient,
  BookedSchedule,
  MatchedProvider,
  RepeatInterval,
} from "../../../types/schedule";
import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import ProviderImg from "../../../assets/images/images-svg/provider-large-vertical.svg";
import "./BookingEditModal.scss";
import { returnAuthorizationValue } from "../../../utils/ScheduleUtils";
import { getSessionToken, useUser } from "@descope/react-sdk";
import { AvailabilityDetails } from "../../../types/availability";
import { DatePicker } from "@mui/x-date-pickers";
import {
  StyledTable,
  StyledTableCell,
  StyledTableRow,
} from "src/pages/settings/business-settings/BusinessForm";
import { getAddressObject } from "src/utils/AddressUtils";
import AvailabilityHeatMap from "../heatmap/AvailabilityHeatMap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const serviceCategory = [
  { key: 0, value: "Billable" },
  { key: 1, value: "Non Billable" },
] as const;
interface BookingModalProps {
  isOpen: boolean;
  pId?: number;
  closePopup: Dispatch<SetStateAction<boolean>>;
  isNew?: boolean;
  pageTitle: string;
  dayIndex?: number;
  provider?: ProviderForms | MatchedProvider; //allow nullable
  isDelete?: boolean;
  isEdit?: boolean;
  argsdata: Record<string, any> | undefined;
  // specificDate?: IDaysOfWeek; //this matches user picked specific date
  isDataEdited?: boolean;
  setIsDataEdited?: Dispatch<SetStateAction<boolean>>;
  bookedSchedule?: BookedSchedule;
  clientData?: ClientForms | BaseClient; //allow nullable
  // Bookeddata: BookedSchedule[];
  allSelectedClients: any[];
  providersData: any[];
  fromPlanning: boolean;
  isAutoPlanned: boolean;
  autoPlanData?: any;
}

const DiscoverBookingEditModal: React.FC<BookingModalProps> = ({
  isNew,
  isEdit,
  isDelete,
  closePopup,
  pageTitle,
  argsdata,
  allSelectedClients,
  providersData,
  setIsDataEdited,
  provider,
  clientData,
  // Bookeddata,
  fromPlanning,
  isAutoPlanned,
  autoPlanData,
}) => {
  //#region variable region
  //dispatch for redux
  const dispatch: AppDispatch = useDispatch();

  //descope
  const { user } = useUser();

  //binding action creators and dispatch
  const dispatchAction = bindActionCreators(
    { GetClientById, GetProviderById, getAllBookingsData },
    dispatch
  );
  //same value between re-renders
  const dispatchActionRef = useRef(dispatchAction);

  //----------------------------------------------------------------
  //Get dropdowns from data catalog
  //store all billable data
  const BillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.BillableCatalog
  );
  //store all non billable data
  const NonBillableCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.NonBillableCatalog
  );
  // all cancellation reasons
  const ProviderReasonCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.ProviderReasonCatalog
  );

  const ClientReasonCatalog = useSelector<RootState, NewDataCatalog[]>(
    (state) => state.dataCatalogSlice.ClientReasonCatalog
  );
  //----------------------------------------------------------------

  //Checked Box handle
  const [repeatInput, setRepeatInput] = useState<boolean>(
    argsdata?.Repeat
      ? true
      : isAutoPlanned
      ? autoPlanData?.repeatService > 0
      : false
  );
  const [selectedClients, setselectedClients] = useState<
    ClientForms[] | BaseClient[]
  >([]);
  const [selectedProvider, setSelectedProvider] = useState<
    ProviderForms[] | MatchedProvider[]
  >([]);

  const [isClient, setIsClient] = useState(false);

  //store initial selected days as user picked one
  const [daysSelect, setDaysSelect] = useState<string[]>(
    argsdata
      ? (WeekDaysFullName[moment(argsdata?.StartTime).day()].split(
          " "
        ) as string[])
      : isAutoPlanned
      ? autoPlanData?.selectedDays
      : []
  );

  // store service date
  const serviceDates: string[] = argsdata
    ? [moment(argsdata?.StartTime).format("MM/DD/YYYY")]
    : isAutoPlanned
    ? autoPlanData?.selectedDate
    : [];
  //store initial client start time
  const clientStartTime: string =
    argsdata &&
    moment(argsdata?.EndTime).format("hh:mm A") !==
      moment(argsdata.StartTime).format("hh:mm A")
      ? moment(argsdata.StartTime).format("hh:mm A")
      : "07:00 AM";
  //changed start time for client
  const [startTime, setStartTime] = useState<string>(clientStartTime);

  //store initial client end time
  const clientEndTime: string = argsdata
    ? moment(argsdata.EndTime).format("hh:mm A")
    : "06:00 PM";
  //changed end time for client
  const [endTime, setEndTime] = useState<string>(clientEndTime);

  // handler addresses display or not
  const [displayOtherAddress, setDisplayOtherAddress] =
    useState<boolean>(false);

  const [userAddress, setUserAddress] = useState<string[]>([]);

  // validation handler
  const [hasError, setHasError] = useState(false);

  //handles appointment error
  const [appointmentError, setAppointmentError] = useState<boolean>(false);

  //handles reason error
  const [reasonError, setReasonError] = useState<boolean>(false);

  //store the bottom ref of modal
  const bottomRef = useRef<HTMLDivElement | null>(null);

  //handling the start time
  const setServiceFromTime = (value: string): void => {
    setStartTime(value);
  };
  // store client Addressargsdat
  const [address, setAddress] = useState<string>(
    argsdata && argsdata.Location ? argsdata.Location : ""
  );

  //description variable
  const [description, setDescription] = useState<string>(
    argsdata ? argsdata.Description : ""
  );

  // store cancellation reasion
  const [cancellationReason, setCancellationReason] = useState<string>(
    argsdata?.cancelReason ? argsdata?.cancellationReason : ""
  );

  // store who's cancelled
  const [whoCancelled, setWhoCancelled] = useState<string>(
    argsdata?.whoCancelled ? argsdata?.whoCancelled : "Client"
  );

  // store value Custom Date
  const [customEndDate, setCustomEndDate] = useState<Dayjs | null>(
    argsdata
      ? argsdata?.repeatCustomEnd
      : autoPlanData?.isEndMonth
      ? autoPlanData.customEnd
      : null //new Date(), confusing to use today!
  );

  //store custom date error
  const [customDateError, setCustomDateError] = useState<boolean>(false);
  //stored the repeat service
  const [repeatService, setRepeatService] = useState<number>(
    argsdata?.Repeat
      ? argsdata.Repeat
      : isAutoPlanned
      ? autoPlanData.repeatService
      : 0
  );

  //stored the repeat service
  const [cancelOption, setCancelOption] = useState<number>(
    argsdata?.cancelOption ? argsdata.cancelOption : 0
  );

  const [repeatInterval, setRepeatInterval] = useState<string | null>(
    argsdata?.repeatInterval || RepeatInterval.WEEKLY
  );

  //stored the repeat service
  const [updateOption, setUpdateOption] = useState<number>(
    argsdata?.updateOption ? argsdata.updateOption : 0
  );

  //service type
  const [serviceType, setServiceType] = useState<string>(
    argsdata ? argsdata.ServiceType : ""
  );

  //handling the end time
  const setServiceToTime = (value: string): void => {
    setEndTime(value);
  };

  //match values
  // const matchValues = useSelector<RootState, MatchOption>(
  //   (state) => state.matchSlice
  // );

  //store delete Dialog value
  const [permanentDelete, setPermanentDelete] = useState<boolean>(false);

  // set model value
  const [model, setModal] = useState<boolean>(false);

  //Dialog open popup
  // eslint-disable-next-line
  const [open, setOpen] = useState<boolean>(true);
  // const [clientAuths, setClientAuths] = useState({
  //   error: false,
  //   msg: "",
  //   auth: [],
  // });
  // const [selectedAuth, setSelectedAuth] = useState<any>({
  //   id: "",
  //   code: "",
  //   authCodes: [],
  // });
  const [bookedClientsDetail, setbookedClientsDetail] = useState<
    {
      clientId: string;
      authId: string;
      authCode: string;
    }[]
  >([]);
  // store lat lng school address
  const [schoolAddressObj, setSchoolAddressObj] = useState<OtherAddresses>({
    address: {
      fullAddress: "",
      lat: 0,
      lng: 0,
    },
    state: "",
    city: "",
    zipCode: "",
  });

  //store error message after booking failed
  const [bookingErrorMsg, setBookingErrorMsg] = useState<string>("");

  const [bookingProcessing, setBookingProcessing] = useState<boolean>(false);

  //store event stream message and success message
  const [sseMessage, setSseMessage] = useState<string>("");

  const [bookingStatus, setBookingStatus] = useState<string>(""); //success | error

  //business units
  const businessUnits = useSelector<RootState, OrgBU[]>(
    (state) => state.businessUnitsSlice.businessUnits
  );

  //store selected category
  const [category, setCategory] = useState<string>(
    isEdit
      ? BillableCatalog?.includes(argsdata?.ServiceType)
        ? "Billable"
        : NonBillableCatalog?.includes(argsdata?.ServiceType)
        ? "Non Billable"
        : ""
      : "Billable"
  );

  //stores billable data
  const [billableData, setBillableData] = useState<string>(
    argsdata ? argsdata.ServiceType : BillableCatalog[0]?.conceptValue
  );
  // console.log("billableData:", billableData, BillableCatalog);

  //store non billable data
  const [nonBillableData, setNonBillableData] = useState<string>(
    argsdata ? argsdata.ServiceType : NonBillableCatalog[0]?.conceptValue
  );
  //function for billable data
  const billableTest = (serviceType: string): string => {
    let result = BillableCatalog.find(
      (item: any) => item.conceptValue === serviceType
    );
    if (result !== undefined) {
      setCategory("Billable");
      return result.conceptValue;
    } else {
      return "";
    }
  };

  //function for non billable data
  const nonBillableTest = (serviceType: string): string => {
    let result = NonBillableCatalog.find(
      (item: any) => item.conceptValue === serviceType
    );
    if (result !== undefined) {
      setCategory("Non Billable");
      // eslint-disable-next-line no-lone-blocks
      {
        isEdit ? setNonBillableData(serviceType) : setNonBillableData("");
      }
      return result.conceptValue;
    } else {
      return "";
    }
  };

  //checks the custom end date is less than authorization end date
  const validateCustomEndDate = (customEndDate: Dayjs): boolean => {
    const selectedAuthIds = new Set(
      bookedClientsDetail.map((item) => item.authId)
    );

    for (const client of selectedClients) {
      for (const auth of client.authorizations) {
        if (selectedAuthIds.has(auth.id)) {
          const authEndDate = dayjs(auth.authPeriod.endDate, "MM/DD/YYYY");
          if (
            !(
              customEndDate.isBefore(authEndDate) ||
              customEndDate.isSame(authEndDate)
            )
          ) {
            setCustomDateError(true);
            return true; // Early return to improve efficiency
          }
        }
      }
    }
    return false;
  };

  //Function for Date Calender
  const handleDateChange = (newValue: Dayjs | null) => {
    if (!newValue) return;
    const invalidDate = validateCustomEndDate(newValue);
    setCustomEndDate(newValue);
    setRepeatService(3);
    if (customDateError && !invalidDate) {
      setCustomDateError(false);
    }
  };

  const handleChangeAuthorization = (
    e: SelectChangeEvent,
    client: ClientForms | BaseClient
  ) => {
    const authCode = getClientAuthCodes(client.authorizations, e.target.value);
    const updatedClientDetail = bookedClientsDetail.map((item) => {
      if (item.clientId === client.clientBasicDetails.id) {
        return {
          ...item,
          authId: e.target.value,
          authCode: authCode[0],
        };
      }
      return item;
    });
    if (customEndDate) {
      // proceed if custom date is already selected
      const selectedAuthorization = client.authorizations.find(
        (auth) => auth.id === e.target.value
      );
      if (selectedAuthorization) {
        const authEndDate = dayjs(
          selectedAuthorization.authPeriod.endDate,
          "MM/DD/YYYY"
        );
        // check if custom end date is less than authorization end date
        if (
          !(
            customEndDate.isBefore(authEndDate) ||
            customEndDate.isSame(authEndDate)
          )
        ) {
          setCustomDateError(true);
        } else {
          setCustomDateError(false);
        }
      }
    }
    setbookedClientsDetail(updatedClientDetail);
  };

  //store gmt value
  // eslint-disable-next-line
  // const [gmt, setGmt] = useState<number>(0);

  //#endregion

  //#region methods region

  //remove focus from input feilds
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  /* temporary turn off by CTO. Keep this, need to revisit! 
  //handle split time zone
  const splitTimeZone = () => {
    if (
      clientData &&
      businessUnits?.length > 0 &&
      clientData.clientBasicDetails?.businessUnit?.length > 0
    ) {
      let arr: any[] = [];
      businessUnits?.forEach((item) => {
        clientData.clientBasicDetails?.businessUnit?.forEach((res) => {
          if (item?.id === res) {
            arr.push(item);
          }
        });
      });
      setBusiness(arr);

      let val = business[0]?.businessUnitTimeZone?.label?.split(")");
      // businessUnitTimeZone.split(")");

      if (val !== undefined) {
        let s = val[0];
        let secondval = s.slice(4);
        let GMT = parseInt(secondval);
        if (GMT < 0) {
          setGmt(Math.abs(GMT));
        } else {
          GMT = GMT * -1;
          setGmt(GMT);
        }
      }
    }
  };
  */

  //use for place autocomplete
  const {
    suggestions: { data },
    value,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      //types: ["address"],
      componentRestrictions: {
        country: "us",
      },
    },
    debounce: 300,
  });

  // Helper function to format addresses without extra commas
  const formatAddress = (
    prefix: string,
    fullAddress?: string,
    city?: string,
    state?: string,
    zip?: string
  ) => {
    const parts = [fullAddress, city, state, zip].filter(Boolean); // Remove empty values
    return parts.length ? `${prefix} ${parts.join(", ")}` : ""; // Join only if there's data
  };

  const getClientAddresses = (newOptionSelect: any) => {
    const addresses: string[] = [];
    let client: any;

    // Determine the selected client
    if (isClient) {
      client = selectedClients;
    } else {
      if (newOptionSelect) {
        const result = allSelectedClients.filter(
          (item) => newOptionSelect === item.clientBasicDetails.id
        );
        client = result[0];
      }
    }

    // Populate valid addresses
    if (client?.clientContactDetails?.address?.fullAddress) {
      addresses.push(
        formatAddress(
          "Home",
          client.clientContactDetails.address.fullAddress,
          client.clientContactDetails?.city,
          client.clientContactDetails?.state,
          client.clientContactDetails?.zipCode
        )
      );
    }

    if (client?.clientContactDetails?.schoolAddress?.fullAddress) {
      addresses.push(
        formatAddress(
          "School",
          client.clientContactDetails.schoolAddress.fullAddress,
          client.clientContactDetails?.schoolCity,
          client.clientContactDetails?.schoolState,
          client.clientContactDetails?.schoolZipCode
        )
      );
    }

    client?.clientContactDetails?.otherAddresses?.forEach((item: any) => {
      if (item?.address?.fullAddress) {
        addresses.push(
          formatAddress(
            "Other",
            item.address.fullAddress,
            item?.city,
            item?.state,
            item?.zipCode
          )
        );
      }
    });

    setUserAddress(addresses);
  };

  //get client auth codes
  const getClientAuthCodes = (
    authorizations: ClientAuthPayload[],
    authId: string | undefined
  ): string[] => {
    const authCodes: string[] = [];
    authorizations?.map(
      (authorization) =>
        authorization.id === authId &&
        authorization.auth.map((auth) => authCodes.push(auth.authCode))
    );
    return authCodes;
  };

  const getClientAuthorizationPeriod = (
    authorizations: ClientAuthPayload[]
  ): ClientAuthPayload[] => {
    let foundAuths: ClientAuthPayload[] = [];
    const compareDate = serviceDates[0]
      ? new Date(serviceDates[0])
      : new Date();
    foundAuths = authorizations?.filter(
      (auth: any) =>
        auth?.authPeriod &&
        auth?.authPeriod?.startDate &&
        auth?.authPeriod?.endDate &&
        new Date(auth.authPeriod.startDate).getTime() <=
          compareDate.getTime() &&
        new Date(auth.authPeriod.endDate).getTime() >= compareDate.getTime()
    );
    return foundAuths;
  };

  const handleSelectClients = (value: string[]) => {
    const clients = allSelectedClients.filter((item: ClientForms) =>
      value.includes(item.clientBasicDetails.id)
    );
    const bookedClients: any[] = [];
    clients.forEach((item: ClientForms) => {
      const filteredAuthorizations = getClientAuthorizationPeriod(
        item.authorizations
      );
      bookedClients.push({
        clientId: item.clientBasicDetails.id,
        authId: filteredAuthorizations[0]?.id,
        authCode: filteredAuthorizations[0]?.auth[0]?.authCode,
      });
    });
    setbookedClientsDetail(bookedClients);
    setselectedClients(clients);
    if (value.length === 1) {
      getClientAddresses(value[0]);
    } else if (value.length > 1 && !isEdit) {
      setAddress("");
    }
  };

  const getClientName = (clientId: string) => {
    const client = allSelectedClients.find(
      (item: ClientForms) => item.clientBasicDetails.id === clientId
    );
    if (client) {
      return `${client.clientBasicDetails.childFirstName}  ${client.clientBasicDetails.childLastName}`;
    }
    return;
  };

  const getProviderName = (providerId: string) => {
    const provider = providersData.find(
      (item: any) => item.employmentDetails.id === providerId
    );
    if (provider) {
      return `${provider.employmentDetails.firstName}  ${provider.employmentDetails.lastName}`;
    }
    return;
  };

  // //gets index from time
  const getIndexFromTimeValidation = (val: string): number => {
    for (let i = 0; i < AvailabilityTimesForSchedulesBars.length; i++) {
      if (AvailabilityTimesForSchedulesBars[i] === val) return i;
    }
    return -1;
  };

  //Returns the drop down values for start and end time
  const getAvailabilityTimes = (index: number): JSX.Element[] => {
    const data: JSX.Element[] = [];
    for (let i = index; i < AvailabilityTimesForSchedulesBars.length; i++) {
      data.push(
        <MenuItem key={i} value={AvailabilityTimesForSchedulesBars[i]}>
          {AvailabilityTimesForSchedulesBars[i]}
        </MenuItem>
      );
    }
    return data;
  };

  //Call Google API to get GoogleGeoCodeReul
  const handleSelect = (e: any) => {
    let description = e?.label;
    if (!description) return;

    // Get latitude and longitude via utility functions
    //getGeocode({ address: description }).then(async (results: any) => {
    geocodeByAddress(description).then(
      async (results: google.maps.GeocoderResult[]) => {
        const addressObject = getAddressObject(results[0].address_components);

        //Get lat & Long Data
        const { lat, lng } = getLatLng(results[0]);

        let addressStr = description.split(",")[0];
        setSchoolAddressObj({
          address: {
            fullAddress: addressStr,
            lat: lat,
            lng: lng,
          },
          state: addressObject?.province,
          city: addressObject?.city,
          zipCode: addressObject?.postal_code,
        });
      }
    );
  };

  // school address accress
  const handleChange = (e: any) => {
    setSchoolAddressObj((prevState: any) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  //suggested school address
  const renderSuggestionsForSchool = () => {
    return data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={() => handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });
  };

  //add one more address
  const resetAddOtherAddresses = (): void => {
    dispatch(
      setClientContactDetailsWithOtherAddresses({
        ...selectedClients[0]?.clientContactDetails, //when single booking
      })
    );
    dispatch(addOtherAddresses({ ...schoolAddressObj }));
    setSchoolAddressObj({
      address: {
        fullAddress: "",
        lat: 0,
        lng: 0,
      },
      state: "",
      city: "",
      zipCode: "",
    });
  };

  //save Other address
  const saveOtherAddresses = (): void => {
    if (schoolAddressObj.address.fullAddress) {
      dispatch(addOtherAddresses({ ...schoolAddressObj }));
      const otherAddress = formatAddress(
        "Other",
        schoolAddressObj.address.fullAddress,
        schoolAddressObj.city,
        schoolAddressObj.state,
        schoolAddressObj.zipCode
      );
      setUserAddress([...userAddress, otherAddress]);
    }
    setDisplayOtherAddress(false);
    setSchoolAddressObj({
      address: {
        fullAddress: "",
        lat: 0,
        lng: 0,
      },
      state: "",
      city: "",
      zipCode: "",
    });

    dispatch(updateClientContactDetailById());
    if (fromPlanning) {
      dispatch(GetAllClientData());
    }
  };

  const isValidAvailability = (availability: AvailabilityDetails): boolean => {
    if (!availability.beginDate || !availability.endDate) return false;
    const result =
      new Date(availability.beginDate).getTime() <= new Date().getTime() &&
      new Date(availability.endDate).getTime() >= new Date().getTime();
    return result;
  };
  //#endregion

  //#region API calling

  //create group booking api
  const createGroupBooking = async (
    bookingData: any,
    fromPlanning: boolean
  ) => {
    const token = getSessionToken();
    const BASE_URL = process.env.REACT_APP_BACKEND_SERVER_BASE_URL;
    const url = new URL(`${BASE_URL}/group/book-service`);

    try {
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...bookingData,
          fromPlanning,
        }),
      });

      if (!response.body) {
        throw new Error("No response body");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let finalResponse = null;

      await new Promise<void>((resolve, reject) => {
        const stream = new ReadableStream({
          start(controller) {
            async function read() {
              while (true) {
                const { done, value } = await reader.read();
                if (done) {
                  controller.close();
                  resolve();
                  break;
                }

                const text = decoder.decode(value, { stream: true });
                const messages = text.split("\n\n");

                for (const message of messages) {
                  if (message.trim() !== "") {
                    const eventData = JSON.parse(message.substring(5).trim());

                    if (eventData.type === "message") {
                      console.log("Received update:", eventData.message);
                      setSseMessage(eventData.message);
                    } else if (eventData.type === "data") {
                      console.log(
                        "Final JSON response:",
                        JSON.parse(eventData.message)
                      );
                      finalResponse = JSON.parse(eventData.message);
                      if (!finalResponse.isError) {
                        setSseMessage(finalResponse.message);
                      }
                      setBookingStatus(
                        finalResponse.isError ? "error" : "success"
                      );
                    }
                  }
                }
                controller.enqueue(value);
              }
            }

            read().catch((error) => {
              console.error("Stream reading error:", error);
              controller.error(error);
              reject(error);
            });
          },
        });

        return stream;
      });

      return { data: finalResponse }; // Ensure the final response is returned
    } catch (error: any) {
      console.error("Error in createInGroupBooking:", error);
      throw error?.response?.data || error.message;
    }
  };

  //error message display handler

  //on submitting the form API calling
  const onSubmitBooking = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    //resetStates
    setBookingErrorMsg("");
    setSseMessage("");

    // ----------------------------------------------
    //1a: New booking must have client and provider
    if (isNew) {
      if (!category) {
        setHasError(true);
        return;
      }

      if (!serviceType) {
        setHasError(true);
        return;
      }

      if (customDateError) {
        return;
      }
      if (billableData?.length > 0 && !selectedClients && !selectedProvider) {
        dispatch(
          setResponseValue({
            name: "message",
            value: "Billable service must have client and provider.",
          })
        );
        return;
      }
      if (nonBillableData?.length > 0 && !selectedProvider) {
        dispatch(
          setResponseValue({
            name: "message",
            value: "Non billable service must have provider.",
          })
        );
        return;
      }
    }

    //1b. Edit booking must have category and service type
    if (isEdit) {
      if (!category) {
        setHasError(true);
        return;
      }
      if (!serviceType) {
        setHasError(true);
        return;
      }
    }

    //1c. Delete booking must have whoCancelled and cancellation
    if (isDelete && !permanentDelete) {
      if (whoCancelled?.length === 0) {
        setAppointmentError(true);
        return;
      }
      if (cancellationReason?.length === 0) {
        setReasonError(true);
        return;
      }
    }

    let bookingData: any;
    let result: any;
    setBookingProcessing(true); //processing indicator

    try {
      const providerId = selectedProvider.map(
        (item) => item.employmentDetails.id
      );
      //------------------------------------------------
      /* a. add new booking
         - can support both client and provider
         - can support calendar book, table book (auto-plan)
      */
      if (isNew) {
        bookingData = {
          userTodayDate: moment(new Date()).format("ddd YYYY-MM-DD"),
          serviceType: serviceType,
          serviceLocation: address,
          description: description,
          authId: bookedClientsDetail[0]?.authId,
          authCode: bookedClientsDetail[0]?.authCode,
          category: category,
          fromTime: startTime,
          toTime: endTime,
          specificDate: serviceDates[0], //must pass it as local dtm!!!
          repeatService: repeatService,
          repeatWorkDays: daysSelect,
          repeatCustomEnd: repeatService
            ? dayjs(customEndDate).format("ddd YYYY-MM-DD")
            : null,
          createdBy: user?.email,
          repeatInterval,
        };
        if (selectedClients.length > 1 || selectedProvider.length > 1) {
          //group booking with multiple clients
          bookingData = {
            ...bookingData,
            bookedClientDetails:
              category === "Billable" ? bookedClientsDetail : [],
            bookedProviderIds: providerId,
          };
          result = await createGroupBooking(bookingData, fromPlanning);
        } else {
          //1-1 booking or non billable booking
          bookingData = {
            ...bookingData,
            bookedClientId:
              category === "Billable"
                ? (selectedClients[0]?.clientBasicDetails.id as string)
                : "",
            bookedProviderId: providerId[0] as string,
          };
          result = await bookService(bookingData, fromPlanning);
        }
      }

      //------------------------------------------------
      //b. edit booking
      if (isEdit) {
        //populate booking data from UI
        bookingData = {
          updateOption: updateOption,
          specificDate: argsdata?.specificDate, //must pass it as local dtm!!!
          fromTime: startTime,
          toTime: endTime,
          serviceType: serviceType,
          serviceLocation: address,
          description: description,
          category: category,
          serviceId: argsdata?.serviceID,
          fromPlanning: fromPlanning,
          userTodayDate: moment(new Date()).format("ddd YYYY-MM-DD"),
          repeatService: repeatService,
          repeatWorkDays: daysSelect,
          repeatCustomEnd: repeatService
            ? dayjs(customEndDate).format("ddd YYYY-MM-DD")
            : null,
          repeatInterval,
        };

        if (argsdata?.groupBookingClients?.length > 0) {
          //client group booking
          bookingData = {
            ...bookingData,
            bookedClientIds: bookedClientsDetail,
            bookedProviderIds: [argsdata?.bookedProviderId?.id],
            clientGroup: argsdata?.clientGroup,
          };
          result = await updateGroupBooking(bookingData, fromPlanning);
        } else if (argsdata?.groupBookingProviders?.length) {
          //provider group booking
          bookingData = {
            ...bookingData,
            bookedProviderIds: argsdata?.groupBookingProviders.map(
              (item: any) => item.id
            ),
            providerGroup: argsdata?.providerGroup,
          };
          result = await updateGroupBooking(bookingData, fromPlanning);
        } else {
          //1-1 booking
          bookingData = {
            ...bookingData,
            bookedClientId:
              category === "Billable" ? argsdata?.bookedClientId?.id : "",
            bookedProviderId: argsdata?.bookedProviderId?.id,
          };
          result = await editService(bookingData, fromPlanning);
        }
      }
      //------------------------------------------------
      //c. delete booking
      if (isDelete) {
        // populate booking data from UI
        bookingData = {
          serviceId: argsdata?.serviceID,
          cancelOption: cancelOption,
          specificDate: argsdata?.specificDate,
          whoCancelled: whoCancelled,
          cancellationReason: cancellationReason,
          isDelete: permanentDelete ? true : false,
          fromPlanning: fromPlanning,
          billableOption: category === "Billable" ? true : false,
          category: category,
          userTodayDate: moment(new Date()).format("MM/DD/YYYY"),
        };

        if (argsdata?.groupBookingClients?.length > 0) {
          // group booking
          bookingData = {
            ...bookingData,
            bookedClientIds: argsdata?.groupBookingClients?.map(
              (client: any) => client.id
            ),
            bookedProviderIds: [argsdata?.bookedProviderId?.id],
            clientGroup: argsdata?.clientGroup,
          };
          result = await cancelGroupBooking(bookingData, fromPlanning);
        } else if (argsdata?.groupBookingProviders?.length) {
          //provider group booking
          bookingData = {
            ...bookingData,
            bookedProviderIds: argsdata?.groupBookingProviders.map(
              (item: any) => item.id
            ),
            providerGroup: argsdata?.providerGroup,
          };
          result = await cancelGroupBooking(bookingData, fromPlanning);
        } else {
          // 1-1 booking
          bookingData = {
            ...bookingData,
            bookedProviderId: argsdata?.bookedProviderId?.id,
            bookedClientId: argsdata
              ? argsdata.bookedClientId?.id
              : category === "Billable"
              ? clientData?.clientBasicDetails.id
              : "",
          };
          result = await cancelService(bookingData, fromPlanning);
        }
      }

      //------------------------------------------------
      //d. after status success: refresh data
      if (bookingData && result && result.data && result.data.status) {
        if (selectedClients.length > 1) {
          //group clients booking
          selectedClients.forEach((client) => {
            dispatchActionRef.current.GetClientById({
              clientId: client.clientBasicDetails.id,
            });
          });
        } else if (selectedProvider.length > 1) {
          selectedProvider.forEach((provider) => {
            dispatchActionRef.current.GetProviderById({
              providerId: provider.employmentDetails.id,
            });
          });
        } else {
          // single booking
          if (bookingData?.bookedClientId) {
            dispatchActionRef.current.GetClientById({
              clientId: bookingData.bookedClientId,
            });
          }
          //update booked provider
          dispatchActionRef.current.GetProviderById({
            providerId: bookingData.bookedProviderId,
          });
        }

        //refetch booking data
        setTimeout(() => {
          batch(() => {
            dispatchActionRef.current.getAllBookingsData();
            if (setIsDataEdited) setIsDataEdited(true);
          });
        }, 1000);

        //set success message
        dispatch(setResponseValue({ name: "success", value: true }));
        dispatch(
          setResponseValue({ name: "message", value: result?.data?.message })
        );
        setBookingStatus("success");
        closePopup(false);
        // setSseMessage(result?.data?.message);
      } else {
        setBookingErrorMsg(result?.data?.message);
      }
    } catch (e: any) {
      console.log("scheduling error:", e);
      setBookingStatus("error");
      setBookingErrorMsg(e?.message);
    } finally {
      if (isAutoPlanned) {
        if (setIsDataEdited) setIsDataEdited(true);
      }
    }
  };
  //#endregion

  //#region useEffect region

  //fetch and handle all selected clients
  useEffect(() => {
    // from auto planning
    if (isAutoPlanned) {
      if (!clientData) {
        return;
      }
      setIsClient(true); //client will always be true in autoPlanning
      //configure start and end time
      const [fromTime, toTime] = autoPlanData?.selectedSlot.split(" - ");
      setStartTime(fromTime);
      setEndTime(toTime);

      //get service location
      getClientAddresses(clientData.clientBasicDetails?.id);

      if ("remainingAuthHours" in clientData) {
        // clientData is BaseClient
        setselectedClients((prev) => [...(prev as BaseClient[]), clientData]);
      } else {
        // clientData is ClientForms
        setselectedClients((prev) => [...(prev as ClientForms[]), clientData]);
      }
      if (provider) setSelectedProvider([provider as MatchedProvider]);
      const filteredAuth = clientData?.authorizations?.find(
        (auth) => auth.id === autoPlanData?.authId
      );
      setbookedClientsDetail([
        {
          clientId: clientData?.clientBasicDetails?.id,
          authId: filteredAuth ? filteredAuth.id : "",
          authCode: filteredAuth ? filteredAuth.auth[0]?.authCode : "",
        },
      ]);
    } else {
      const client = allSelectedClients?.find(
        (client) =>
          (isEdit ? argsdata?.bookedClientId?.id : argsdata?.id) ===
          client?.clientBasicDetails?.id
      );
      const provider = providersData?.filter(
        (provider) =>
          (isEdit &&
            argsdata?.providerGroup &&
            argsdata?.groupBookingProviders?.some(
              (item: any) => item.id === provider.employmentDetails.id
            )) ||
          argsdata?.id === provider.employmentDetails.id
      );

      if (provider) {
        setIsClient(false);
      } else {
        setIsClient(true);
      }
      if (!argsdata?.clientGroup) {
        getClientAddresses(client?.clientBasicDetails?.id);
      }

      setselectedClients(client ? [client] : []);
      if (provider) {
        setSelectedProvider(provider);
      }

      const authorizations = getClientAuthorizationPeriod(
        client?.authorizations
      );
      if (client) {
        setbookedClientsDetail([
          {
            clientId: client?.clientBasicDetails?.id,
            authId: isEdit
              ? argsdata?.authId
              : authorizations.length
              ? authorizations[0]?.id
              : "",
            authCode: isEdit
              ? argsdata?.authCode
              : authorizations.length
              ? authorizations[0]?.auth[0]?.authCode
              : "",
          },
        ]);
      }
      setAddress(argsdata?.Location);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argsdata, isAutoPlanned, isEdit]);

  //fetch and handle all selected clients update group booking
  useEffect(() => {
    if (
      allSelectedClients &&
      argsdata &&
      argsdata.groupBookingClients?.length > 0
    ) {
      let clientsInGroupBooking = allSelectedClients.filter(
        (item: ClientForms) =>
          argsdata.groupBookingClients.some(
            (client: { id: string }) =>
              client.id === item?.clientBasicDetails?.id
          )
      );
      const bookedClients: any[] = [];
      argsdata.groupBookingClients.forEach((item: any) => {
        bookedClients.push({
          clientId: item.id,
          authId: item.bookedAuthId,
          authCode: item.bookedAuthCode,
        });
      });
      setselectedClients(clientsInGroupBooking);
      setbookedClientsDetail(bookedClients);
      setAddress(argsdata?.Location);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [argsdata?.groupBookingClients, allSelectedClients]);

  /* temporary turn off by CTO. Keep this, need to revisit! 
  //handle timezone
  useEffect(() => {
    splitTimeZone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessUnits, clientData?.clientBasicDetails?.id]);
  */

  //returns authorization value
  useEffect(() => {
    if (argsdata !== undefined) {
      billableTest(argsdata.ServiceType);
      nonBillableTest(argsdata.ServiceType);
      setCustomEndDate(argsdata.repeatCustomEnd);
      setDescription(argsdata.Description);
    }
    // eslint-disable-next-line
  }, [argsdata]);

  useEffect(() => {
    dispatch(getDataCatalog());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (bookingProcessing) {
      if (bottomRef.current) {
        //set the scroll to bottom
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [bookingProcessing]);
  //#endregion

  return (
    <>
      <Dialog
        open={open}
        className={
          isDelete || isEdit || isNew
            ? "largeModel schedule-detail-model deleteSchedule"
            : "largeModel schedule-detail-model"
        }
      >
        <Box className="largeModel__head">
          <Typography variant="h5" className="largeModel__title">
            {pageTitle}: {argsdata?.Subject}
          </Typography>
          <Button
            className="largeModel__closeBtn"
            onClick={() => {
              closePopup(false);
              setOpen(false);
            }}
          >
            <CloseIcon />
          </Button>
        </Box>

        <DialogContent>
          <Box className="largeModel__body">
            <div className="bookingModel">
              <div className="bookingModel__form">
                <Grid container spacing={4}>
                  {/* New Booking View */}
                  {isNew && (
                    <>
                      <Grid size={{ xs: 12, md: 6 }}>
                        {isClient ? (
                          <div className="userList-name">
                            <img
                              src={
                                selectedClients[0]?.clientBasicDetails
                                  ?.clientProfile?.url || ClientImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                            <span style={{ marginLeft: 10 }}>
                              {selectedClients[0]?.clientBasicDetails
                                .childFirstName +
                                " " +
                                selectedClients[0]?.clientBasicDetails
                                  .childLastName}
                            </span>
                          </div>
                        ) : (
                          <div>
                            {allSelectedClients.length > 0 ? (
                              <FormControl sx={{ width: "100%" }} size="small">
                                <InputLabel id="demo-select-small-label">
                                  Select Clients
                                </InputLabel>
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  multiple
                                  value={bookedClientsDetail.map(
                                    (item) => item.clientId
                                  )}
                                  onChange={(e) => {
                                    handleSelectClients(
                                      e.target.value as string[]
                                    );
                                  }}
                                  input={
                                    <OutlinedInput label="select clients" />
                                  }
                                  renderValue={(selected: any) => {
                                    selected = selected.map((select: any) => {
                                      return getClientName(select);
                                    });
                                    return selected.join(", ");
                                  }}
                                  MenuProps={MenuProps}
                                >
                                  {allSelectedClients.map((client) => (
                                    <MenuItem
                                      key={client.clientBasicDetails.id}
                                      value={client.clientBasicDetails.id}
                                    >
                                      <Checkbox
                                        checked={bookedClientsDetail
                                          .map((item) => item.clientId)
                                          .includes(
                                            client.clientBasicDetails.id
                                          )}
                                      />
                                      <ListItemText
                                        primary={`${client.clientBasicDetails.childFirstName} ${client.clientBasicDetails.childLastName}`}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            ) : (
                              <FormControl sx={{ width: "100%" }} disabled>
                                <Select
                                  sx={{ height: "40px" }}
                                  id="demo-simple-select"
                                  label="selectClient"
                                ></Select>
                                <FormHelperText>
                                  Please Select the Client
                                </FormHelperText>
                              </FormControl>
                            )}
                          </div>
                        )}
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        {!isClient && isAutoPlanned ? (
                          selectedProvider[0]?.employmentDetails && (
                            <div className="userList-name">
                              <img
                                src={ProviderImg}
                                className="userSmallCircle"
                                alt=""
                              />
                              <span style={{ marginLeft: 10 }}>
                                {
                                  selectedProvider[0]?.employmentDetails
                                    .firstName
                                }{" "}
                                {
                                  selectedProvider[0]?.employmentDetails
                                    .lastName
                                }
                              </span>
                            </div>
                          )
                        ) : (
                          <div>
                            {providersData.length > 0 ? (
                              <FormControl sx={{ width: "100%" }} size="small">
                                <InputLabel id="demo-select-small-label">
                                  Select Provider
                                </InputLabel>
                                <Select
                                  labelId="demo-select-small-label"
                                  id="demo-select-small"
                                  multiple={category === "Non Billable"}
                                  value={selectedProvider.map(
                                    (item) => item.employmentDetails.id
                                  )}
                                  input={
                                    <OutlinedInput label="Select Provider" />
                                  }
                                  MenuProps={MenuProps}
                                  onChange={(e) => {
                                    console.log(e.target.value, "targetValue");
                                    const selectedIds = Array.isArray(
                                      e.target.value
                                    )
                                      ? e.target.value
                                      : [e.target.value];

                                    const selectedProviders =
                                      providersData.filter((item) =>
                                        selectedIds.includes(
                                          item.employmentDetails.id
                                        )
                                      );

                                    setSelectedProvider(selectedProviders);
                                    setHasError(false);
                                  }}
                                  renderValue={(selected: any) => {
                                    selected = selected.map((select: any) => {
                                      return getProviderName(select);
                                    });
                                    return selected.join(", ");
                                  }}
                                >
                                  {providersData.map((provider) => (
                                    <MenuItem
                                      key={provider.employmentDetails.id}
                                      value={provider.employmentDetails.id}
                                    >
                                      {category === "Non Billable" && (
                                        <Checkbox
                                          checked={selectedProvider
                                            .map(
                                              (item) =>
                                                item.employmentDetails.id
                                            )
                                            .includes(
                                              provider.employmentDetails.id
                                            )}
                                        />
                                      )}

                                      <ListItemText
                                        primary={`${provider.employmentDetails.firstName} ${provider.employmentDetails.lastName}`}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                                {!selectedProvider.length && hasError && (
                                  <FormHelperText style={{ color: "#d32f2f" }}>
                                    Please select provider
                                  </FormHelperText>
                                )}
                              </FormControl>
                            ) : (
                              <FormControl sx={{ width: "100%" }} disabled>
                                <Select
                                  sx={{ height: "40px" }}
                                  id="demo-simple-select"
                                  label="selectProvider"
                                ></Select>
                                <FormHelperText>
                                  Please Select the Provider
                                </FormHelperText>
                              </FormControl>
                            )}
                          </div>
                        )}
                      </Grid>
                    </>
                  )}

                  {/* Edit Booking View */}
                  {isEdit && (
                    <>
                      <Grid size={{ xs: 12, md: 6 }}>
                        {!argsdata?.groupBookingClients?.length ? (
                          <div className="userList-name">
                            {category === "Billable" && (
                              <img
                                src={
                                  clientData?.clientBasicDetails?.clientProfile
                                    ?.url || ClientImg
                                }
                                className="userSmallCircle"
                                alt=""
                              />
                            )}
                            <span style={{ marginLeft: 10 }}>
                              {argsdata?.bookedClientId?.childFirstName}{" "}
                              {argsdata?.bookedClientId?.childLastName}
                            </span>
                          </div>
                        ) : (
                          <div>
                            {allSelectedClients.length > 0 && (
                              <FormControl sx={{ width: 300 }}>
                                <InputLabel id="demo-multiple-checkbox-label">
                                  Select Clients
                                </InputLabel>
                                <Select
                                  sx={{ height: "40px" }}
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={bookedClientsDetail.map(
                                    (item) => item.clientId
                                  )}
                                  onChange={(e) => {
                                    handleSelectClients(
                                      e.target.value as string[]
                                    );
                                  }}
                                  input={
                                    <OutlinedInput label="select clients" />
                                  }
                                  renderValue={(selected: any) => {
                                    selected = selected.map((select: any) => {
                                      return getClientName(select);
                                    });
                                    return selected.join(", ");
                                  }}
                                  MenuProps={MenuProps}
                                >
                                  {allSelectedClients.map((client: any) => (
                                    <MenuItem
                                      key={client.clientBasicDetails.id}
                                      value={client.clientBasicDetails.id}
                                    >
                                      <Checkbox
                                        checked={bookedClientsDetail
                                          .map((item) => item.clientId)
                                          .includes(
                                            client.clientBasicDetails.id
                                          )}
                                      />
                                      <ListItemText
                                        primary={`${client.clientBasicDetails.childFirstName} ${client.clientBasicDetails.childLastName}`}
                                      />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            )}
                          </div>
                        )}
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        {!argsdata?.groupBookingProviders?.length ? (
                          <div className="userList-name">
                            <img
                              src={
                                provider?.employmentDetails?.providerProfile
                                  ?.url || ProviderImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                            {isNew ? (
                              <span style={{ marginLeft: 10 }}>
                                {argsdata?.bookedProviderId?.childFirstName}{" "}
                                {argsdata?.bookedProviderId?.childLastName}
                              </span>
                            ) : (
                              <span style={{ marginLeft: 10 }}>
                                {argsdata
                                  ? argsdata?.bookedProviderId?.firstName
                                  : ""}{" "}
                                {argsdata
                                  ? argsdata?.bookedProviderId?.lastName
                                  : ""}
                              </span>
                            )}
                          </div>
                        ) : (
                          <FormControl sx={{ width: "100%" }} size="small">
                            <InputLabel id="demo-select-small-label">
                              Select Provider
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              multiple={category === "Non Billable"}
                              value={selectedProvider.map(
                                (item) => item.employmentDetails.id
                              )}
                              input={<OutlinedInput label="Select Provider" />}
                              MenuProps={MenuProps}
                              renderValue={(selected: any) => {
                                selected = selected.map((select: any) => {
                                  return getProviderName(select);
                                });
                                return selected.join(", ");
                              }}
                            >
                              {providersData.map((provider) => (
                                <MenuItem
                                  key={provider.employmentDetails.id}
                                  value={provider.employmentDetails.id}
                                >
                                  <ListItemText
                                    primary={`${provider.employmentDetails.firstName} ${provider.employmentDetails.lastName}`}
                                  />
                                </MenuItem>
                              ))}
                            </Select>
                            {!selectedProvider.length && hasError && (
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                Please select provider
                              </FormHelperText>
                            )}
                          </FormControl>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid size={{ xs: 12 }} className={"fromGroup"}>
                    <Grid container spacing={3}>
                      {
                        //Delete Booking View
                        isDelete && (
                          <>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <InputLabel className="fromLabel">
                                Service Type
                              </InputLabel>
                              <p className="detailsCard-part__text">
                                {serviceType}
                              </p>
                            </Grid>
                            <Grid size={{ xs: 12, md: 6 }}>
                              <InputLabel className="fromLabel">
                                Service Time
                              </InputLabel>
                              <p className="detailsCard-part__text">
                                {startTime} - {endTime}
                              </p>
                            </Grid>
                            <Grid size={{ xs: 12 }}>
                              <InputLabel className="fromLabel">
                                Service Location
                              </InputLabel>
                              <p className="detailsCard-part__text">
                                {address}
                              </p>
                            </Grid>
                          </>
                        )
                      }
                      {(isNew || isEdit) && (
                        <>
                          <TableContainer
                            component={Paper}
                            className="tableResponsive"
                          >
                            <StyledTable
                              sx={{
                                borderCollapse: "separate",
                                cursor: "pointer",
                              }}
                              aria-label="customized table"
                            >
                              <TableBody className="tableBody">
                                {selectedClients.map((item) => {
                                  let currentClient = bookedClientsDetail.find(
                                    (client) =>
                                      client.clientId ===
                                      item.clientBasicDetails.id
                                  );

                                  const clientAuths = isAutoPlanned
                                    ? item.authorizations.filter(
                                        (auth) =>
                                          auth.id === autoPlanData?.authId
                                      )
                                    : item.authorizations;
                                  // : getClientAuthorizationPeriod(
                                  //     item.authorizations
                                  //   );
                                  // console.log(
                                  //   currentClient,
                                  //   clientAuths,
                                  //   "currentClient"
                                  // );
                                  return (
                                    <StyledTableRow
                                      key={item.clientBasicDetails.id}
                                    >
                                      <StyledTableCell
                                        style={{ width: "200px" }}
                                      >
                                        {
                                          item?.clientBasicDetails
                                            .childFirstName
                                        }{" "}
                                        {item?.clientBasicDetails.childLastName}
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <Select
                                          // className="form-control"
                                          labelId="demo-simple-select-label"
                                          value={
                                            currentClient?.authId !== undefined
                                              ? currentClient.authId
                                              : ""
                                          }
                                          name="Authorization"
                                          onChange={(e) =>
                                            handleChangeAuthorization(e, item)
                                          }
                                          MenuProps={MenuProps}
                                          style={{
                                            width: "300px",
                                          }}
                                        >
                                          {clientAuths.length > 0 &&
                                            clientAuths?.map(
                                              (
                                                item: ClientAuthPayload,
                                                index: number
                                              ) => {
                                                return (
                                                  item.authPeriod &&
                                                  item.auth.length > 0 && (
                                                    <MenuItem
                                                      key={index}
                                                      value={item.id}
                                                    >
                                                      {returnAuthorizationValue(
                                                        item
                                                      )}
                                                    </MenuItem>
                                                  )
                                                );
                                              }
                                            )}
                                        </Select>
                                      </StyledTableCell>
                                      <StyledTableCell>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          defaultValue=""
                                          value={
                                            currentClient?.authCode !==
                                            undefined
                                              ? currentClient.authCode
                                              : ""
                                          }
                                          onChange={(e) => {
                                            const updatedClientDetail =
                                              bookedClientsDetail.map(
                                                (client) => {
                                                  if (
                                                    client.clientId ===
                                                    item.clientBasicDetails.id
                                                  ) {
                                                    return {
                                                      ...client,
                                                      authCode: e.target.value,
                                                    };
                                                  }
                                                  return client;
                                                }
                                              );
                                            setbookedClientsDetail(
                                              updatedClientDetail
                                            );
                                          }}
                                          name="authCode"
                                          MenuProps={MenuProps}
                                          style={{
                                            width: "150px",
                                          }}
                                        >
                                          {item.authorizations.length > 0 &&
                                            getClientAuthCodes(
                                              item.authorizations,
                                              currentClient?.authId
                                            ).map((auth) => (
                                              <MenuItem key={auth} value={auth}>
                                                {auth}
                                              </MenuItem>
                                            ))}
                                        </Select>
                                      </StyledTableCell>
                                    </StyledTableRow>
                                  );
                                })}
                              </TableBody>
                            </StyledTable>
                          </TableContainer>

                          <Grid size={{ xs: 12 }}>
                            <InputLabel className="fromLabel">
                              Service Type
                            </InputLabel>
                            <Box
                              className="fromGroup-chips flexNowrap"
                              display={"flex"}
                              flexWrap={"nowrap"}
                              style={{ gap: "10px" }}
                              mb={"20px"}
                            >
                              <>
                                {serviceCategory.map((item) => (
                                  <Card
                                    className={
                                      category === item.value
                                        ? "fromGroup-chip-active"
                                        : "fromGroup-chip"
                                    }
                                    style={{ whiteSpace: "nowrap" }}
                                    onClick={() => {
                                      setCategory(item.value);
                                      if (item.value === "Billable") {
                                        setNonBillableData("");
                                        setBillableData("");
                                        if (selectedProvider.length > 1) {
                                          setSelectedProvider([]);
                                        }
                                      } else if (
                                        item.value === "Non Billable"
                                      ) {
                                        setBillableData("");
                                        setNonBillableData("");
                                      }
                                      setHasError(false);
                                    }}
                                    key={item.key}
                                  >
                                    {item.value}
                                  </Card>
                                ))}
                              </>
                              <Box width={"100%"}>
                                {category === "Billable" && (
                                  <Box className="fromGroup">
                                    <Select
                                      sx={{
                                        minWidth: 300,
                                        width: "100%",
                                        marginTop: "5px",
                                      }}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label=""
                                      MenuProps={MenuProps}
                                      value={serviceType || ""}
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        setServiceType(e.target.value);
                                        // setBillableData(e.target.value);
                                        setHasError(false);
                                      }}
                                    >
                                      {BillableCatalog?.map((item: any) => {
                                        return (
                                          <MenuItem
                                            value={item.conceptValue}
                                            key={item.id}
                                          >
                                            {item.conceptValue}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    {!serviceType && hasError && (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please select service value
                                      </FormHelperText>
                                    )}
                                  </Box>
                                )}
                                {category === "Non Billable" && (
                                  <Box className="fromGroup">
                                    <Select
                                      defaultValue={
                                        argsdata && argsdata.ServiceType
                                      }
                                      sx={{
                                        minWidth: 460,
                                        marginTop: "5px",
                                      }}
                                      labelId="demo-simple-select-label"
                                      id="demo-simple-select"
                                      label=""
                                      MenuProps={MenuProps}
                                      value={serviceType || ""}
                                      onChange={(
                                        e: SelectChangeEvent<string>
                                      ) => {
                                        setServiceType(e.target.value);
                                        setHasError(false);
                                      }}
                                    >
                                      {NonBillableCatalog?.map((item: any) => {
                                        return (
                                          <MenuItem
                                            value={item.conceptValue}
                                            key={item.id}
                                          >
                                            {item.conceptValue}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                    {!serviceType && hasError && (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please select service value
                                      </FormHelperText>
                                    )}
                                  </Box>
                                )}
                              </Box>
                            </Box>
                          </Grid>
                          <Grid size={{ xs: 12 }}>
                            <InputLabel className="fromLabel">
                              Service Location
                            </InputLabel>
                            <div className="fromGroup-chips">
                              <Select
                                defaultValue={argsdata && argsdata?.Location}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                MenuProps={MenuProps}
                                value={address || ""}
                                onChange={(e: SelectChangeEvent<string>) => {
                                  setAddress(e.target.value);
                                }}
                              >
                                {selectedClients.length === 1 ? (
                                  userAddress.map(
                                    (item: string, index: number) => {
                                      return (
                                        <MenuItem value={item} key={index}>
                                          {item}
                                        </MenuItem>
                                      );
                                    }
                                  )
                                ) : (
                                  <MenuItem value={address}>{address}</MenuItem>
                                )}
                                {businessUnits.map((item, index: number) => {
                                  return (
                                    <MenuItem
                                      value={item.businessUnitAddress}
                                      key={index}
                                    >
                                      {`Clinic ${item.businessUnitAddress}`}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </div>
                          </Grid>

                          {!displayOtherAddress &&
                            selectedClients.length === 1 && (
                              <Grid
                                size={{ xs: 12 }}
                                sx={{ position: "relative" }}
                              >
                                <Button
                                  onClick={() => {
                                    setDisplayOtherAddress(true);
                                    dispatch(
                                      setClientContactDetailsWithOtherAddresses(
                                        {
                                          ...selectedClients[0]
                                            ?.clientContactDetails,
                                        }
                                      )
                                    );
                                  }}
                                  className="addAddressBtn"
                                >
                                  + Add Another Address
                                </Button>
                              </Grid>
                            )}
                          {displayOtherAddress && (
                            <Grid size={{ xs: 12 }}>
                              <ValidatorForm onSubmit={() => {}}>
                                <Grid size={{ xs: 12 }}>
                                  <Box className="fromGroup">
                                    <InputLabel className="fromLabel">
                                      Address
                                    </InputLabel>
                                    <GooglePlacesAutocomplete
                                      apiKey={
                                        process.env
                                          .React_APP_GOOGLE_AUTOCOMPLETE_KEY
                                      }
                                      selectProps={{
                                        defaultInputValue:
                                          schoolAddressObj.address.fullAddress,
                                        setValue:
                                          schoolAddressObj.address.fullAddress,
                                        onChange: handleSelect,
                                        debounce: 300,
                                        styles: {
                                          input: (provided: any) => ({
                                            ...provided,
                                            fontSize: 16,
                                          }),
                                          option: (provided: any) => ({
                                            ...provided,
                                            fontSize: 16,
                                          }),
                                          singleValue: (provided: any) => ({
                                            ...provided,
                                            fontSize: 16,
                                          }),
                                        },
                                      }}
                                      autocompletionRequest={{
                                        componentRestrictions: {
                                          country: ["us"],
                                        },
                                      }}
                                      onLoadFailed={(error: any) =>
                                        console.error(
                                          "Could not inject Google script",
                                          error
                                        )
                                      }
                                    />
                                    {schoolAddressObj?.address?.fullAddress
                                      .length > 0 && (
                                      <ul className="addressSuggestionList">
                                        {renderSuggestionsForSchool()}
                                      </ul>
                                    )}

                                    {hasError &&
                                    value === "" &&
                                    !schoolAddressObj.address.fullAddress ? (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please select address
                                      </FormHelperText>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Grid>
                                <Grid container spacing={3}>
                                  <Grid size={{ xs: 6 }}>
                                    <Box className="fromGroup">
                                      <InputLabel className="fromLabel">
                                        City
                                      </InputLabel>
                                      <TextValidator
                                        className="form-control"
                                        onChange={handleChange}
                                        name="city"
                                        id="city"
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("schoolCity");
                                          }
                                        }}
                                        value={schoolAddressObj?.city}
                                        validators={["required"]}
                                        errorMessages={["Please enter city"]}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid size={{ xs: 3 }}>
                                    <Box className="fromGroup">
                                      <InputLabel className="fromLabel">
                                        State
                                      </InputLabel>
                                      <TextValidator
                                        className="form-control"
                                        onChange={handleChange}
                                        name="state"
                                        id="state"
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("schoolState");
                                          }
                                        }}
                                        value={schoolAddressObj?.state}
                                        validators={["required"]}
                                        errorMessages={["Please enter state"]}
                                      />
                                    </Box>
                                  </Grid>
                                  <Grid size={{ xs: 3 }}>
                                    <Box className="fromGroup">
                                      <InputLabel className="fromLabel">
                                        ZipCode
                                      </InputLabel>
                                      <TextValidator
                                        className="form-control"
                                        onChange={handleChange}
                                        name="zipCode"
                                        id={"zipCode"}
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            e.preventDefault();
                                            removeFocus("zipCode");
                                          }
                                        }}
                                        value={schoolAddressObj?.zipCode}
                                        validators={[
                                          "required",
                                          // "matchRegexp:^[0-9]{5}(?:-[0-9]{4})?$",
                                        ]}
                                        errorMessages={[
                                          "Please enter zip code",
                                          // "zip-code  is not valid",
                                        ]}
                                      />
                                    </Box>
                                  </Grid>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Button
                                    onClick={() => resetAddOtherAddresses()}
                                  >
                                    Reset
                                  </Button>
                                  <Button onClick={() => saveOtherAddresses()}>
                                    Save
                                  </Button>
                                </Grid>
                              </ValidatorForm>
                            </Grid>
                          )}
                          <Grid size={{ xs: 12 }}>
                            <Box className="fromGroup">
                              <Grid container alignItems="center">
                                <Grid size={{ xs: 12, lg: 6 }}>
                                  <InputLabel className="fromLabel">
                                    Service Time
                                  </InputLabel>
                                </Grid>
                              </Grid>

                              <div className="time-group">
                                <Box className="time-group-select">
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200,
                                          width: 100,
                                        },
                                      },
                                    }}
                                    value={startTime}
                                    onChange={(e: any) => {
                                      setServiceFromTime(e.target.value);
                                      const toTimeIndex =
                                        getIndexFromTimeValidation(
                                          e.target.value
                                        ) + 4;
                                      if (
                                        toTimeIndex <
                                        AvailabilityTimesForSchedulesBars.length
                                      ) {
                                        setServiceToTime(
                                          AvailabilityTimesForSchedulesBars[
                                            toTimeIndex
                                          ]
                                        );
                                      } else {
                                        setServiceToTime(
                                          AvailabilityTimesForSchedulesBars[
                                            AvailabilityTimesForSchedulesBars.length -
                                              1
                                          ]
                                        );
                                      }
                                    }}
                                  >
                                    {getAvailabilityTimes(0)}
                                  </Select>
                                </Box>
                                <span className="time-group__space">-</span>
                                <Box className="time-group-select">
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: 200,
                                          width: 100,
                                        },
                                      },
                                    }}
                                    value={endTime}
                                    onChange={(e: any) =>
                                      setServiceToTime(e.target.value)
                                    }
                                  >
                                    {getAvailabilityTimes(
                                      getIndexFromTimeValidation(startTime) + 1
                                    )}
                                  </Select>
                                </Box>
                              </div>
                            </Box>
                          </Grid>
                        </>
                      )}
                      <Grid container size={{ xs: 12 }}>
                        <Grid size={{ xs: 6 }}>
                          <InputLabel className="fromLabel">
                            Service Date
                          </InputLabel>
                          <p className="detailsCard-part__text">
                            {serviceDates?.join(", ")}
                          </p>
                        </Grid>

                        {isNew && (
                          <Grid size={{ xs: 6 }}>
                            <InputLabel className="fromLabel">
                              Ends On
                            </InputLabel>
                            <div className="check-AuthBlock">
                              <div style={{ display: "flex" }}>
                                <input
                                  disabled={category === "Non Billable"}
                                  type="checkbox"
                                  checked={repeatService === 2}
                                  onChange={() =>
                                    repeatService === 2
                                      ? setRepeatService(0)
                                      : setRepeatService(2)
                                  }
                                  style={{ width: "16px", height: "16px" }}
                                />
                                End of Authorization
                              </div>
                            </div>
                            <div className="check-AuthBlock">
                              <div>
                                <input
                                  type="checkbox"
                                  checked={
                                    repeatService === 3 || repeatService === 1
                                  } //handle legacy repeatService=EndOfMonth
                                  onChange={() =>
                                    repeatService === 3 || repeatService === 1
                                      ? setRepeatService(0)
                                      : setRepeatService(3)
                                  }
                                  style={{ width: "16px", height: "16px" }}
                                />
                                Custom Date
                              </div>
                              <div className="datepickerBlock">
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DatePicker
                                    label="Controlled picker"
                                    value={customEndDate}
                                    onChange={handleDateChange}
                                  />
                                </LocalizationProvider>
                              </div>
                            </div>
                            {customDateError && (
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                End date must not exceed authorization end date.
                              </FormHelperText>
                            )}
                          </Grid>
                        )}
                      </Grid>

                      <Grid size={{ xs: 12 }}>
                        {isNew && (
                          <Grid size={{ xs: 12 }}>
                            <Box className="checkBoxEdit">
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    checked={repeatInput}
                                    value={repeatInput}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setRepeatInput(e.target.checked);
                                    }}
                                  />
                                }
                                label="Repeat On"
                              />
                              {repeatInput && (
                                <FormControl
                                  sx={{ m: 1, minWidth: 120 }}
                                  size="small"
                                >
                                  <InputLabel id="demo-select-small-label">
                                    Repeat Interval
                                  </InputLabel>
                                  <Select
                                    labelId="demo-select-small-label"
                                    id="demo-select-small"
                                    value={repeatInterval}
                                    label=" Repeat Interval"
                                    onChange={(e) => {
                                      setRepeatInterval(e.target.value);
                                    }}
                                  >
                                    {Object.values(RepeatInterval).map(
                                      (item: string) => (
                                        <MenuItem key={item} value={item}>
                                          {item}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              )}
                            </Box>
                            <div className="fromGroup-repeatDay">
                              {WeekDaysFirstletter.map(
                                (item: string, index: number) => {
                                  return (
                                    <div
                                      className={
                                        daysSelect?.indexOf(
                                          WeekDaysFullName[index]
                                        ) > -1
                                          ? "fromGroup-dayName"
                                          : ""
                                      }
                                      onClick={() => {
                                        let oldSelectedDays: string[] = [
                                          ...daysSelect,
                                        ];
                                        if (
                                          oldSelectedDays?.indexOf(
                                            WeekDaysFullName[index]
                                          ) > -1
                                        ) {
                                          oldSelectedDays =
                                            oldSelectedDays.filter(
                                              (item) =>
                                                item !== WeekDaysFullName[index]
                                            );
                                        } else {
                                          oldSelectedDays.push(
                                            WeekDaysFullName[index]
                                          );
                                        }
                                        setDaysSelect(oldSelectedDays);
                                      }}
                                      key={index}
                                    >
                                      {item}
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </Grid>
                        )}

                        {isEdit && (
                          <Grid size={{ xs: 12 }}>
                            <InputLabel className="fromLabel">
                              What would you like to edit?
                            </InputLabel>
                            <div className="fromGroup-chips">
                              {UpdateOptions.map(
                                (item: string, index: number) => (
                                  <Card
                                    className={
                                      index === updateOption
                                        ? "fromGroup-chip-active"
                                        : ""
                                    }
                                    onClick={() => setUpdateOption(index)}
                                    key={item}
                                  >
                                    {item}
                                  </Card>
                                )
                              )}
                            </div>
                          </Grid>
                        )}

                        {isDelete && (
                          <Grid size={{ xs: 12 }}>
                            <InputLabel className="fromLabel">
                              What would you like to cancel?
                            </InputLabel>
                            <div className="fromGroup-chips">
                              {CancelOptions.map(
                                (item: string, index: number) => (
                                  <Card
                                    className={
                                      index === cancelOption
                                        ? "fromGroup-chip-active"
                                        : ""
                                    }
                                    onClick={() => setCancelOption(index)}
                                    key={item}
                                  >
                                    {item}
                                  </Card>
                                )
                              )}
                            </div>
                          </Grid>
                        )}
                      </Grid>
                      {!isDelete && (
                        <AvailabilityHeatMap
                          selectedClients={selectedClients}
                          selectedProvider={selectedProvider}
                          isValidAvailability={isValidAvailability}
                        />
                      )}

                      {isDelete && (
                        <>
                          <Grid size={{ xs: 12 }}>
                            <InputLabel className="fromLabel">
                              Who cancelled the appointment?
                            </InputLabel>
                            <div className="fromGroup-chips">
                              {WhoCancelled.map(
                                (item: string, index: number) => (
                                  <Card
                                    className={
                                      whoCancelled === item
                                        ? "fromGroup-chip-active"
                                        : ""
                                    }
                                    onClick={() => {
                                      setWhoCancelled(item);
                                      setAppointmentError(false);
                                    }}
                                    key={item}
                                  >
                                    {item}
                                  </Card>
                                )
                              )}
                            </div>
                            {appointmentError ? (
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                Please Select at least open option
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </Grid>
                          <Grid size={{ xs: 12 }}>
                            <InputLabel className="fromLabel">
                              Cancel Reason:
                            </InputLabel>
                            <div className="fromGroup-chips">
                              <Select
                                id="demo-simple-select"
                                MenuProps={MenuProps}
                                value={cancellationReason}
                                onChange={(e: SelectChangeEvent<string>) => {
                                  setCancellationReason(e.target.value);
                                  setReasonError(false);
                                }}
                              >
                                {(whoCancelled === "Client"
                                  ? ClientReasonCatalog
                                  : whoCancelled === "Employee"
                                  ? ProviderReasonCatalog
                                  : []
                                )?.map((item: any) => {
                                  return (
                                    <MenuItem
                                      key={item.id}
                                      value={item.conceptValue}
                                    >
                                      {item.conceptValue}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </div>
                            {reasonError ? (
                              <FormHelperText style={{ color: "#d32f2f" }}>
                                Please select cancel reason
                              </FormHelperText>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </>
                      )}
                      {(isNew || isEdit) && (
                        <Grid size={{ xs: 12 }}>
                          <InputLabel>Description</InputLabel>
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            placeholder="Leave some comment here..."
                            style={{
                              width: "100%",
                              boxSizing: "border-box",
                              background: "#FFFFFF",
                              border: "1px solid #D9D9D9",
                              borderRadius: "2px",
                            }}
                            value={description}
                            onChange={(e: any) => {
                              setDescription(e.target.value);
                            }}
                            //autoFocus
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Box>

          <div ref={bottomRef}>
            {bookingProcessing && (
              <div className="eventStream-content">
                <Box className="content">
                  {bookingStatus === "" && (
                    <div>
                      <Typography className="msg">In Progress</Typography>
                      <CircularProgress />
                    </div>
                  )}
                  {bookingStatus === "error" && (
                    <ErrorOutlineIcon color="warning" />
                  )}
                  <Typography className="msg">{sseMessage}</Typography>
                  <Typography className="msg">{bookingErrorMsg}</Typography>
                </Box>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions sx={{ padding: "0px" }}>
          <Box className="largeModel__footer">
            <div>
              {isDelete !== undefined
                ? isDelete && (
                    <Button
                      className="button"
                      onClick={(e) => {
                        setPermanentDelete(true);
                        setModal(true);
                      }}
                    >
                      Permanent Delete
                    </Button>
                  )
                : ""}
            </div>
            {argsdata?.status !== "Cancelled" && (
              <Button
                className="button"
                onClick={(e) => {
                  onSubmitBooking(e);
                }}
              >
                {isNew
                  ? "Add Booking"
                  : isEdit
                  ? "Save Booking"
                  : "Cancel Booking"}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
      {isDelete && permanentDelete && (
        <Dialog open={model} className="smallModel">
          <div>
            <DialogContent>
              {"Do you want to permenantly Delete the Booking?"}
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  setModal(false);
                  setPermanentDelete(false);
                }}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={(
                  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                ) => {
                  e.preventDefault();
                  setModal(false);
                  onSubmitBooking(e);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default DiscoverBookingEditModal;
