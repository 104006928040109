import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useState } from "react";
import "./message-details.scss";
import MessageUserList from "../message-list/MessageUserList";
import MessageField from "../message-field/MessageField";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Loader from "../../../layouts/loader/Loader";

const MessageDetails: React.FC = () => {
  const [isMobileView, setIsMobileView] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<string>("Clients");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<{
    id: string;
    name: string;
    entityType: string;
  }>({
    id: "",
    name: "",
    entityType: "Client",
  });

  return (
    <Box sx={{ flexGrow: 1 }} className="mapWrapper">
      {isLoading && <Loader isShow={isLoading} />}
      <Grid container spacing={4} columns={16} sx={{ height: "100%" }}>
        {((selectedUser.id === "" && isMobileView) || !isMobileView) && (
          <Grid size={{ xs: 16, md: 5, lg: 4 }}>
            <MessageUserList
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
              isMobileView={isMobileView}
              setIsMobileView={setIsMobileView}
              setSelectedTab={setSelectedTab}
              selectedTab={selectedTab}
              setIsLoading={setIsLoading}
            />
          </Grid>
        )}
        {((selectedUser.id !== "" && isMobileView) || !isMobileView) && (
          <>
            {isMobileView && (
              <div style={{ position: "absolute", top: "15%" }}>
                <Button
                  onClick={() =>
                    setSelectedUser({
                      id: "",
                      name: "",
                      entityType: selectedUser.entityType,
                    })
                  }
                  className="backBtn"
                  startIcon={<KeyboardBackspaceIcon />}
                  style={{ marginBottom: 0 }}
                >
                  Back
                </Button>
              </div>
            )}
            <Grid size={{ xs: 16, md: 11, lg: 12 }}>
              <MessageField
                selectedUser={selectedUser}
                selectedTab={selectedTab}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default MessageDetails;
