import React, { useState, useMemo, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSession, useUser } from "@descope/react-sdk";

import { useAppSelector } from "../redux/store";

import SideMenu from "../components/side-menu/SideMenu";
import CommonHeader from "../layouts/header/Header";

import { AppUrls } from "../AppUrls";

import { getRolesForTenant } from "../utils/getRolesForTenant";
import ErrorPage from "src/pages/error/ErrorPage";

// Private Routes
const PrivateRoute = ({ children, ...rest }: any) => {
  // //#region variable region
  const { sideMenuSlider } = useAppSelector((state) => state.auth);
  const orgData = useAppSelector((state) => state.organizationSlice.orgData);

  const navigate = useNavigate();
  const location = useLocation();

  /* Descope Integration */
  const { isAuthenticated, isSessionLoading } = useSession();
  const { user, isUserLoading } = useUser();
  // const [sessionToken, setSessionToken] = useState(sdk.getSessionToken());
  const [isRefreshLoading, SetIsRefreshLoading] = useState(false);
  const currentTenantId = user?.customAttributes?.currentTenantId;
  const currentRoles = useMemo(() => {
    return getRolesForTenant(user, currentTenantId);
  }, [user, currentTenantId]);

  useEffect(
    () => localStorage.setItem("path", location.pathname),
    [location.pathname]
  );

  useEffect(() => {
    if (isAuthenticated) {
      if (location.pathname !== AppUrls.Client.OrganizationOnboard && orgData) {
        const allowEnter =
          orgData?.currentSubscriptionId !== null || //already paid, allow to enter!
          new Date(orgData.createdAt).getTime() <
            new Date("2024-3-27").getTime() || //before cut-over, allow to enter!
          currentRoles.includes("ABAEngine Admin") || //ABAEngineAdmin, allow to enter!
          orgData?.enterpriseApprove; //enterprise approved, allow to enter!

        // console.log("whether to enter",
        //   allowEnter,
        //   location.pathname,
        //   orgData?.currentSubscriptionId,
        //   orgData.createdAt,
        //   currentRoles);

        if (allowEnter) {
          // console.log("show map");
          // navigate(AppUrls.Client.Map);
        } else {
          // console.log("show org onboarding");
          navigate(AppUrls.Client.OrganizationOnboard);
        }
      }
    } else {
      // console.log("show sign in");
      navigate(AppUrls.Client.SignIn);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, location, orgData?.currentSubscriptionId]);

  if (isSessionLoading || isUserLoading || isRefreshLoading) {
    return <div>Loading...</div>;
  }

  //For Un-Authorized User
  if (
    isAuthenticated &&
    currentRoles &&
    currentRoles.length > 0 &&
    // location.pathname !== "/organization-onboard" &&
    !(
      currentRoles.includes("Admin") ||
      currentRoles.includes("ABAEngine Admin") ||
      currentRoles.includes("Owner")
    )
  ) {
    return <ErrorPage />;
  }

  // For Authenticated User
  return (
    <React.Fragment>
      <>
        {user && user.userTenants && user.userTenants?.length > 0 ? (
          <>
            {location.pathname !== AppUrls.Client.OrganizationOnboard && (
              <>
                <CommonHeader />
                <SideMenu menuIcon={sideMenuSlider} />
              </>
            )}
          </>
        ) : null}
        {children}
      </>
    </React.Fragment>
  );
};

export default PrivateRoute;
