import React, { useEffect, useState } from "react";
import { bindActionCreators } from "@reduxjs/toolkit";
import { useRef } from "react";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Delete, KeyboardBackspace } from "@mui/icons-material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { AvailabilityDetails } from "../../../types/availability";
import { Credentials, ProviderForms } from "../../../types/provider";
import {
  GetProviderById,
  getProviderFilesById,
  getProviderMessagesById,
  setSingleProviderData,
} from "../../../redux/features/providers-main/provider-list-slice";
import { setCredentialDetails } from "../../../redux/features/providers-main/provider-slice";
import { deleteProviderById } from "../../../redux/features/providers-main/provider-slice";
import DeleteConfirmation from "../../../layouts/delete-confirmation/DeleteConfirmation";
import { setResponseValue } from "../../../redux/features/api-response/api-response";
import SubEmploymentDetails from "./sub-details/SubEmploymentDetails";
import SubContactDetails from "./sub-details/SubContactDetails";
import SubProviderStatus from "./sub-details/SubProviderStatus";
import ProviderSubAvailability from "./sub-details/ProviderSubAvailability";
import SubProviderInsuranceDetails from "./sub-details/SubProviderInsuranceDetails";
import ProviderSubFilesList from "./sub-details/ProviderSubFilesList";
import ProviderSubComments from "./sub-details/ProviderSubComments";
import SubSchedulerNew from "./sub-details/SubScheduleNew";
import { setSelectedAvailabiltyValues } from "../../../redux/features/Common/availability-slice";
import { sortDataArray } from "../../../utils/AvailabilityUtils";
import "./providersDetails.scss";
import "react-calendar-heatmap/dist/styles.css";
import { clearSchedulesMatchValues } from "../../../redux/features/schedules-main/match-slice";
import { ClientForms } from "../../../types/client";
import { GetAllClientData } from "../../../redux/features/client-main/client-list-slice";
import ProviderCertificates from "./sub-details/ProviderCertificates";
import Loader from "src/layouts/loader/Loader";

const ProvidersDetails: React.FC = () => {
  // #region variables region

  //dispatch for redux
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //routing object
  const navigate = useNavigate();
  const location = useLocation();

  //provider id for fetching the particular provider
  const { id } = useParams();

  //stores provider insurance
  const providerInsurance = useSelector<RootState, Credentials[]>(
    (state) => state.providerSlice.providerInsurance
  );

  //messages change notifier
  const messagesChange = useSelector<RootState, boolean>(
    (state) => state.providerListSlice.providerMessagesChange
  );

  //stores client list
  const clientList = useSelector<RootState, ClientForms[]>(
    (state) => state.clientListSlice.listData
  );
  // change availability
  const [availabilityChange, setAvailabilityChange] = useState<boolean>(false);

  //selected tab
  const [selectedTab, setSelectedTab] = useState<string>("Summary");

  //handle show error
  const [_, setHasError] = useState<boolean>(false);

  //keeps track of index for particular authorization to edit and delete authorization or authorization file
  const [authIndex, setAuthIndex] = useState<number | null>(null);

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //single provider data
  const data = useSelector<RootState, ProviderForms>(
    (state) => state.providerListSlice.singleProvider
  );

  //checks for data update
  const dataChange = useSelector<RootState, boolean>(
    (state) => state.providerListSlice.listDataChange
  );

  //checks for data update
  const filesChange = useSelector<RootState, boolean>(
    (state) => state.providerListSlice.providerFilesChange
  );

  const isDataLoading = useSelector<RootState, boolean>(
    (state) => state.providerListSlice.loader
  );

  const responseLoader = useSelector<RootState, boolean>(
    (state) => state.responseSlice.pending
  );
  //Handle match scheduele provider
  const MatchScheduleHandle = () => {
    if (data?.availabilityDetails?.length > 0) {
      //must to clean previous match slice data
      dispatch(clearSchedulesMatchValues({}));
      localStorage.setItem("entityType", "Provider");
      navigate(`/schedule/${id}`, {
        state: {
          counter: 1,
          entityType: "Provider",
        },
      });
    } else {
      setHasError(true);
    }
  };

  //binding action creators
  const dispatchAction = bindActionCreators(
    {
      setSingleProviderData,
      GetProviderById,
      setResponseValue,
      setCredentialDetails,
      getProviderFilesById,
      getProviderMessagesById,
      GetAllClientData,
    },
    dispatch
  );

  //dispatch ref stored between multiple re-renders
  const dispatchActionRef = useRef(dispatchAction);

  // #endregion

  // #region methods region

  //handles navigation
  const backToProviders = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault();
    //navigate("/providers", "All Employees" );
    navigate(-1);
  };

  //handles deleting provider
  const deleteProvider = (value: boolean) => {
    if (value) {
      dispatch(deleteProviderById({ providerId: id as string }));
      navigate("/employees");
    }
    setDeletePopup(false);
  };

  // #endregion

  // #region useEffect region

  //re-fetches data if data is updated
  useEffect(() => {
    if (id) {
      dispatchActionRef.current.GetProviderById({ providerId: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, dataChange]);

  useEffect(() => {
    if (id) {
      dispatchActionRef.current.getProviderFilesById({ providerId: id });
    }
  }, [id, filesChange]);

  useEffect(() => {
    if (id) {
      dispatchActionRef.current.getProviderMessagesById({ providerId: id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, messagesChange]);

  useEffect(() => {
    if (data?.availabilityDetails?.length > 0) {
      const defaultDate = new Date();
      const availabilityForToday = data.availabilityDetails.find(
        (availability) =>
          new Date() >=
            (availability.beginDate
              ? new Date(availability.beginDate)
              : defaultDate) &&
          new Date() <=
            (availability.endDate
              ? new Date(availability.endDate)
              : defaultDate)
      );

      if (availabilityForToday) {
        dispatch(
          setSelectedAvailabiltyValues({
            name: "availability",
            value: availabilityForToday,
          })
        );
      } else {
        const defaultSelectedAvailablility = sortDataArray<AvailabilityDetails>(
          data?.availabilityDetails
        )[0];

        dispatch(
          setSelectedAvailabiltyValues({
            name: "availability",
            value: defaultSelectedAvailablility,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, selectedTab]);

  //fetches clients list
  useEffect(() => {
    if (clientList.length === 0) {
      dispatchActionRef.current.GetAllClientData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // #endregion

  return (
    <React.Fragment>
      <div className="pageTemplate">
        {isDataLoading && !responseLoader && <Loader isShow={isDataLoading} />}
        <Box className="detailsPageHeader">
          <Grid container spacing={0}>
            <Grid size={{ xs: 12, sm: 9 }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => backToProviders(e)}
                  className="backBtn"
                  startIcon={<KeyboardBackspace />}
                  style={{ marginBottom: 0 }}
                >
                  Back
                </Button>
                <Typography variant="h6" className="DetailsPageTitle">
                  Employee Details - {data.employmentDetails?.firstName}{" "}
                  {data.employmentDetails?.lastName}
                </Typography>
              </div>
            </Grid>
            <Grid size={{ xs: 12, sm: 3 }}>
              <Box className="detailsPageHeader__btn">
                <Button
                  type="submit"
                  variant="contained"
                  startIcon={<Delete />}
                  className="button"
                  onClick={(
                    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    e.preventDefault();
                    setDeletePopup(true);
                  }}
                >
                  Delete Employee
                </Button>
              </Box>
            </Grid>
            <Grid size={{ xs: 12 }}>
              <Box
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <ul className="innerTabs">
                  <li className="innerTabs__item">
                    <button
                      onClick={() => setSelectedTab("Summary")}
                      className={selectedTab === "Summary" ? "active" : ""}
                    >
                      Summary
                    </button>
                  </li>
                  <li className="innerTabs__item">
                    <button
                      onClick={() => setSelectedTab("Schedules")}
                      className={selectedTab === "Schedules" ? "active" : ""}
                    >
                      Schedules
                    </button>
                  </li>
                  <li className="innerTabs__item">
                    <button
                      onClick={() => setSelectedTab("Files")}
                      className={selectedTab === "Files" ? "active" : ""}
                    >
                      Files
                    </button>
                  </li>
                  <li className="innerTabs__item">
                    <button
                      onClick={() => setSelectedTab("News")}
                      className={selectedTab === "News" ? "active" : ""}
                    >
                      News
                    </button>
                  </li>
                </ul>
                <Box>
                  {selectedTab === "Schedules" ? (
                    <Button onClick={MatchScheduleHandle} variant="contained">
                      Match Clients
                    </Button>
                  ) : (
                    ""
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="detailsPageBody">
          <Grid container spacing={2}>
            {selectedTab === "Summary" && (
              <>
                <SubEmploymentDetails
                  employmentDetails={data.employmentDetails}
                  id={id as string}
                />
                <SubProviderStatus
                  employmentDetails={data.employmentDetails}
                  id={id as string}
                />
                <SubContactDetails
                  contactDetails={data.contactDetails}
                  id={id as string}
                />
                <SubProviderInsuranceDetails
                  providerInsurance={providerInsurance}
                  id={id as string}
                  authIndex={authIndex}
                  setAuthIndex={setAuthIndex}
                />
                <ProviderCertificates
                  providerCertificates={data.providerCertificates}
                  providerId={id as string}
                />
              </>
            )}
          </Grid>
        </Box>
        {selectedTab === "Schedules" && (
          <>
            <ProviderSubAvailability
              providerData={data}
              setIsDataEdited={setAvailabilityChange}
            />
            <SubSchedulerNew
              selectedClients={[]}
              selectedProviders={[data]} //show selected provider
              isDataEdited={availabilityChange}
              setIsDataEdited={setAvailabilityChange}
              clientList={clientList} //populate initial client list
            />
          </>
        )}
        {selectedTab === "Files" && (
          <ProviderSubFilesList
            providerId={id as string}
            providerName={`${data.employmentDetails.firstName} ${data.employmentDetails.lastName}`}
          />
        )}
        {selectedTab === "News" && (
          <ProviderSubComments providerId={id as string} />
        )}
        {deletePopup && (
          <DeleteConfirmation
            name={"employee"}
            setModal={(value) => deleteProvider(value)}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ProvidersDetails;
