import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, useAppSelector } from "../../../redux/store";
import { ITaskState } from "../../../types/trello-board";
import Board from "../../trello-board/Board";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import {
  moveClientCardToAnotherBoard,
  setClientTasks,
} from "../../../redux/features/client-main/client-board-slice";
import { OrgData } from "../../../types/company";
import { OrgService } from "../../../constants/company-user";
import { BoardCard, Board as BoardI, stages } from "../../../types/board";
import { OrgBU } from "src/types/company";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { setBusinessUnitFilters } from "src/redux/features/custom-board/custom-board-slice";
import { GetAllClientData } from "src/redux/features/client-main/client-list-slice";
import Loader from "src/layouts/loader/Loader";
import { updateIntakeStage } from "src/redux/features/client-main/client-intake-stage";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Boards = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: baseline;
`;

interface ClientBoardProps {
  callbacks: ((clientId: string) => Promise<void>)[];
  chatCommand: (id: string) => void;
}

const BoardStage: React.FC<ClientBoardProps> = ({ callbacks, chatCommand }) => {
  // #region variables region
  const org = useAppSelector<OrgData>(
    (state) => state.organizationSlice.orgData
  );

  //get all clients
  const clientList = useAppSelector((state) => state.clientListSlice.listData);

  //get loading status for getclients api
  const loader = useAppSelector((state) => state.clientListSlice.loader);
  //client boards
  const inittasks = useAppSelector<ITaskState>(
    (state) => state.clientBoardSlice
  );

  const intakeBoardInfo = useAppSelector<BoardI>(
    (state) => state.clientIntakeStage
  );
  const [intakeStages, setIntakeStages] = useState<stages[]>([]);

  const businessUnitsFilterDetails = useAppSelector(
    (state) => state.boardSlice.selectedBusinessUnits
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const selectedBusinessUnits =
    businessUnitsFilterDetails?.find(
      (item) => item.boardId === intakeBoardInfo.id
    )?.businessUnits || [];

  const [tasks, setTasks] = useState(inittasks);
  //dispatch for redux
  const dispatch = useDispatch<AppDispatch>();

  const businessUnits = useAppSelector(
    (state) => state.businessUnitsSlice.businessUnits
  );

  // #endregion

  // #region methods region
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  //handles drop logic
  const onDragEnd = (dropResult: DropResult) => {
    const { source, destination, draggableId } = dropResult;
    if (!destination) return;

    // Move Tasks
    if (source.droppableId === destination?.droppableId) {
      // Move a task in same board.
      let reOrderedTasks = [...tasks[destination.droppableId]];
      const sourceTask = {
        ...reOrderedTasks[source.index],
        // boardColumnUpdatedAt: new Date().toString(),
      };
      reOrderedTasks.splice(source.index, 1);
      reOrderedTasks.splice(destination?.index, 0, sourceTask);
      dispatch(
        setClientTasks({
          ...tasks,
          [destination?.droppableId]: reOrderedTasks,
        })
      );
      const fromBoardId = intakeStages.find(
        (item) => item.stageName === source.droppableId
      );

      if (fromBoardId) {
        //update state data
        dispatch(
          updateIntakeStage({
            updatedStages: [
              { ...fromBoardId, boardColumnNames: reOrderedTasks },
            ],
            boardId: intakeBoardInfo.id,
          })
        );
        //api call
        dispatch(
          moveClientCardToAnotherBoard({
            fromBoardId: fromBoardId.id,
            toBoardId: fromBoardId.id, //null,
            cardId: draggableId,
            index: destination.index,
          })
        );
      }
    } else if (source?.droppableId !== destination?.droppableId) {
      // Move task to different board
      let modifiedSourceTasks = [...tasks[source.droppableId]];
      let modifiedDestinationTasks = [...tasks[destination?.droppableId]];
      const sourceTask = {
        ...modifiedSourceTasks[source.index],
        boardColumnUpdatedAt: new Date().toString(),
      };
      modifiedSourceTasks.splice(source.index, 1);
      modifiedDestinationTasks.splice(destination?.index, 0, sourceTask);
      const fromBoardId = intakeStages.find(
        (item) => item.stageName === source.droppableId
      );
      const toBoardId = intakeStages.find(
        (item) => item.stageName === destination.droppableId
      );

      if (fromBoardId && toBoardId) {
        //update state data
        dispatch(
          updateIntakeStage({
            updatedStages: [
              { ...fromBoardId, boardColumnNames: modifiedSourceTasks },
              { ...toBoardId, boardColumnNames: modifiedDestinationTasks },
            ],
            boardId: intakeBoardInfo.id,
          })
        );
        //api call
        dispatch(
          moveClientCardToAnotherBoard({
            fromBoardId: fromBoardId.id,
            toBoardId: toBoardId.id,
            cardId: draggableId,
            index: destination.index,
          })
        );
      }
    } else {
      return;
    }
  };

  //sort boards stages by index
  const sortedTasks = useMemo(() => {
    if (!intakeBoardInfo) return {};
    return intakeBoardInfo.stages
      .slice()
      .sort((a, b) => a.index - b.index)
      .reduce((acc: any, stage) => {
        acc[stage.stageName] = stage.boardColumnNames;
        return acc;
      }, {});
  }, [intakeBoardInfo]);

  useEffect(() => {
    if (
      org.orgService &&
      org.orgService.length === 1 &&
      org.orgService.includes(OrgService.MentalHealth)
    ) {
      const singleStage = intakeBoardInfo.stages[0];
      setIntakeStages([singleStage]);
    } else {
      setIntakeStages(intakeBoardInfo.stages); //must have!!
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intakeBoardInfo.stages, org]);

  // fetch all clients
  useEffect(() => {
    if (clientList.length === 0) {
      dispatch(GetAllClientData());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // business unit filtering
  useEffect(() => {
    if (!intakeBoardInfo || !clientList.length) {
      return;
    }
    if (selectedBusinessUnits.length > 0) {
      // Filter clients based on selected business units and extract their IDs
      const filteredClientIds = clientList
        .filter((client) =>
          selectedBusinessUnits.some((bu: OrgBU) =>
            client.clientBasicDetails.businessUnit.includes(bu.id)
          )
        )
        .map((item) => item.clientBasicDetails.id);

      // Reduce intakeBoardInfo.stages to a filtered tasks object
      const filteredTasks = intakeBoardInfo.stages.reduce((acc: any, stage) => {
        const stageCards = sortedTasks[stage.stageName] || [];
        acc[stage.stageName] = stageCards.filter((card: BoardCard) =>
          filteredClientIds.includes(card.entityId)
        );
        return acc;
      }, {});
      setTasks(filteredTasks);
    } else {
      setTasks(sortedTasks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBusinessUnits, clientList, intakeBoardInfo]);

  // #endregion
  return (
    <>
      <div className="taskHeader">
        <Typography>Filter:</Typography>
        <div className="taskFormController">
          <FormControl className="task-outer">
            <InputLabel id="demo-simple-select-label">
              {"Business Unit"}
            </InputLabel>
            <Select
              className="form-control bufilterDropdown"
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              style={{ width: "250px" }}
              value={selectedBusinessUnits}
              onChange={(e) => {
                const selectedId = e.target.value;
                let updatedSelectedBU: OrgBU[] = [...selectedBusinessUnits];

                const isSelected = selectedBusinessUnits.some(
                  (item: OrgBU) => item.id === selectedId
                );

                if (isSelected) {
                  updatedSelectedBU = selectedBusinessUnits.filter(
                    (item: OrgBU) => item.id !== selectedId
                  );
                } else {
                  const newBUFilter = businessUnits.find(
                    (item) => item.id === selectedId
                  );
                  if (newBUFilter) {
                    updatedSelectedBU = [...selectedBusinessUnits, newBUFilter];
                  }
                }
                dispatch(
                  setBusinessUnitFilters({
                    boardId: intakeBoardInfo.id,
                    businessUnits: updatedSelectedBU,
                  })
                );
              }}
              renderValue={(selected: OrgBU[]) =>
                selected.map((b) => b.businessUnitName).join(", ")
              }
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  removeFocus("demo-simple-select");
                }
              }}
              MenuProps={MenuProps}
            >
              {businessUnits?.map((item: OrgBU) => (
                <MenuItem key={item.id} value={item.id}>
                  <Checkbox
                    checked={selectedBusinessUnits.some(
                      (bu: OrgBU) => bu.id === item.id
                    )}
                  />
                  {item.businessUnitName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <Loader isShow={loader} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId={"boards"}
          type={"boards"}
          direction={"horizontal"}
        >
          {({ innerRef, droppableProps, placeholder }) => (
            <Boards ref={innerRef} {...droppableProps}>
              <div className="allStageCards">
                {Object.keys(tasks).map((stageName, index) => (
                  <Board
                    key={stageName}
                    index={index}
                    //boardName={stageName}
                    stage={intakeStages?.find(
                      (stage: any) => stage.stageName === stageName
                    )}
                    tasks={tasks[stageName]}
                    callbacks={callbacks}
                    chatCommand={chatCommand}
                  />
                ))}
              </div>
              {placeholder}
            </Boards>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default BoardStage;
