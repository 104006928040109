import React, { useState, useEffect, SetStateAction } from "react";
import { ClientForms } from "../../../types/client";
// import ClientImg from "../../../assets/images/images-svg/client-large.svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState, useAppSelector } from "../../../redux/store";

import "../../maps/Map-Details/mapDetails.scss";
import { ProviderForms } from "../../../types/provider";
import PlannerSupervisorList from "./PlannerSupervisorList";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Popover,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ClientAvailabilityDetailsNew from "../../clients/client-stepper/ClientAvailabilityDetailsNew";
import {
  deleteSingleAuthorization,
  deleteSingleAvailability,
  removeAuthorization,
  setAuthorizationDetails,
  setAvailabilityDetails,
  setClientInsuranceDetails,
  updateClientAuthorizations,
  createClientAuthorizations,
  updateClientAvailabilityDetailById,
  setClientDetails,
} from "../../../redux/features/client-main/client-slice";
import { GetAllClientData } from "../../../redux/features/client-main/client-list-slice";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IPlanninglistSelect } from "../planning-Details/PlanningsDetails";
import AuthorizationsDetails from "../../clients/client-stepper/AuthorizationsDetails";
import { PlannerFilter } from "../../../types/planning";

interface PlannerClientListProps {
  clientsListData: ClientForms[];
  // setBookingChange: Dispatch<React.SetStateAction<boolean>>;
  activeTab: boolean;
  setSelectedClients: (item: ClientForms[]) => void;
  allSelectedClients: ClientForms[];
  selectedApplicationTab: string;
  setSelectedProvider: Function;
  providerListData: ProviderForms[];
  selectedProvider: ProviderForms[];
  setchangedList: React.Dispatch<React.SetStateAction<boolean>>;
  selectedClientIds: IPlanninglistSelect[];
  setSelectedClientIds: React.Dispatch<
    React.SetStateAction<IPlanninglistSelect[]>
  >;
  selectedProviderIds: IPlanninglistSelect[];
  bookingData: { [key: string]: string[] };
  setSelectedProviderIds: React.Dispatch<
    React.SetStateAction<IPlanninglistSelect[]>
  >;
  setIsAllSelected: React.Dispatch<
    SetStateAction<{
      client: boolean;
      provider: boolean;
    }>
  >;
  isAllSelected: { client: boolean; provider: boolean };
  filteredProviderList: ProviderForms[];
  isFilterApplied: boolean;
}

const PlannerClientList: React.FC<PlannerClientListProps> = ({
  clientsListData,
  // setBookingChange,
  activeTab,
  setSelectedClients,
  allSelectedClients,
  setSelectedProvider,
  providerListData,
  selectedProvider,
  bookingData,
  setchangedList,
  selectedClientIds,
  setSelectedClientIds,
  selectedProviderIds,
  setSelectedProviderIds,
  isAllSelected,
  setIsAllSelected,
  isFilterApplied,
  filteredProviderList,
}) => {
  //#region variable region

  const dispatch = useDispatch<AppDispatch>();

  const authorizations = useAppSelector((state) => state.client.authorizations);

  const [prevAuths, setPrevAuths] = useState();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [isDataEdited, setIsDataEdited] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<
    Record<"auth" | "availability", boolean>
  >({
    auth: false,
    availability: false,
  });
  const [, setAuthLength] = useState<number>(0);
  const [selectedClient, setSelectedClient] = useState<ClientForms | null>(
    null
  );
  const [authId, setAuthId] = useState<string>();
  const [availabilityId, setAvailabilityId] = useState<string>();
  const [clientSupervisorLength, setClientSuperVisorLength] =
    useState<number>(0);

  const open = Boolean(anchorEl);
  const filteredAuth = useSelector<RootState, PlannerFilter>(
    (state) => state.planningFilterSlice.PlanningFilter
  ).authCode;

  const sortedList = clientsListData?.sort((a: ClientForms, b: ClientForms) => {
    // Check if the client ID `a` is in `selectedClientIds`
    const isASelected = selectedClientIds.some(
      (item) => item.id === a.clientBasicDetails.id && item.single
    );
    // Check if the client ID `b` is in `selectedClientIds`
    const isBSelected = selectedClientIds.some(
      (item) => item.id === b.clientBasicDetails.id && item.single
    );

    // First, sort by whether they are in `selectedClientIds`
    if (isASelected && !isBSelected) {
      return -1; // `a` should come first
    } else if (!isASelected && isBSelected) {
      return 1; // `b` should come first
    }

    // sort the list from A-Z
    const nameA = a.clientBasicDetails.childFirstName?.toUpperCase() || "";
    const nameB = b.clientBasicDetails.childFirstName?.toUpperCase() || "";

    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  });

  const checkAllSelection = (selection: IPlanninglistSelect[]) => {
    const singleSelection = selection.filter((item) => item.single);
    const groupSelection = selection.filter((item) => item.group);
    if (
      (singleSelection.length < clientsListData.length &&
        groupSelection.length === clientSupervisorLength) ||
      (singleSelection.length === clientsListData.length &&
        groupSelection.length < clientSupervisorLength &&
        isAllSelected.client)
    ) {
      setIsAllSelected({ ...isAllSelected, client: false });
    } else if (
      singleSelection.length === clientsListData.length &&
      groupSelection.length === clientSupervisorLength &&
      !isAllSelected.client
    ) {
      setIsAllSelected({ ...isAllSelected, client: true });
    }
  };

  // #region methods region

  const handleMultiple = (client: ClientForms, superVisorId: string) => {
    // setBookingChange(true);

    //client part ===================================================================
    const isExist = selectedClientIds.find(
      (cli) => cli.id === client.clientBasicDetails.id
    );
    const selected = allSelectedClients.find(
      (item) => item.clientBasicDetails.id === client.clientBasicDetails.id
    );

    let newSelection = [...selectedClientIds];
    if (isExist) {
      newSelection = selectedClientIds.map((item) =>
        item.id === client.clientBasicDetails.id
          ? { ...item, group: !item.group }
          : item
      );
    }
    if (!isExist) {
      newSelection.push({
        id: client.clientBasicDetails.id,
        group: true,
        single: false,
      });
    }
    setSelectedClientIds(newSelection);
    checkAllSelection(newSelection);

    if (selected && !isExist?.single) {
      //group case
      const newClients = allSelectedClients.filter(
        (item) => item.clientBasicDetails.id !== client.clientBasicDetails.id
      );
      setSelectedClients(newClients); //affect calendar list
    } else {
      //single case
      setSelectedClients([...allSelectedClients, client]); //affect calendar list
    }

    //employee Part===================================================================

    const [data] = providerListData
      .filter((item) => item.employmentDetails.id === superVisorId)
      .map((item) => item.employmentDetails.providerList);

    let employeeIds = [superVisorId];
    providerListData.forEach((item) => {
      if (
        data?.includes(item.employmentDetails.id) &&
        item.employmentDetails.isSupervisor
      ) {
        employeeIds.push(item.employmentDetails.id);
      }
    });

    let newSelectionp = [...selectedProviderIds];
    let provider = selectedProvider;

    employeeIds.forEach((Id) => {
      const isExist = selectedProviderIds.find(
        (selected) => selected.id === Id
      );
      const isSelected = selectedProvider.some(
        (selected) => selected.employmentDetails.id === Id
      );

      newSelectionp = newSelectionp.map((pro) =>
        pro.id === Id ? { ...pro, clientGroup: !pro.clientGroup } : pro
      );

      if (!isExist) {
        newSelectionp.push({
          id: Id,
          group: false,
          single: false,
          clientGroup: true,
        });
      }

      if (isSelected && !isExist?.group && !isExist?.single) {
        provider = provider.filter((i) => i.employmentDetails.id !== Id);
      } else {
        const data = providerListData.find(
          (emp: any) => emp.employmentDetails.id === Id && !isSelected
        );
        if (data) {
          provider.push(data);
        }
      }
    });

    // get booked providers for this client
    const bookedProviders = bookingData[client.clientBasicDetails.id];
    let newSelectionp2 = [...newSelectionp];
    let provider2 = [...provider];
    if (bookedProviders) {
      bookedProviders.forEach((Id) => {
        const isExist = newSelectionp.find((selected) => selected.id === Id);
        const isSelected = provider.some(
          (selected) => selected.employmentDetails.id === Id
        );

        newSelectionp2 = newSelectionp2.map((pro) =>
          pro.id === Id ? { ...pro, clientGroup: !pro.clientGroup } : pro
        );

        if (!isExist) {
          newSelectionp2.push({
            id: Id,
            group: false,
            single: false,
            clientGroup: true,
          });
        }

        if (isSelected && !isExist?.group && !isExist?.single) {
          provider2 = provider2.filter((i) => i.employmentDetails.id !== Id);
        } else {
          const data = providerListData.find(
            (emp: any) => emp.employmentDetails.id === Id && !isSelected
          );
          if (data) {
            provider2.push(data);
          }
        }
      });
    }
    provider = [...provider, ...provider2];
    newSelectionp = [...newSelectionp, ...newSelectionp2];

    //refresh calendar provider list
    // if (isFilterApplied) {
    //   provider = provider.filter((item) =>
    //     filteredProviderList.some(
    //       (ele) => ele.employmentDetails.id === item.employmentDetails.id
    //     )
    //   );
    //   console.log("apply filter: ", filteredProviderList, provider);

    // }

    setSelectedProviderIds(newSelectionp); //affect data table list
    checkAllSelection(newSelectionp); // affect check list
    setSelectedProvider([...provider]); //affect calendar list
  };

  //handle select client data
  const handleClick = (item: ClientForms) => {
    // setBookingChange(true);

    if (!activeTab) {
      const isSelected = allSelectedClients.some(
        (pro) => pro.clientBasicDetails.id === item.clientBasicDetails.id
      );
      const isExist = selectedClientIds.find(
        (selected) => selected.id === item.clientBasicDetails.id
      );

      let newSelection = selectedClientIds;

      if (isExist) {
        newSelection = selectedClientIds.map((client) =>
          client.id === item.clientBasicDetails.id
            ? { ...client, single: !client.single }
            : client
        );
      } else {
        newSelection.push({
          id: item.clientBasicDetails.id,
          group: false,
          single: true,
        });
      }
      setSelectedClientIds(newSelection);
      checkAllSelection(newSelection);

      //refresh calendar list
      if (isSelected) {
        if (!isExist?.group) {
          const data = allSelectedClients.filter(
            (cli) => cli.clientBasicDetails.id !== item.clientBasicDetails.id
          );
          setSelectedClients(data);
        }
      } else {
        setSelectedClients([...allSelectedClients, item]);
      }
    }
  };

  //#endregion

  const onSubmitAvailabilityInfo = async () => {
    setIsEdit({ auth: false, availability: false }); //close the dialog first!
    if (availabilityId !== undefined) {
      dispatch(
        deleteSingleAvailability({
          availableId: availabilityId,
        })
      );
    }
    await dispatch(updateClientAvailabilityDetailById());
    await dispatch(GetAllClientData());
    setIsDataEdited(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = (item: ClientForms) => {
    setSelectedClient(item);
    dispatch(setClientDetails(item)); //set client details in redux
    dispatch(
      setAvailabilityDetails({
        data: item.availabilityDetails,
      })
    );
    if (item.authorizations) {
      setAuthLength(item.authorizations.length);
      dispatch(
        setAuthorizationDetails({
          authorization: item.authorizations,
        })
      );
    }
    if (item.clientInsurance) {
      dispatch(
        setClientInsuranceDetails({
          clientInsurance: item.clientInsurance,
          isEdit: false,
        })
      );
    }
  };

  const onSubmitAuthorizationsInfo = async () => {
    //close the dialog first!
    setAnchorEl(null);
    setIsEdit({ auth: false, availability: false });
    if (authId !== undefined) {
      await dispatch(deleteSingleAuthorization({ authId: authId }));
    }

    if (JSON.stringify(authorizations) !== JSON.stringify(prevAuths)) {
      for (const item of authorizations) {
        if (item?.id === "" && selectedClient)
          await dispatch(
            createClientAuthorizations({
              clientId: selectedClient?.clientBasicDetails.id,
              clientAuthorization: item,
            })
          );
        else
          await dispatch(
            updateClientAuthorizations({
              clientAuthId: item?.id,
              clientAuthorization: item,
            })
          );
      }

      await dispatch(GetAllClientData());
    }

    setIsDataEdited(true);
  };

  const handleAuthUtilization = (item: any) => {
    let total: number = 0;
    if (filteredAuth.length > 0) {
      item?.clientAuthUtilization?.timeTables?.forEach((auth: any) => {
        if (filteredAuth.includes(auth.timeTableCode)) {
          total = total + auth.weeklyAvgAuthorization;
        } else {
          return;
        }
      });
    } else {
      total = item?.clientAuthUtilization?.weeklyAvgAuthorization;
    }
    return total;
  };

  useEffect(() => {
    if (isDataEdited) {
      const data = clientsListData.filter((item) =>
        allSelectedClients.some(
          (ele) => ele.clientBasicDetails.id === item.clientBasicDetails.id
        )
      );
      setSelectedClients(data);
      setIsDataEdited(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataEdited, clientsListData]);

  useEffect(() => {
    const data = clientsListData.filter(
      (item) => item.clientBasicDetails?.supervisor
    ).length;
    setClientSuperVisorLength(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientsListData]);

  return (
    <div className="mapUserList calendarList">
      {clientsListData.length > 0 &&
        sortedList?.map((item: ClientForms, index: number) => {
          return (
            <div key={"provider" + index}>
              <div
                className={
                  selectedClientIds.some(
                    (cli) => item.clientBasicDetails.id === cli.id && cli.single
                  )
                    ? "fromGroup-chip-active"
                    : "userMapCart "
                }
              >
                <div className="cardLevelBlock">
                  <table>
                    <thead className="plan-thead">
                      <tr>
                        <th>
                          <Checkbox
                            checked={selectedClientIds.some(
                              (cli) =>
                                item.clientBasicDetails.id === cli.id &&
                                cli.single
                            )}
                            onChange={() => {
                              setchangedList(true); //make sure to auto-save plan

                              handleClick(item);
                            }}
                          />
                        </th>
                        {/* <th>
                          <span className="userMapCart__img">
                            <img
                              src={
                                item?.clientBasicDetails?.clientProfile?.url ||
                                ClientImg
                              }
                              className="userSmallCircle"
                              alt=""
                            />
                          </span>
                        </th> */}
                        <th style={{ minWidth: "132px" }}>
                          <div className="userMapCart__statusBox">
                            <span className="top-level-title">
                              {item?.clientAuthUtilization?.status}
                            </span>
                            <strong>
                              {item?.clientBasicDetails?.childFirstName}{" "}
                              {item?.clientBasicDetails?.childLastName}{" "}
                            </strong>
                          </div>
                        </th>
                        <th>
                          <div className="userMapCart__statusBox">
                            <span className="top-level-title">Auth</span>{" "}
                            <strong>
                              {handleAuthUtilization(item)?.toFixed(1)}
                            </strong>
                          </div>
                        </th>
                        <th>
                          <div className="userMapCart__statusBox">
                            <span className="top-level-title">Booked</span>{" "}
                            <strong>
                              {item?.clientAuthUtilization?.weeklyAvgBooked?.toFixed(
                                1
                              )}
                            </strong>
                          </div>
                        </th>
                        <th>
                          <div className="userMapCart__statusBox">
                            <span className="top-level-title">Remain</span>{" "}
                            <strong>
                              {item?.clientAuthUtilization?.weeklyAvgRemainedByAuthorization?.toFixed(
                                1
                              )}
                            </strong>
                          </div>
                        </th>
                        <th className="p-0">
                          <div>
                            <button
                              onClick={(
                                event: React.MouseEvent<HTMLButtonElement>
                              ) => {
                                setAnchorEl(event.currentTarget);
                                handleEdit(item);
                              }}
                              style={{
                                border: "none",
                                background: "none",
                                float: "right",
                                cursor: "pointer",
                                padding: "0px",
                              }}
                            >
                              <MoreVertIcon color="info" />
                            </button>
                          </div>
                        </th>
                      </tr>
                    </thead>
                  </table>
                </div>
              </div>
              {item?.clientBasicDetails?.supervisor && !activeTab && (
                <div
                  className={
                    !activeTab &&
                    selectedClientIds.some(
                      (cli) =>
                        item.clientBasicDetails.id === cli.id && cli.group
                    )
                      ? "fromGroup-chip-active"
                      : "userMapCart "
                  }
                  key={"supervisor" + index}
                >
                  <div className="cardLevelBlock" key={index}>
                    <table>
                      <thead className="plan-thead">
                        <tr>
                          <th>
                            <Checkbox
                              checked={selectedClientIds.some(
                                (cli) =>
                                  item.clientBasicDetails.id === cli.id &&
                                  cli.group
                              )}
                              onChange={() => {
                                setchangedList(true); //make sure to auto-save plan
                                handleMultiple(
                                  item,
                                  item?.clientBasicDetails?.supervisor.id
                                );
                              }}
                            />
                          </th>
                          {/* <th>
                            <span className="userMapCart__img">
                              <img
                                src={
                                  item?.clientBasicDetails?.clientProfile
                                    ?.url || ClientImg
                                }
                                className="userSmallCircle"
                                alt=""
                              />
                            </span>
                          </th> */}
                          <th style={{ minWidth: "132px" }}>
                            <div className="userMapCart__statusBox">
                              <span className="top-level-title">
                                {item?.clientAuthUtilization?.status}
                              </span>
                              <strong>
                                {item?.clientBasicDetails?.childFirstName}{" "}
                                {item?.clientBasicDetails?.childLastName}{" "}
                              </strong>
                            </div>
                          </th>
                          <th>
                            <div className="userMapCart__statusBox">
                              <span className="top-level-title">Auth </span>{" "}
                              <strong>
                                {handleAuthUtilization(item)?.toFixed(1)}
                              </strong>
                            </div>
                          </th>
                          <th>
                            <div className="userMapCart__statusBox">
                              <span className="top-level-title">Booked </span>{" "}
                              <strong>
                                {item?.clientAuthUtilization?.weeklyAvgBooked?.toFixed(
                                  1
                                )}
                              </strong>
                            </div>
                          </th>
                          <th>
                            <div className="userMapCart__statusBox">
                              <span className="top-level-title">
                                Remaining{" "}
                              </span>{" "}
                              <strong>
                                {item?.clientAuthUtilization?.weeklyAvgRemainedByAuthorization?.toFixed(
                                  1
                                )}
                              </strong>
                            </div>
                          </th>
                          <th className="p-7"></th>
                        </tr>
                      </thead>

                      <tbody className="plan-tbody">
                        <PlannerSupervisorList
                          supervisorId={item?.clientBasicDetails?.supervisor.id}
                          showSupervisor={true}
                          providerList={
                            bookingData
                              ? bookingData[item?.clientBasicDetails?.id]
                                ? bookingData[item?.clientBasicDetails?.id]
                                : null
                              : null
                          }
                        />
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          );
        })}
      {/* 3-dot menu */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ul className="popoverLinkList">
          <li
            onClick={() => {
              handleClose();
              setAvailabilityId(undefined);
              setIsEdit({ auth: false, availability: true });
            }}
          >
            Edit Availability
          </li>
          <li
            onClick={() => {
              handleClose();
              setAuthId(undefined);
              setIsEdit({ auth: true, availability: false });
            }}
          >
            Edit Authorization
          </li>
        </ul>
      </Popover>

      {isEdit.availability && (
        <Dialog
          open={isEdit.availability}
          className="largeModel largeModel--edit"
        >
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Availability -{" "}
              {`${selectedClient?.clientBasicDetails?.childFirstName} ${selectedClient?.clientBasicDetails?.childLastName}`}
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                setIsEdit({ auth: false, availability: false });
              }}
            >
              <Close />
            </Button>
          </Box>

          <ClientAvailabilityDetailsNew
            isBoardPage={false}
            onSubmit={onSubmitAvailabilityInfo}
            isEdit={true}
            setAvailabilityId={setAvailabilityId}
          />
        </Dialog>
      )}

      {isEdit.auth && (
        <Dialog open={isEdit.auth} className="largeModel largeModel--edit">
          <Box className="largeModel__head">
            <Typography variant="h5" className="largeModel__title">
              Edit Authorization -{" "}
              {`${selectedClient?.clientBasicDetails?.childFirstName} ${selectedClient?.clientBasicDetails?.childLastName}`}
            </Typography>
            <Button
              className="largeModel__closeBtn"
              onClick={() => {
                setIsEdit({ auth: false, availability: false });
                dispatch(removeAuthorization({}));
                setAuthLength(0);
              }}
            >
              <Close />
            </Button>
          </Box>
          <AuthorizationsDetails
            headerTitle={"Edit Authorization"}
            isEdit={true}
            setAuthId={setAuthId}
            onSubmit={onSubmitAuthorizationsInfo}
            setPrevAuths={setPrevAuths}
            // authIndex={authLength - 1}
          />
        </Dialog>
      )}
    </div>
  );
};

export default PlannerClientList;
