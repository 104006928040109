export enum FileModelType {
  "Client" = "Client",
  "ClientAuthorization" = "ClientAuthorization",
  "Provider" = "Provider",
  "ClientInsurance" = "ClientInsurance",
  "ProviderInsurance" = "ProviderInsurance",
  "Organization" = "Organization",
}

export interface FilePermissionSetting {
  fileOwner: {
    ownerCanSee: boolean;
  };
}
