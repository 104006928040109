import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Switch,
  Typography,
  Box,
  Card,
} from "@mui/material";
import {
  ColumnDirective,
  ColumnsDirective,
  ContextMenu,
  DetailRow,
  ExcelExport,
  Filter,
  GridComponent,
  Group,
  GroupSettingsModel,
  Inject,
  Page,
  PdfExport,
  Resize,
  SelectionSettingsModel,
  Sort,
  TextWrapSettingsModel,
  Toolbar,
} from "@syncfusion/ej2-react-grids";
import { AppDispatch, RootState } from "../../../../redux/store";
import { NewMessageTemplate } from "../../../../types/businessSettings";
import {
  GetMessageTemplate,
  deleteMessageTemplate,
  updateMessageTemplateById,
} from "../../../../redux/features/communication/messageTemplate-slice";
import DeleteIcon from "@mui/icons-material/Delete";
import { Edit } from "@mui/icons-material";
import { setCurrentTemplate } from "../../../../redux/features/data-catalog/data-catalog-slice";
import resourceCenterLogo from "../../../../assets/images/images-svg/resource-center.svg";

const BusinessMessage: React.FC = () => {
  //#region variable region

  const navigate = useNavigate();
  const { state } = useLocation();

  //Dispatch
  const dispatch: AppDispatch = useDispatch<AppDispatch>();

  //custom rendering
  const [isDataChanged, setIsDataChanged] = useState<boolean>(false);

  //delete popup handler
  const [deletePopup, setDeletePopup] = useState<boolean>(false);

  //store id
  const [messageTemplateId, setMessageTemplateId] = useState<string>("");

  const [filterGroup, setFilterGroup] = useState<string>( state?.templateGroup || "Admin");

  //fetch all message templates
  const messageTemplates = useSelector<RootState, NewMessageTemplate[]>(
    (state) => state.communicationSlice.MessageTemplates
  );

  const TemplateGroup = ["Admin", "Client", "Employee", "Archive"];
  //#endregion

  //#region methods region

  //handle SortData massage Settings
  const SortData = () => {
    let data: NewMessageTemplate[] = [];
    const filteredMessageTemplates = filterMessageTemplates()
    if (filteredMessageTemplates.length > 0) {
      let result = [...filteredMessageTemplates].sort(
        (a: NewMessageTemplate, b: NewMessageTemplate) => {
          return (
            (new Date(b.createdAt) as any) - (new Date(a.createdAt) as any)
          );
        }
      );
      data = [...result];
    }

    return data;
  };

  //handle switch
  const handleSwitchChange = async (item: NewMessageTemplate) => {
    try {
      // eslint-disable-next-line
      const data = await dispatch(
        updateMessageTemplateById({
          id: item.id,
          name: item.name,
          audience: item.audience,
          methods: item.methods,
          from: item.from,
          message: item.message,
          subject: item.subject,
          active: !item.active,
          admins: item.admins,
        })
      );
    } catch (error: any) {
      console.log(error);
    }
  };

  //#endregion

  //#region grid region

  let gridInstance: GridComponent;
  // let grid: Grid | null;

  const groupOptions: GroupSettingsModel = { showGroupedColumn: true };

  //text-wrap for grid
  const textWrapSettings: TextWrapSettingsModel = { wrapMode: "Content" };

  const rowSelected = () => {
    console.log("[rowSelected]");
    // if (gridInstance) {
    //   const selectedrowindex: number[] = gridInstance.getSelectedRowIndexes();
    //   const selectedrecords: object[] = gridInstance.getSelectedRecords();
    // }
  };
  const pdfExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };
  const excelExportComplete = (): void => {
    if (gridInstance) {
      gridInstance.hideSpinner();
    }
  };

  //select format for grid
  const selectionSettings: SelectionSettingsModel = {
    type: "Multiple",
    mode: "Both",
  };

  let refresh: boolean;
  function load(this: any) {
    // console.log("[load]");
    refresh = this?.refreshing;
  }

  function dataBound() {
    // console.log("[dataBound]");
    if (refresh && gridInstance) {
      gridInstance.groupColumn("subject");
      refresh = false;
      gridInstance.refresh(); //must have!! otherwise, has white-screen.
    }
  }

  //field: templateName
  const templateName = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        {props.resource && props.resource?.id !== "" && (
          <img
            className="formLogo"
            src={resourceCenterLogo}
            alt="resourceCenterLogo"
          />
        )}
        <span style={{ lineHeight: "20px" }}> {props.name}</span>
      </div>
    );
  };

  //field: entityName
  const subjectName = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.subject}</span>
      </div>
    );
  };

  //field: methods
  const methodsName = (props: any) => {
    return (
      <div className="border-boxBlock">
        <span style={{ display: "flex", justifyContent: "center" }}>
          {props.methods}
        </span>
      </div>
    );
  };

  //field: audience
  const audienceType = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.audience === "Provider" ? "Employee" : props.audience}</span>
      </div>
    );
  };

  //field: from
  const messageFrom = (props: any) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>{props.from}</span>
      </div>
    );
  };

  //field: action
  const handleAction = (props: any) => {
    // console.log("[handleAction]:", props);
    return (
      <div>
        {props && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <FormGroup
              className="settingBox__field"
              style={{ justifyContent: "center" }}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={props.active}
                    onChange={() => {
                      handleSwitchChange(props);
                      setIsDataChanged(true);
                    }}
                  />
                }
                // label="On"
                label={props.active ? "On" : "Off"}
              />
            </FormGroup>
            <div className="setDate">
              <span
                onClick={() => {
                  try {
                    dispatch(
                      setCurrentTemplate({ isEdit: true, templateData: props })
                    );
                    navigate("/message-template");
                  } catch (e) {
                    console.log(e);
                  }
                }}
              >
                <Edit />
              </span>
              <span
                onClick={() => {
                  setDeletePopup(true);
                  setMessageTemplateId(props.id);
                }}
              >
                <DeleteIcon />
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const filterMessageTemplates = (): NewMessageTemplate[] => {
    let result: NewMessageTemplate[] = [];
    if (messageTemplates && messageTemplates.length > 0) {
      if (
        filterGroup === "Client" ||
        filterGroup === "Admin" ||
        filterGroup === "Employee"
      ) {
        result = messageTemplates.filter(
          (item) => (
            (item.audience === filterGroup || 
             (filterGroup === "Employee" && item.audience === "Provider")
            ) && item.active
          )
        );
      } else if (filterGroup === "Archive") {
        result = messageTemplates.filter(
          (item) => !item.active
        );
      } else {
        result = messageTemplates;
      }
    }
    return result;
  };

  //data grid table
  const dataGridTable = React.useMemo(
    () =>
      messageTemplates &&
      messageTemplates[0]?.id !== "" && (
        <GridComponent
          id="grid" //must have!!
          className="gridTable"
          dataSource={SortData()}
          dataBound={dataBound.bind(this)}
          allowPaging={true}
          ref={(grid) => (gridInstance = grid as GridComponent)}
          statelessTemplates={["directiveTemplates"]}
          // ref={g => grid = g}
          load={load}
          width="auto"
          height="650"
          allowGrouping={true}
          groupSettings={groupOptions}
          allowFiltering={true}
          allowSorting={true}
          allowResizing={true}
          allowTextWrap={true}
          filterSettings={{ type: "Menu" }}
          textWrapSettings={textWrapSettings}
          selectionSettings={selectionSettings}
          rowSelected={rowSelected}
          allowPdfExport={true}
          allowExcelExport={true}
          pdfExportComplete={pdfExportComplete}
          excelExportComplete={excelExportComplete}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="id"
              visible={false}
              headerText="ID"
              isPrimaryKey={true}
              width="130"
            />
            <ColumnDirective
              headerText="Template Name"
              width="150"
              textAlign="Left"
              field="name"
              template={templateName}
            />
            <ColumnDirective
              headerText="From"
              width="150"
              textAlign="Left"
              field="from"
              template={messageFrom}
            />
            <ColumnDirective
              headerText="To"
              width="80"
              textAlign="Left"
              field="audience"
              template={audienceType}
            />
            <ColumnDirective
              headerText="Subject"
              width="250"
              textAlign="Left"
              field="subject"
              template={subjectName}
            />
            <ColumnDirective
              headerText="Method"
              width="80"
              textAlign="Left"
              field="methods"
              template={methodsName}
            />

            <ColumnDirective
              headerText="Action"
              width="100"
              textAlign="Left"
              field="action"
              disableHtmlEncode={false}
              template={handleAction}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Page,
              Group,
              ContextMenu,
              Toolbar,
              Toolbar,
              Resize,
              DetailRow,
              Filter,
              Sort,
              ExcelExport,
              PdfExport,
            ]}
          />
        </GridComponent>
      ),
    [messageTemplates,filterGroup]
  );

  //#endregion

  //#region useEffect region
  useEffect(() => {
    dispatch(GetMessageTemplate());
  }, [isDataChanged, dispatch]); //must have dispatch

  console.log("messageTemplates:", messageTemplates);

  return (
    <div>
      <Box sx={{ marginTop: "30px" }}>
        <Typography
          gutterBottom
          variant="h5"
          component="div"
          fontWeight="bold"
          className="listingTable__title"
          style={{ marginBottom: "10px" }}
        ></Typography>

        <Box
          sx={{
            display: { xs: "block", md: "flex" },
            gap: "15px",
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: "10px",
          }}
        >
          <Typography
            className="listingTable__title"
            gutterBottom
            // variant="h6"
            style={{ fontWeight: 500, fontSize: "20px" }}
          >
            Select Group:
          </Typography>
          <Box sx={{ display: "flex", gap: "15px" }} className="form-tabs">
            {TemplateGroup.map((item, index) => (
              <Box className="fromGroup-chips" key={item}>
                <Card
                  key={index}
                  className={
                    filterGroup === item ? "fromGroup-chip-active" : ""
                  }
                  onClick={() => {
                    filterGroup === item
                      ? setFilterGroup("")
                      : setFilterGroup(item);
                  }}
                >
                  {item}
                </Card>
              </Box>
            ))}
          </Box>
        </Box>
        {dataGridTable ? <div>{dataGridTable}</div> : null}
      </Box>
      {deletePopup && (
        <Dialog open={deletePopup} className="smallModel">
          <div>
            <DialogContent>
              Do you want to delete this message template?
            </DialogContent>
            <DialogActions>
              <Button
                className="border-button"
                variant="contained"
                onClick={() => setDeletePopup(false)}
                color="secondary"
              >
                No
              </Button>
              <Button
                className="button"
                variant="contained"
                onClick={() => {
                  dispatch(deleteMessageTemplate({ id: messageTemplateId }));
                  setDeletePopup(false);
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </div>
        </Dialog>
      )}
    </div>
  );
};
export default BusinessMessage;
