import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useAppDispatch, useAppSelector } from "../../../../redux/store";
import {
  addNewCustomBoard,
  setNewBoard,
  resetNewBoard,
  updateCustomBoard,
  setAutomationUpdated,
} from "../../../../redux/features/custom-board/custom-board-slice";

import StageComponent from "./StageComponent";

import "./NewBoardModal.scss";
import { updateIntakeStage } from "src/redux/features/client-main/client-intake-stage";
import { updateOnBoardingStage } from "src/redux/features/providers-main/provider-onboardingstage-slice";
import { stages } from "src/types/board";

interface INewBoardModal {
  setModal: Function;
  addedNew: boolean;
  boardExists: (boardName: string) => boolean;
}
const NewBoardModal: React.FC<INewBoardModal> = ({
  setModal,
  addedNew,
  boardExists,
}) => {
  //hand;e dispatch data
  const dispatch = useAppDispatch();
  //handle basic form
  const [basicForm, setBasicForm] = useState("basic");
  //handle Error
  const [hasError, setError] = useState(false);
  //handle Message Error
  const [errorMsg, setErrorMsg] = useState("");
  //handle Rank Index
  const [rankIndex, setRankIndex] = useState(1);
  //handle new Board data
  const newBoard = useAppSelector((state) => state.boardSlice.newBoard);

  //store existing stages
  const [existingStages, setExistingStages] = useState<stages[]>([]);

  //handle warning display
  const [displayWarning, setDisplayWarning] = useState<boolean>(false);

  const [editedStages, setEditedStages] = useState<
    { stageId: string; stageName: string; ruleName: string }[]
  >([]);

  //#endregion

  //#region methods region
  //handle add Stage handler
  const addStageHandler = () => {
    if (
      newBoard.stages.length > 0 &&
      !newBoard.stages[newBoard.stages.length - 1].stageName
    ) {
      setError(true);
      setErrorMsg("Enter the name of previous stage");
      setTimeout(() => setError(false), 2000);
      return;
    }

    dispatch(
      setNewBoard({
        name: "stages",
        value: [
          ...newBoard.stages,
          {
            id: "",
            name: "",
            goal: "1 day",
            client: [],
            provider: [],
            checkList: [],
            index: rankIndex,
          },
        ],
      })
    );

    setRankIndex(rankIndex + 1);
  };

  //handle submit handler
  const submitHandler = ({ warningAccepted }: { warningAccepted: boolean }) => {
    if (!newBoard.name) {
      setError(true);
      setErrorMsg("Enter the name of Board");
      setTimeout(() => setError(false), 2000);
      return;
    }

    if (addedNew && boardExists(newBoard.name)) {
      setError(true);
      setErrorMsg("Board name already exists");
      return;
    }
    /* turn off by Hui - relax this!!
    if (!newBoard.description) {
      setError(true);
      setErrorMsg("Enter the description of Board");
      setTimeout(() => setError(false), 2000);
      return;
    }*/
    if (!newBoard.boardType) {
      setError(true);
      setErrorMsg("Select the type of Board");
      setTimeout(() => setError(false), 2000);
      return;
    }
    if (!(newBoard.stages.length > 0)) {
      setError(true);
      setErrorMsg("Enter the stages for Board");
      setTimeout(() => setError(false), 2000);
      return;
    }

    if (newBoard.stages.length > 0) {
      for (let i = 0; i < newBoard.stages.length; i++) {
        if (!newBoard.stages[i].stageName) {
          setError(true);
          setErrorMsg("Enter the name of all stages");
          setTimeout(() => setError(false), 2000);
          return;
        }
      }
    }

    if (addedNew) {
      dispatch(addNewCustomBoard());
    } else {
      if (!warningAccepted) {
        // default warningAccpeted is false for first verification
        const isStageAutomated = verifyStageRelatedAutomation();
        if (isStageAutomated) {
          //isStageAutomated display warning
          setDisplayWarning(true);
          return;
        }
      } else {
        const editedStageIds = editedStages.map((stage) => stage.stageId);
        //updated automation
        const effectedAutomation = newBoard.automation.map((automation) =>
          editedStageIds.includes(automation.cardDetails.stage)
            ? { ...automation, apply: false, onHold: true, formId: [] }
            : automation
        );
        dispatch(
          setNewBoard({ name: "automation", value: effectedAutomation })
        );
        dispatch(setAutomationUpdated(true));
      }
      //updated states in slice
      dispatch(
        updateIntakeStage({
          updatedStages: newBoard.stages,
          boardId: newBoard.id,
        })
      );
      dispatch(
        updateOnBoardingStage({
          updatedStages: newBoard.stages,
          boardId: newBoard.id,
        })
      );
      //call update API
      dispatch(updateCustomBoard());
    }
    setTimeout(()=> dispatch(resetNewBoard()), 1000);
    setModal(false);
  };

  const verifyStageRelatedAutomation = (): boolean => {
    const editedStagesList = newBoard.stages
      .filter((stage) =>
        existingStages.some(
          (existingStage) =>
            existingStage.id === stage.id &&
            existingStage.stageName.toLowerCase() !==
              stage.stageName.toLowerCase()
        )
      )
      .map((stage) => ({
        stageId: stage.id,
        stageName: stage.stageName,
        ruleName: "",
      }));

    if (editedStagesList.length === 0) return false;

    // Extract edited stage IDs
    const editedStageIds = editedStagesList.map((stage) => stage.stageId);

    // Check if any of these stages are part of automation
    const isStageAutomated = newBoard.automation.some((automation) =>
      editedStageIds.includes(automation.cardDetails.stage)
    );

    // Update edited stages with rule names from automation
    const updatedEditedStages = editedStagesList.map((stage) => {
      const relatedAutomation = newBoard.automation.find(
        (automation) => automation.cardDetails.stage === stage.stageId
      );
      return {
        ...stage,
        ruleName: relatedAutomation ? relatedAutomation.name : "",
      };
    });

    // Store the edited stages in state
    setEditedStages(updatedEditedStages);

    return isStageAutomated;
  };

  //#endregion

  //#region useEffect region

  useEffect(() => {
    if (!addedNew) {
      setRankIndex(newBoard.stages.length);
    }
  }, []);

  useEffect(() => {
    if (newBoard.stages.length > 0 && existingStages.length === 0) {
      setExistingStages(newBoard.stages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newBoard]);
  //#endregion

  return (
    <div className="create-new-board ">
      <div className="create-new-board-content">
        {displayWarning ? (
          <div className="board-update-warning">
            <div className="warning-header">
              <h3>Automation Impact Detected</h3>
              <ErrorOutlineIcon color="warning" />
              {/* <WarningIcon color="warning"/>  */}
            </div>
            <p className="warning-text">
              The updated Stage has automation connected to it. When you proceed
              the changes, the automation will be put "On Hold".
            </p>
            <div>
              <h4>List of affected Stages and rule name.</h4>
              <>
                {editedStages.map((item, index) => (
                  <p>
                    {index + 1}. {item.stageName} - {item?.ruleName || "Unnamed Rule"}
                  </p>
                ))}
              </>
            </div>
          </div>
        ) : (
          <>
            <div className="tab-section">
              <ul className="innerTabs m-0 mb-8">
                <li className="innerTabs__item">
                  <button
                    onClick={() => {
                      setBasicForm("basic");
                    }}
                    className={basicForm === "basic" ? "active" : ""}
                  >
                    Basic
                  </button>
                </li>
                <li className="innerTabs__item">
                  <button
                    onClick={() => {
                      setBasicForm("stages");
                    }}
                    className={basicForm === "stages" ? "active" : ""}
                  >
                    Stages
                  </button>
                </li>
              </ul>
              {basicForm === "stages" && (
                <div onClick={addStageHandler}>
                  <span className="linkBtn">+ New Stage</span>
                </div>
              )}
            </div>
            {basicForm === "basic" && (
              <Grid className="create-new-board-inner">
                <Grid size={{ xs: 12, md: 6 }}>
                  <Box className="fromGroup mb-8">
                    <InputLabel className="fromLabel" variant="standard">
                      Title
                    </InputLabel>
                    <FormControl>
                      <TextField
                        disabled={newBoard.primitive}
                        value={newBoard.name}
                        onChange={(e) => {
                          dispatch(
                            setNewBoard({ name: "name", value: e.target.value })
                          );
                        }}
                        onKeyUp={() => {
                          if (addedNew && boardExists(newBoard.name)) {
                            setError(true);
                            setErrorMsg("Board name already exists");
                            return;
                          } else {
                            setErrorMsg("");
                          }
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box className="fromGroup mb-8">
                    <InputLabel className="fromLabel">Description</InputLabel>
                    <FormControl>
                      <TextField
                        value={newBoard.description}
                        disabled={newBoard.primitive}
                        onChange={(e) => {
                          dispatch(
                            setNewBoard({
                              name: "description",
                              value: e.target.value,
                            })
                          );
                        }}
                      />
                    </FormControl>
                  </Box>
                  <Box className="fromGroup mb-8">
                    <Box className="fromGroup">
                      <InputLabel className="fromLabel">
                        Tracking Type
                      </InputLabel>
                      <div className="fromGroup-chips">
                        {addedNew ? (
                          <>
                            <Card
                              className={
                                newBoard.boardType === "client"
                                  ? "fromGroup-chip-active"
                                  : ""
                              }
                              onClick={
                                newBoard.primitive
                                  ? () => {}
                                  : () =>
                                      dispatch(
                                        setNewBoard({
                                          name: "boardType",
                                          value: "client",
                                        })
                                      )
                              }
                            >
                              Client
                            </Card>
                            <Card
                              className={
                                newBoard.boardType === "provider"
                                  ? "fromGroup-chip-active"
                                  : ""
                              }
                              onClick={
                                newBoard.primitive
                                  ? () => {}
                                  : () =>
                                      dispatch(
                                        setNewBoard({
                                          name: "boardType",
                                          value: "provider",
                                        })
                                      )
                              }
                            >
                              Employee
                            </Card>
                          </>
                        ) : (
                          <Card
                            className={
                              newBoard.boardType === "client" ||
                              newBoard.boardType === "provider"
                                ? "fromGroup-chip-inactive"
                                : ""
                            }
                          >
                            {newBoard.boardType === "client"
                              ? "Client"
                              : newBoard.boardType === "provider"
                              ? "Employee"
                              : ""}
                          </Card>
                        )}
                      </div>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            )}
            {basicForm === "stages" && (
              <StageComponent
                newBoard={newBoard}
                setError={setError}
                setErrorMsg={setErrorMsg}
                key={newBoard.id}
              />
            )}
          </>
        )}

        {/* deprecate this !!!
        {basicForm === "automation" && newBoard.automation && (
        <Automation setErrorMsg={setErrorMsg} setError={setError} />
      )} */}
        {hasError && (
          <FormHelperText style={{ color: "#d32f2f" }}>
            {errorMsg}
          </FormHelperText>
        )}
      </div>

      <Box
        className="create-new-board-footer ptb-15"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <div>
          {displayWarning && (
            <Button
              variant="outlined"
              onClick={() => {
                setDisplayWarning(false);
              }}
            >
              back
            </Button>
          )}
        </div>

        <div>
          {displayWarning ? (
            <Button
              variant="contained"
              onClick={() => {
                submitHandler({ warningAccepted: true });
              }}
            >
              Save
            </Button>
          ) : (
            <Button
              onClick={() => {
                submitHandler({ warningAccepted: false });
              }}
              variant="contained"
            >
              {addedNew ? `Create Board` : `Save Board`}
            </Button>
          )}
        </div>
      </Box>
    </div>
  );
};

export default NewBoardModal;
