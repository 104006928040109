import React, { useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Delete } from "@mui/icons-material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { MuiFileInput } from "mui-file-input";
import BackupIcon from "@mui/icons-material/Backup";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

import { useAppSelector } from "../../../redux/store";

import {
  AuthCode,
  ClientAuthCode,
  ClientAuthorization,
  InsuranceProviders,
} from "../../../types/client";
import { InsuranceProvider } from "../../../types/response";
import { IHashObj } from "../../../types/Common";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ClientAddAuthorizationFormProps {
  onSubmit: () => void;
  deleteSingleAuthorization?: (id: string) => void;
  activeTabFromParent: string;
  clientInsurance: InsuranceProviders[];
  newAuthorization: ClientAuthorization;
  setNewAuthorization: (auth: any) => void;
}

const ClientAddAuthorizationForm: React.FC<ClientAddAuthorizationFormProps> = ({
  onSubmit,
  clientInsurance,
  newAuthorization,
  activeTabFromParent,
  setNewAuthorization,
}) => {
  //handle the Error to show the warnings
  const [hasError, setHasError] = useState<boolean>(false);

  //insurance Providers Data
  const insuranceProviders = useAppSelector(
    (state) => state.clientInsuranceSlice.clientInsurance
  );

  const authorizations = useAppSelector((state) => state.client.authorizations);

  //stores authcode
  const authcode = useAppSelector(
    (state) => state.clientInsuranceSlice.clientAuthcode
  );

  //duplicate error image
  const [duplicateErrorImage, setDuplicateErrorImage] =
    useState<boolean>(false);

  //duplicate error image
  const [duplicateErrorImageMessage, setDuplicateErrorImageMessage] =
    useState<string>("");

  // active tab
  const [activeTab, setActiveTab] = useState<string>(
    activeTabFromParent ? activeTabFromParent : "basic"
  );

  //duplication error insurance
  const [isInsuranceError, setIsInsuranceError] = useState<boolean>(false);

  //auth code error handler
  const [duplicateAuthCodeError, setDuplicateAuthCodeError] =
    useState<boolean>(false);

  // #endregion

  // #region methods region

  // handle start date of authperiod
  const handleStartDate = (date: Dayjs | null) => {
    const startDate = dayjs(date).format("MM/DD/YYYY");
    setNewAuthorization({
      ...newAuthorization,
      authPeriod: {
        ...newAuthorization.authPeriod,
        startDate,
      },
    });
  };

  //handle enddate of authperiod
  const handleEndDate = (date: Dayjs | null) => {
    const endDate = dayjs(date).format("MM/DD/YYYY");
    setNewAuthorization({
      ...newAuthorization,
      authPeriod: {
        ...newAuthorization.authPeriod,
        endDate,
      },
    });
  };

  const handleChange = (e: any) => {
    setNewAuthorization({
      ...newAuthorization,
      [e.target.name]: e.target.value,
    });
  };

  //remove focus on input field
  const removeFocus = (id: string) => {
    document.getElementById(id)?.blur();
  };

  // function to used the checked the auth object
  const last = (array: ClientAuthCode[]): boolean => {
    if (array === undefined || array.length === 0) return false;
    return !Boolean(
      array[array.length - 1]?.authCode && array[array.length - 1]?.units
    );
  };

  //make sure AuthInsurance found in existing ClientInsurance
  const validateInsurance = (e: any) => {
    if (clientInsurance !== undefined) {
      const currentInsurance = insuranceProviders.find(
        (insurance) => insurance.id === e.target.value
      );
      const found = clientInsurance.find(
        (item: any) =>
          item.insuranceProviderName === currentInsurance?.insuranceProviderName
      );
      if (!currentInsurance || found === undefined) {
        setIsInsuranceError(true);
        return false;
      } else {
        setIsInsuranceError(false);
        return true;
      }
    }
  };

  //handle submiting the data
  const handleSubmit = (e: any) => {
    e.preventDefault();
    let error = false;

    if (
      newAuthorization.authPeriod.startDate === "Invalid Date" ||
      newAuthorization.authPeriod.endDate === "Invalid Date"
    ) {
      error = true;
    }
    if (isInsuranceError) {
      error = true;
    }
    //turn off by Hui... relax logic to allow partial save
    newAuthorization.auth.forEach((item: ClientAuthCode) => {
      if (!item.authCode || !item.units) {
        error = true;
      }
    });

    if (error) {
      setHasError(true);
      setActiveTab("basic");
    } else {
      onSubmit();
    }
  };

  return (
    <>
      <Box className="stepperForm">
        <ValidatorForm onSubmit={handleSubmit}>
          {/* <div className="stepperForm__inner" style={{ marginBottom: 0 }}>
            <Grid container spacing={2} className="InsuranceHeader">
              <Grid size={{ xs: 12, lg: 6 }}>
                <h1 className="stepperForm__title">Add Authorization</h1>
              </Grid>
            </Grid>
          </div> */}
          <div className="stepperForm__inner">
            <Grid container spacing={3} style={{ position: "relative" }}>
              <Grid size={{ xs: 12, lg: 4 }}>
                <div className="authListBox">
                  <div className="authListBox__item">
                    <div className="authListBox__head">
                      <p className="authListBox__title">
                        {newAuthorization.authorizationName}
                      </p>
                    </div>
                    <div className="authListBox__body">
                      <span
                        className={
                          activeTab === "basic"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("basic");
                        }}
                      >
                        Basic
                      </span>
                      <span
                        className={
                          activeTab === "files"
                            ? "authListBox__link activeLink"
                            : "authListBox__link"
                        }
                        onClick={() => {
                          setActiveTab("files");
                        }}
                      >
                        Files
                      </span>
                    </div>
                  </div>
                </div>
              </Grid>
              {activeTab === "basic" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Grid container spacing={3}>
                    <Grid size={{ xs: 12 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Authorization Name
                        </InputLabel>
                        <TextValidator
                          onChange={handleChange}
                          name="authorizationName"
                          value={newAuthorization.authorizationName}
                          validators={["required"]}
                          errorMessages={["Please enter authorization name"]}
                          id="authorizationName"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authorizationName");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <Box className="fromGroup">
                        <InputLabel
                          id="demo-simple-select-label"
                          className="fromLabel"
                        >
                          Insurance Provider
                        </InputLabel>
                        <Select
                          className="form-control"
                          value={newAuthorization.insuranceId}
                          id="authInsuranceProvider"
                          name="insuranceId"
                          onChange={(e) => {
                            validateInsurance(e);
                            handleChange(e);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authInsuranceProvider");
                            }
                          }}
                          MenuProps={MenuProps}
                        >
                          {insuranceProviders.map((item: InsuranceProvider) => {
                            return (
                              <MenuItem key={item.id} value={item.id}>
                                {item.insuranceProviderName}
                              </MenuItem>
                            );
                          })}
                        </Select>

                        {hasError && !newAuthorization.insuranceId && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select insurance provider
                          </FormHelperText>
                        )}
                        {isInsuranceError ? (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            The client didn't provide this insurance provider.
                            Please verify client's insurance first.
                          </FormHelperText>
                        ) : null}
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <Box className="fromGroup">
                        <InputLabel className="fromLabel">
                          Auth Number
                        </InputLabel>
                        <TextValidator
                          onChange={handleChange}
                          name="authorizationNumber"
                          value={newAuthorization.authorizationNumber}
                          id="authorizationNumber"
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              removeFocus("authorizationNumber");
                            }
                          }}
                        />
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 12 }}>
                      <InputLabel className="fromLabel">Auth Period</InputLabel>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid container spacing={3}>
                          <Grid size={{ xs: 12, md: 6 }}>
                            <DesktopDatePicker
                              className="singleDatePicker"
                              label="Start Date"
                              value={newAuthorization.authPeriod.startDate !=="" ? dayjs(
                                newAuthorization.authPeriod.startDate
                              ):null}
                              maxDate={dayjs(
                                newAuthorization.authPeriod.endDate
                              )}
                              format="MM/DD/YYYY"
                              onChange={handleStartDate}
                            />
                          </Grid>
                          <Grid size={{ xs: 12, md: 6 }}>
                            <DesktopDatePicker
                              className="singleDatePicker"
                              label="End Date"
                              value={newAuthorization.authPeriod.endDate ? dayjs(newAuthorization.authPeriod.endDate) : null}
                              minDate={dayjs(
                                newAuthorization.authPeriod.startDate
                              )}
                              format="MM/DD/YYYY"
                              onChange={handleEndDate}
                            />
                          </Grid>
                        </Grid>
                      </LocalizationProvider>
                      {hasError &&
                        (newAuthorization.authPeriod.endDate === null ||
                          newAuthorization.authPeriod.startDate === null) && (
                          <FormHelperText style={{ color: "#d32f2f" }}>
                            Please select auth Start date and auth End Date
                          </FormHelperText>
                        )}
                      {/* </Grid> */}
                      {/* <Grid item xs={6}></Grid> */}
                    </Grid>
                    <Grid
                      size={{ xs: 12 }}
                      style={{ position: "relative", textAlign: "right" }}
                    >
                      <Button
                        disabled={
                          authorizations ? last(newAuthorization.auth) : true
                        }
                        className={
                          last(newAuthorization.auth)
                            ? "addBtnDisable"
                            : "addBtn"
                        }
                        onClick={() => {
                          const auth = newAuthorization.auth;
                          auth.push({
                            authCode: "",
                            units: "",
                            authApprovalUnit: "",
                          });
                          setNewAuthorization({
                            ...newAuthorization,
                            auth: auth,
                          });
                        }}
                      >
                        + Add Auth Code
                      </Button>
                    </Grid>
                    <Grid size={{ xs: 6 }}>
                      {duplicateAuthCodeError && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          Please select different auth code
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid size={{ xs: 12 }} style={{ marginTop: "-20px" }}>
                      {newAuthorization.auth.map(
                        (
                          authCodeItem: ClientAuthCode,
                          authCodeIndex: number
                        ) => {
                          let maxUnits = 0;
                          if (
                            newAuthorization.authPeriod.startDate &&
                            newAuthorization.authPeriod.endDate &&
                            authCodeItem.authCode
                          ) {
                            const weeks =
                              moment(
                                new Date(newAuthorization.authPeriod.endDate),
                                "MM/DD/YYYY"
                              ).diff(
                                moment(
                                  new Date(
                                    newAuthorization.authPeriod.startDate
                                  ),
                                  "MM/DD/YYYY"
                                ),
                                "week"
                              ) * 5;
                            maxUnits =
                              (authcode.find(
                                (item) =>
                                  item.conceptValue === authCodeItem.authCode
                              )?.data?.unitsPerDay || 0) * weeks;
                          }
                          return (
                            <div key={authCodeIndex}>
                              <Grid container spacing={3}>
                                <Grid size={{ xs: 5 }}>
                                  <InputLabel className="fromLabel">
                                    Auth Code
                                  </InputLabel>
                                  <Box className="fromGroup">
                                    <Select
                                      labelId="multiple-select-label"
                                      name="authCode"
                                      id="authCode"
                                      value={
                                        newAuthorization.auth[authCodeIndex]
                                          .authCode
                                      }
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          removeFocus("authCode");
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        if (
                                          newAuthorization.auth.find(
                                            (item) =>
                                              item.authCode === e.target.value
                                          )
                                        ) {
                                          setDuplicateAuthCodeError(true);
                                          return;
                                        } else {
                                          setDuplicateAuthCodeError(false);
                                        }
                                        const auth = newAuthorization.auth;
                                        auth[authCodeIndex].authCode =
                                          e.target.value;

                                        setNewAuthorization({
                                          ...newAuthorization,
                                          auth: auth,
                                        });
                                      }}
                                      MenuProps={MenuProps}
                                    >
                                      {authcode.map((item: AuthCode) => {
                                        return (
                                          <MenuItem
                                            key={item.id}
                                            value={item.conceptValue}
                                          >
                                            {item.conceptValue}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>

                                    {hasError &&
                                    !newAuthorization?.auth[authCodeIndex]
                                      .authCode ? (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please select auth code
                                      </FormHelperText>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Grid>
                                <Grid size={{ xs: 5 }}>
                                  {maxUnits > 0 ? (
                                    <InputLabel className="fromLabel">
                                      # of Units (max: {maxUnits})
                                    </InputLabel>
                                  ) : (
                                    <InputLabel className="fromLabel">
                                      # of Units
                                    </InputLabel>
                                  )}
                                  <Box className="fromGroup">
                                    <TextValidator
                                      placeholder="##"
                                      className="form-control"
                                      name="units"
                                      id="units"
                                      value={
                                        newAuthorization?.auth[authCodeIndex]
                                          .units
                                      }
                                      type="number"
                                      max={maxUnits}
                                      onKeyDown={(e) => {
                                        if (e.key === "Enter") {
                                          e.preventDefault();
                                          removeFocus("units");
                                        }
                                      }}
                                      onChange={(e: any) => {
                                        const auth = newAuthorization.auth;
                                        auth[authCodeIndex].units =
                                          e.target.value;
                                        setNewAuthorization({
                                          ...newAuthorization,
                                          auth: auth,
                                        });
                                      }}
                                      validators={[
                                        // "required",
                                        "matchRegexp:^[1-9][0-9]*$",
                                      ]}
                                      errorMessages={[
                                        // "Please enter unit",
                                        "unit must be a positive number",
                                      ]}
                                    />
                                    {hasError &&
                                    !newAuthorization?.auth[authCodeIndex]
                                      .units ? (
                                      <FormHelperText
                                        style={{ color: "#d32f2f" }}
                                      >
                                        Please enter unit
                                      </FormHelperText>
                                    ) : (
                                      ""
                                    )}
                                  </Box>
                                </Grid>
                                <Grid m={10} className="time-group__space">
                                  <Delete
                                    className="removeTimeBtn "
                                    onClick={() => {
                                      const auth = newAuthorization.auth;
                                      auth.splice(authCodeIndex, 1);
                                      setNewAuthorization({
                                        ...newAuthorization,
                                        auth,
                                      });
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                          );
                        }
                      )}
                    </Grid>
                    <Grid size={{ xs: 12 }} style={{ textAlign: "center" }}>
                      <Button onClick={() => setActiveTab("files")}>
                        Upload Files
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {activeTab === "files" && (
                <Grid size={{ xs: 12, lg: 8 }}>
                  <Box className="fromGroup">
                    <InputLabel
                      id="demo-simple-select-label"
                      className="fromLabel"
                    >
                      {duplicateErrorImage && (
                        <FormHelperText style={{ color: "#d32f2f" }}>
                          {duplicateErrorImageMessage}
                        </FormHelperText>
                      )}
                    </InputLabel>
                    <Grid container spacing={3}>
                      <Grid
                        size={{ xs: 12, md: 6, lg: 12 }}
                        className="fileUploadBlock"
                      >
                        <Box className="uploadIconBox">
                          <BackupIcon color="primary" className="uploadIcon" />
                          <Typography>
                            Drag 'n' drop files here or click to add files
                          </Typography>
                        </Box>
                        <MuiFileInput
                          multiple
                          hideSizeText
                          sx={{
                            "& .css-1fel157 > span": { display: "none" },
                            "& .css-flami6": { display: "none" },
                            "& fieldset": { border: "none" },
                          }}
                          value={newAuthorization?.files}
                          onChange={(e: File[]) => {
                            let temp: any = [...newAuthorization?.files];
                            let hashObj: IHashObj = {};
                            temp.forEach((item: any) => {
                              if (item.key) {
                                if (hashObj[item.key?.split("*$SPLIT$*")[1]]) {
                                    
                                  hashObj[item.key?.split("*$SPLIT$*")[1]] += 1;
                                } else {
                                  hashObj[item.key?.split("*$SPLIT$*")[1]] = 1;
                                }
                              } else {
                                if (hashObj[item.name]) {
                                  hashObj[item.name] += 1;
                                } else {
                                  hashObj[item.name] = 1;
                                }
                              }
                            });

                            let duplicateFileNames: IHashObj = {};
                            e.forEach((item: File) => {
                              if (!hashObj[item.name]) {
                                temp.push(item);
                                hashObj[item.name] = 1;
                              } else {
                                if (duplicateFileNames[item.name]) {
                                  duplicateFileNames[item.name] += 1;
                                } else {
                                  duplicateFileNames[item.name] = 1;
                                }
                              }
                            });
                            let fileNameString = "";
                            if (Object.keys(duplicateFileNames).length > 0) {
                              Object.keys(duplicateFileNames).forEach(
                                (item: string) => {
                                  fileNameString += item + ", ";
                                }
                              );
                            }
                            if (fileNameString) {
                              fileNameString +=
                                " found duplicate names for the particular files";
                              setDuplicateErrorImage(true);
                              setDuplicateErrorImageMessage(fileNameString);
                              setTimeout(() => {
                                setDuplicateErrorImage(false);
                                setDuplicateErrorImageMessage("");
                              }, 3000);
                            }
                            setNewAuthorization({
                              ...newAuthorization,
                              files: temp,
                            });
                          }}
                        />
                      </Grid>
                      <Grid size={{ xs: 12, md: 6 }}>
                        <div className="uploadProfile__img selectedIn-fileBox client-addAuth-uploadProfile">
                          {newAuthorization?.files.length > 0 ? (
                            newAuthorization?.files.map(
                              (item: any, index: number) => {
                                return (
                                  <div className="selectedIn-file" key={index}>
                                    <h1>
                                      {/* {item.path
                                        ? item.path
                                        : item.key.split("*$SPLIT$*")[1]} */}
                                        {item.name}
                                    </h1>
                                    <span>
                                      <Delete
                                        onClick={() => {
                                          const files = newAuthorization.files;
                                          files.splice(index, 1);
                                          setNewAuthorization({
                                            ...newAuthorization,
                                            files,
                                          });
                                        }}
                                      />
                                    </span>
                                  </div>
                                );
                              }
                            )
                          ) : 
                          ( 
                            <p className="selectedIn-fileBlank">
                              No files uploaded yet. 
                            </p>
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          </div>
          <Box className="stepperForm__footer">
            <Box sx={{ flex: "1 1 auto" }} />
            <Button type="submit" className="button">
              {"Save Changes"}
            </Button>
          </Box>
        </ValidatorForm>
      </Box>
    </>
  );
};

export default ClientAddAuthorizationForm;
